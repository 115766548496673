import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import BaseDialog from './BaseDialog';
import { isPromise } from '../../helpers/utils';

const ConfirmationDialog = (props) => {
  const saveOrCancelOnClick = (action, props) => {
    if (!action) {
      props.closeDialog();
      return;
    }

    const result = action();
    if (isPromise(result)) {
      return result.then((promiseResult) => promiseResult && props.closeDialog());
    }

    return result && props.closeDialog();
  };

  return (
    <BaseDialog
      {...props}
      renderDialog={(props) => (
        <Dialog
          sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435, ...props.paperProps } }}
          maxWidth={props.maxWidth || 'xs'}
          disableEscapeKeyDown
          open={props.open}
          onClose={() => {
            if (props.preventToClickWithClickOutside) {
              return;
            }

            props.closeDialog();
          }}
        >
          <DialogTitle>{props.titleLabel}</DialogTitle>
          {!props.skipDialogContent && <DialogContent dividers>{props.children}</DialogContent>}
          <DialogActions>
            <Button variant="outlined" autoFocus sx={{ mr: 1 }} onClick={() => saveOrCancelOnClick(props.onCancel, props)}>
              {props.cancelLabel || 'Cancel'}
            </Button>
            <Button variant="contained" onClick={() => saveOrCancelOnClick(props.onSave, props)}>
              {props.okLabel || 'Ok'}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    />
  );
};

export default ConfirmationDialog;
