import * as React from 'react';
import { Box, Typography, Container, MenuItem, Button, IconButton, Drawer, List, ListItem, ListItemButton } from '@mui/material';
import headerImage from '../../components/assets/header.jpeg';
import logoImage from '../../components/assets/logo.png';
import Link from '@mui/material/Link';
import { Link as LinkRouter } from 'react-router-dom';
import Popover from '../../components/Popover/Popover';
import LanguageIcon from '@mui/icons-material/Language';
import Dialog from '../../components/Dialogs/Dialog';
import MessageBox from '../../components/MessageBox/MessageBox';
import SearchPage from './SearchPage';
import SearchHotelsInputs from '../../components/Inputs/SearchHotelsInput';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { getCurrency, setCurrency } from '../../helpers/currencies';

const phoneNumber = process.env.REACT_APP_BELLITOURS_PHONE;

const sx = {
  navbar: {
    width: '100%',
    // height: '320px',
    backgroundImage: `url(${headerImage})`,
    backgroundRepeat: 'no-repeat',
    // backgroundAttachment: 'fixed',
    // backgroundSize: 'cover'
    backgroundSize: 'cover',
    backgroundAttachment: {
      xs: 'fixed',
      sm: 'inherit',
    },
    pb: 4,
  },
  navLinkContainer: {
    float: 'right',
    display: {
      xs: 'none',
      sm: 'flex',
    },
    marginLeft: 'auto',
  },
  break: {
    flexBasis: '100%',
    height: '0px',
    display: {
      sm: 'none',
    },
  },
  container: {
    paddingTop: 1,
    maxWidth: '1300px',
    height: '100%',
    position: 'relative',
    px: {
      xs: '20px !important',
      md: '50px !important',
      lg: '0px !important',
    },
  },
  menu: {
    cursor: 'pointer',
    fontWeight: {
      // xs: '',
      sm: 'bold',
    },
    fontSize: 14,
    color: {
      xs: 'text.primary',
      sm: 'white',
    },
    ml: {
      xs: 0,
      sm: 3,
    },
    display: 'block',
  },
  language: {
    minWidth: '70px',
    display: 'inline-block',
  },
  navLink: {
    fontWeight: 'bold',
    fontSize: 14,
    color: 'white',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    ml: {
      xs: 1.5,
      sm: 3,
    },
  },
  submenuNavLink: {
    // color: 'white',
    textDecoration: 'none',
  },
  planner: {
    background: '#f3f3f5',
    color: 'black',
    '&:hover': {
      backgroundColor: '#f3f3f5',
      borderColor: '#0062cc',
      // boxShadow: 'none',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
    ml: {
      xs: 2,
      sm: 3,
    },
    display: {
      xs: 'none',
      sm: 'inline',
    },
  },
  logo: {
    marginTop: 2,
    display: 'inline-block',
    width: {
      // xs: '90px',
      xs: '180px',
      md: '270px',
    },
    // original logo size 3321x1087, the ratio os 3.05
    height: {
      // xs: '29px',
      xs: '58px',
      md: '87px',
    },
    backgroundImage: `url(${logoImage})`,
    backgroundRepeat: 'no-repeat',
    // backgroundAttachment: 'fixed',
    // backgroundSize: 'cover'
    backgroundSize: '100% 100%',
    // marginLeft: '-26px',
  },
  navigation: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  mainText: {
    mt: 0,
    color: 'white',
    fontSize: 28,
    fontWeight: 'bold',
  },
  secondaryText: {
    fontSize: 18,
    color: 'white',
    fontWeight: 'bold',
    mb: -1,
  },
  mainSearch: {
    text: {
      color: 'white',
      fontSize: 16,
      mb: 0.5,
      ml: 1,
    },
  },
};

const links = [
  { to: '/', text: 'Hotel' },
  { to: 'https://bellitours.mybookingplatform.com/us/Tour/Search', text: 'Tours' },
  { to: 'https://bellitours.mybookingplatform.com/us/Car/Search', text: 'Cars' },
  { to: 'https://bellitours.mybookingplatform.com/us/Activities/Search', text: 'Activities' },
  { to: 'https://bellitours.mybookingplatform.com/us/Insurance/Search', text: 'Travel Insurance' },
];

const links2 = [
  { to: '/contact', text: 'Support' },
  { to: 'https://book.bellitours.com', text: 'Deals' },
];

const MainSearch = () => {
  const navigate = useNavigate();
  const search = (searchProps) => navigate(SearchPage.link(searchProps));

  return (
    <>
      <Typography sx={sx.mainSearch.text}>Hotels</Typography>
      <SearchHotelsInputs onSearch={search} />
    </>
  );
};

const Navbar = (props) => {
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'English');
  const updateLanguage = (newLanguage) => {
    localStorage.setItem('language', newLanguage);
    setLanguage(newLanguage);
  };

  const moreServicesMenu = (
    <Popover
      openOn="click"
      inline
      horizontal={-20}
      target={
        <Typography component={'span'} sx={sx.menu}>
          More Services
        </Typography>
      }
      renderChildren={({ close }) => (
        <Box
          sx={
            {
              /*background: 'rgb(43, 104, 154)' */
            }
          }
        >
          {links.map((link) => (
            <MenuItem key={link.to}>
              <Link sx={sx.submenuNavLink} component={LinkRouter} to={link.to} target="_blank">
                {link.text}
              </Link>
            </MenuItem>
          ))}
        </Box>
      )}
    />
  );

  const currencySelector = (
    <Popover
      openOn="click"
      inline
      horizontal={-20}
      target={
        <Typography component={'span'} sx={{ ...sx.menu, py: { xs: 1.5, sm: 0, display: 'block' } }}>
          {getCurrency()}
        </Typography>
      }
      renderChildren={({ close }) => (
        <Box>
          {['USD', 'EUR'].map((currency) => (
            <MenuItem
              key={currency}
              sx={{ width: { xs: '100%' } }}
              onClick={() => {
                setCurrency(currency);
                window.location.reload();
              }}
            >
              <Typography color="text.primary">{currency}</Typography>
            </MenuItem>
          ))}
        </Box>
      )}
    />
  );

  const languageSelector = (
    <Popover
      openOn="click"
      inline
      horizontal={-20}
      target={
        <Typography component={'span'} sx={{ ...sx.menu, ...sx.language, py: { xs: 1.5, sm: 0, display: 'block' } }}>
          <LanguageIcon sx={{ mb: '-4px', fontSize: 17 }} /> {language}
        </Typography>
      }
      renderChildren={({ close }) => (
        <Box>
          <MenuItem
            onClick={() => {
              updateLanguage('English');
              close();
            }}
          >
            English
          </MenuItem>
          {/* <MenuItem
            onClick={() => {
              updateLanguage('Español');
              close();
            }}
          >
            Español
          </MenuItem> */}
        </Box>
      )}
    />
  );

  const planner = (
    <Dialog
      // disableCloseOnClickAway
      titleLabel="Start planning with us"
      renderTarget={({ openDialog }) => (
        <Button sx={sx.planner} variant="contained" size="small" onClick={openDialog}>
          Trip Planner
        </Button>
      )}
      renderContent={() => (
        <Box sx={{ '& .bt-message-box': { p: 2, pt: 0, color: 'red' } }}>
          <Box pl={2}>
            <Typography>We'd love to hear from you.</Typography>
            <Typography>
              For direct assistance call our office at <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
            </Typography>
          </Box>
          <MessageBox />
        </Box>
      )}
    />
  );

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <Box sx={sx.navbar}>
      <Container sx={sx.container}>
        <Box sx={sx.navigation}>
          <Link sx={sx.logo} component={LinkRouter} to={'/'}></Link>
          <IconButton
            aria-label="open drawer"
            onClick={() => setIsDrawerOpen(true)}
            edge="start"
            sx={{
              color: 'white',
              display: {
                sm: 'none',
              },
            }}
          >
            <MenuIcon sx={{ fontSize: '30px' }} />
          </IconButton>

          <Box sx={sx.break} />
          <Box sx={sx.navLinkContainer}>
            {moreServicesMenu}
            {currencySelector}
            {languageSelector}

            {links2.map((link) => (
              <Link key={link.to} sx={sx.navLink} component={LinkRouter} to={link.to} target="_blank">
                {link.text}
              </Link>
            ))}

            {planner}
          </Box>
        </Box>

        <Box mb={2} sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Typography sx={sx.mainText}>Save on your next stay</Typography>
          <Typography sx={sx.secondaryText}>We'll give you the price. You will enjoy the time</Typography>
        </Box>

        {props.withNavbar && (
          <>
            <Box mt={1}>
              <MainSearch />
            </Box>
          </>
        )}
      </Container>

      <Drawer anchor="left" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <Box sx={{ width: '100vw', pt: 5 }} role="presentation">
          <IconButton
            aria-label="close"
            onClick={() => setIsDrawerOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <List>
            {links.map((link, index) => (
              <ListItem key={index} disablePadding>
                <ListItemButton sx={{ pl: 5 }}>
                  <Link
                    sx={{ ...sx.submenuNavLink, py: 1.5, width: '100%', display: 'block' }}
                    component={LinkRouter}
                    to={link.to}
                    target="_blank"
                  >
                    <Typography color="text.primary">{link.text}</Typography>
                  </Link>
                </ListItemButton>
              </ListItem>
            ))}

            <ListItem disablePadding>
              <ListItemButton sx={{ pl: 5 }}>{currencySelector}</ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton sx={{ pl: 5 }}>{languageSelector}</ListItemButton>
            </ListItem>

            {links2.map((link, index) => (
              <ListItem key={index} disablePadding sx={{}}>
                <ListItemButton sx={{ pl: 5 }}>
                  <Link
                    sx={{ ...sx.submenuNavLink, py: 1.5, width: '100%', display: 'block' }}
                    color="text.primary"
                    component={LinkRouter}
                    to={link.to}
                    target="_blank"
                  >
                    {link.text}
                  </Link>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

export default Navbar;
