export const paxesStringToObj = paxesStr => {
  const result = []
  const roomsStrSplitted = paxesStr.split('-')
  while (roomsStrSplitted.length > 0) {
    const room = {}
    result.push(room)
    room.adults = Number.parseInt(roomsStrSplitted.shift(), 10)
    room.children = Number.parseInt(roomsStrSplitted.shift(), 10)
    room.ages = []

    let ages = room.children
    while (ages > 0) {
      room.ages.push(Number.parseInt(roomsStrSplitted.shift(), 10))
      ages -= 1
    }
  }

  return result
}

export const paxesObjToString = rooms => {
  const roomStr = (room) => [room.adults, room.children, ...(room.ages || [])].join('-')
  return rooms.map(roomStr).join('-')
}
