import { useEffect, useState } from 'react';
import { Alert, Box, Button, Paper, Stack, TextField, Typography } from '@mui/material';
import isEmail from 'validator/lib/isEmail';
import { useAuth } from './AuthProvider';
import { toast } from 'react-toastify';

const LoginPage = () => {
  const { login } = useAuth();

  const [email, setEmail] = useState('');
  const [invalidEmail, setInvalidEmail] = useState(false);

  const [pass, setPass] = useState('');
  const [invalidPass, setInvalidPass] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    setInvalidEmail(!email || !isEmail(email));
    setInvalidPass(!pass);
    setErrorMsg('');
  }, [email, pass]);

  const submit = async (event) => {
    event.preventDefault();
    setSubmitted(true);

    if (invalidEmail || invalidPass || isLoading) {
      return;
    }

    try {
      await login({ email, pass });
    } catch (error) {
      setErrorMsg(error?.response?.data?.message || 'Login failed');
      toast.error('Login Failed!')
    }

    setIsLoading(false);
  };

  return (
    <Stack justifyContent="center" alignItems="center" sx={{ height: '100vh' }}>
      <Paper variant="outlined" sx={{ px: 4, py: 3 }}>
        <Typography variant="h6" mb={2} ml={1}>
          Login
        </Typography>

        <Box component="form" onSubmit={submit}>
          <TextField
            label="Email"
            fullWidth
            sx={{ mb: 2 }}
            value={email}
            error={submitted && invalidEmail}
            helperText={submitted && invalidEmail ? 'invalid' : null}
            onChange={(ev) => setEmail(ev.target.value)}
          />

          <TextField
            label="Password"
            fullWidth
            sx={{ mb: 2 }}
            value={pass}
            type="password"
            error={submitted && invalidPass}
            helperText={submitted && invalidPass ? 'invalid' : null}
            onChange={(ev) => setPass(ev.target.value)}
          />

          <Button type="submit" variant="contained" disabled={(submitted && (invalidEmail || invalidPass)) || isLoading}>
            Login
          </Button>

          {errorMsg && !isLoading && (
            <Alert sx={{ mt: 3 }} severity="error">
              {errorMsg}
            </Alert>
          )}
        </Box>
      </Paper>
    </Stack>
  );
};

export default LoginPage;
