import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { useLoading } from './LoadingContext';
import { makeStyles } from '@mui/styles';

// https://mui.com/material-ui/customization/default-theme/?expand-path=$.zIndex
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.tooltip + 1,
    color: '#fff',
  },
}));

const Loading = () => {
  const classes = useStyles();
  const { loading } = useLoading();

  return (
    <Backdrop className={classes.backdrop} open={loading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Loading;
