import { useEffect, useState } from 'react';
import { Box, Button, Container, Grid, Link, Paper, Stack, Typography } from '@mui/material';
import { getPromotionCoupons } from '../../api/hotels';

const ShowPromotionCoupon = () => {
  const [promotionCoupons, setPromotionCoupons] = useState([]);

  useEffect(() => {
    getPromotionCoupons().then((newPromotionCoupons) => {
      setPromotionCoupons(newPromotionCoupons);
    });
  }, []);

  return (
    <Box>
      {promotionCoupons.map((coupon) => (
        <Box
          key={coupon._id}
          sx={{
            textAlign: 'center',
            py: 0.7,
            color: 'white',
            background: 'rgb(24, 66, 129)',
            fontWeight: 'bold',
            borderRadius: '8px',
            mb: 2,
          }}
        >
          <Typography variant="h6">{coupon.promotionText}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default ShowPromotionCoupon;
