import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Typography from '@mui/material/Typography';
import DateRangePicker from '../DateRangePicker';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { makeStyles } from '@mui/styles';
import { addDays, format } from 'date-fns';
import Popover from '../Popover/Popover';
import PlaceholderForInput from './PlaceholderForInput';
import Grid from '@mui/material/Unstable_Grid2';
import { COLOR } from '../../helpers/styles';

const useStyles = makeStyles((theme) => ({
  actionItem: {
    textAlign: 'center',
  },
  checkinContainer: {
    borderRight: `1px solid ${COLOR.GRAY_LINE_SEPARATOR}`,
  },
}));

const DateRangePickerInput = (props) => {
  const { onChange, ...otherProps } = props;

  const classes = useStyles();

  const [range, setRange] = useState(props.initialValue);
  const [acceptButtonDisabled, setAcceptButtonDisabled] = useState(true);

  useEffect(() => {
    setAcceptButtonDisabled(!range);
    onChange?.(range);
  }, [range]);

  const selectDateRange = (range) => {
    setRange(range);
    onChange?.(range);
  };

  const error = props.displayValidationErrors && !range && 'Check-in and check-out dates required';

  const renderTarget = ({ open }) => (
    <Grid container columnSpacing={3}>
      <Grid xs={12}>
        <PlaceholderForInput
          label="Dates"
          icon={CalendarMonthIcon}
          value={
            range?.startDate && range?.endDate ? [format(range.startDate, 'MMM dd'), format(range.endDate, 'MMM dd, yyyy')].join(' - ') : ''
          }
          // withCloseIcon
          onChange={open}
        />
      </Grid>
    </Grid>
  );

  const renderChildren = ({ close }) => (
    <>
      <DateRangePicker
        open={true}
        onChange={selectDateRange}
        minDate={addDays(new Date(), -1)}
        monthSelectorDisabled={true}
        yearSelectorDisabled={true}
        selectedDatesDisabled={true}
        emptyStartDateLabel={'Checkin Date'}
        emptyEndDateLabel={'Checkout Date'}
        allowPastDates={false}
        navigateBimonthly={true}
        initialDateRange={range}
        elevation={1}
        {...otherProps}
      />

      <Stack direction="row" spacing={2} justifyContent="center" alignItems="center" sx={{ py: 1.2 }}>
        <Button disabled={acceptButtonDisabled} className={classes.actionItem} variant={'contained'} onClick={close}>
          Done
        </Button>
      </Stack>
    </>
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Popover fullWidth openOn="click" renderTarget={renderTarget} renderChildren={renderChildren} />
      {error && (
        <Typography variant="caption" color="error" fontSize={11}>
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default DateRangePickerInput;
