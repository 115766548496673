import axios from 'axios';

const getIPInfo = async () => {
  try {
    // TODO: store the response in the local-storage to reuse the value and avoid calling it again
    const { data } = await axios.get('http://ipinfo.io/?token=d896365570b98d');
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

export default getIPInfo;
