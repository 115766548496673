import { money } from '../../helpers/utils';
import Filters from './Filters';
import PropTypes from 'prop-types';

const HotelFilters = (props) => {
  const filtersSet = props.filtersSet || {};
  const filtersApplied = props.filtersApplied || {};
  const filters = [];

  if (filtersSet.price) {
    const { min, max, currency } = filtersSet.price;
    filters.push({
      type: 'minMaxSlider',
      title: 'Total price',
      min: min,
      max: max,
      minLabel: `${currency} $${money(filtersApplied.price ? filtersApplied.price[0] : min)}`,
      maxLabel: `${currency} $${money(filtersApplied.price ? filtersApplied.price[1] : max)}`,
      step: 10,
      value: filtersApplied.price ?? [min, max],
      onChange: (newPriceFilter) => {
        props.onChange({
          ...filtersApplied,
          price: newPriceFilter,
        });
      },
    });
  }

  if (filtersSet.byName) {
    filters.push({
      type: 'text',
      value: filtersApplied.byName,
      label: 'Property Name',
      onChange: (event) => {
        props.onChange({
          ...filtersApplied,
          byName: event.target.value,
        });
      },
    });
  }

  if (filtersSet.reimbursable) {
    filters.push({
      type: 'toggle',
      checked: filtersApplied.reimbursable,
      label: 'Reimbursable',
      onChange: (event) => {
        props.onChange({
          ...filtersApplied,
          reimbursable: event.target.checked,
        });
      },
    });
  }

  const propertiesWithCheckboxes = [
    { title: 'Property Rating', key: 'ratings' },
    { title: 'Property Type', key: 'types' },
    { title: 'Accommodation Type', key: 'accommodationTypes' },
    { title: 'Amenities', key: 'amenities' },
  ];

  propertiesWithCheckboxes
    .filter(({ key }) => filtersSet[key])
    .forEach(({ title, key }) =>
      filters.push({
        type: 'checkboxes',
        title,
        limit: props.checkboxesLimit || 6,
        values: filtersSet[key].map((value) => ({
          label: value,
          checked: filtersApplied[key]?.includes(value),
          onChange: (event) => {
            const newValues = event.target.checked
              ? [...filtersApplied[key], value]
              : filtersApplied[key]?.filter((item) => item !== value);

            props.onChange({
              ...filtersApplied,
              [key]: newValues,
            });
          },
        })),
      })
    );

  return <Filters filters={filters} onCloseDialog={props.onCloseDialog} onCleanup={props.onCleanup} />;
};

HotelFilters.propTypes = {
  filtersSet: PropTypes.shape({
    price: PropTypes.shape({
      min: PropTypes.number.isRequired,
      max: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
    }),
    certificate: PropTypes.bool,
    reimbursable: PropTypes.bool,
    ratings: PropTypes.arrayOf(PropTypes.string),
    types: PropTypes.arrayOf(PropTypes.string),
    accommodationTypes: PropTypes.arrayOf(PropTypes.string),
    amenities: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default HotelFilters;
