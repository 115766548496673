import { Box, Stack, TextField, Typography } from '@mui/material';
import {
  useStripe,
  useElements,
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  AddressElement,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { forwardRef, useImperativeHandle, useState, useRef, useEffect } from 'react';
import { isString } from 'lodash';
import CreditCardBrands from '../CreditCardBrands/CreditCardBrands';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const stripeOptions = {
  appearance: {
    theme: 'stripe',
  },
};

const CreditCardInput = (props) => {
  return (
    <Elements stripe={stripePromise} options={stripeOptions}>
      <StripeCreditCardInput {...props} />
    </Elements>
  );
};

const StripeCreditCardInput = (props) => {
  const form = useRef(null);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const [tokenError, setTokenError] = useState(null);
  const [cardElementErrors, setCardElementErrors] = useState({});

  const getCardNumberElements = () => ({
    cardNumberElement: elements.getElement(CardNumberElement),
    error: Object.values(cardElementErrors).find(Boolean),
  });

  const getCardAddressData = async () => {
    if (!props.withAddress) {
      return { error: false, cardAddress: null };
    }

    const addressElement = elements.getElement(AddressElement);
    const { complete, value } = await addressElement.getValue();

    return {
      error: !complete,
      cardAddress: {
        name: value?.name,
        address_country: value?.address?.country,
        address_state: value?.address?.state,
        address_city: value?.address?.city,
        address_line1: value?.address?.line1,
        address_line2: value?.address?.line2 || '',
        address_zip: value?.address?.postal_code,
      },
    };
  };

  const getCardInfoFromToken = (token) => ({
    type: token?.card?.funding,
    brand: token?.card?.brand,
    expMonth: token?.card?.exp_month,
    expYear: token?.card?.exp_year,
    last4: token?.card?.last4,
  });

  const createToken = async () => {
    try {
      if (!formSubmitted) {
        form.current.requestSubmit();
      }
      setFormSubmitted(true);
      setTokenError(null);

      const { cardNumberElement, error: cardError } = getCardNumberElements();
      const { cardAddress, error: addressError } = await getCardAddressData();

      if (cardError || addressError) {
        return { error: true };
      }

      const { token, error: newTokenError } = await stripe.createToken(cardNumberElement, { card: cardAddress });
      setTokenError(newTokenError);

      return {
        error: newTokenError,
        token,
        cardInfo: getCardInfoFromToken(token),
      };
    } catch (error) {
      console.error(error); // TODO: send this error to the server
      setTokenError('Unexpected Error');
      return { error: true };
    }
  };

  props.onSetCreateToken?.(createToken); // IMPORTANT: calling the onSetCreateToken must not cause a re-render

  const validationChange = (eleName) => (hasError) => {
    setTokenError(null);
    setCardElementErrors((currentValue) => ({ ...currentValue, [eleName]: hasError }));
  };

  return (
    <Box component="form" ref={form} onSubmit={(ev) => ev.preventDefault()}>
      <Box sx={{ mb: 2 }}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2} mb={2} ml={1}>
          <Typography color={'text.secondary'}>Accepted Payment Methods</Typography>
          <CreditCardBrands />
        </Stack>
        <CardTextField
          displayValidationErrors={formSubmitted}
          label="Card number*"
          component={CardNumberElement}
          options={{ showIcon: true }}
          onValidationChange={validationChange('cardNumber')}
        />
      </Box>

      <Box display="flex" mb={2}>
        <Box sx={{ width: '50%', mr: 4 }}>
          <CardTextField
            displayValidationErrors={formSubmitted}
            label="Expiration*"
            component={CardExpiryElement}
            onValidationChange={validationChange('expiration')}
          />
        </Box>
        <Box sx={{ width: '50%' }}>
          <CardTextField
            displayValidationErrors={formSubmitted}
            label="CVC*"
            component={CardCvcElement}
            onValidationChange={validationChange('cvc')}
          />
        </Box>
      </Box>

      {tokenError && (
        <Box sx={{ color: 'error.main', mt: -2, mb: 2 }}>{isString(tokenError) ? tokenError : tokenError.message || 'invalid card'}</Box>
      )}

      {props.withAddress && <AddressElement options={{ mode: 'billing' }} />}
    </Box>
  );
};

const CardTextField = ({ displayValidationErrors, label, component, options, onValidationChange }) => {
  const [hasError, setHasError] = useState(true);
  useEffect(() => {
    onValidationChange(hasError);
  }, [hasError]);

  return (
    <TextField
      fullWidth
      label={label}
      error={displayValidationErrors && !!hasError}
      helperText={displayValidationErrors && hasError ? 'Invalid' : ''}
      onChange={({ complete, error }) => setHasError(!complete || error)}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        notched: true,
        inputComponent: CardElementInput,
        inputProps: { component, options },
      }}
    />
  );
};

const CardElementInput = forwardRef((props, ref) => {
  // https://mui.com/material-ui/react-text-field/#integration-with-3rd-party-input-libraries
  const { component: Component, ...other } = props;

  useImperativeHandle(ref, () => ({
    focus: () => {
      // logic to focus the rendered component from 3rd party belongs here
    },
    // hiding the value e.g. react-stripe-elements
  }));

  // `Component` will be your `SomeThirdPartyComponent` from below
  return <Component {...other} />;
});

export default CreditCardInput;
