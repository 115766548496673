import HotelOverview from './HotelOverview';
import HotelLocation from './HotelLocation';
import HotelImportantInfo from './HotelImportantInfo';
import ImagesContainer from '../ImagesContainer';

const HotelDetails = (props) => {
  return (
    <>
      <ImagesContainer images={props.hotel.images} />
      {props.children}
      <HotelOverview {...props} />
      <HotelLocation {...props} />
      <HotelImportantInfo {...props} />
    </>
  );
};

export default HotelDetails;
