import { Box, IconButton, ImageList, ImageListItem, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { pick } from '../../helpers/utils';
import FullScreenCarousel from './FullScreenCarousel';

const LoadImagesAhead = ({ images }) => {
  <Box display="none">
    {images.map((src) => (
      <img key={src} src={src} alt="hotel" loading="eager" />
    ))}
  </Box>;
};

const ImagesContainer = ({ images }) => {
  const theme = useTheme();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [showFullScreenCarousel, setShowFullScreenCarousel] = useState(false);
  const [initialSlide, setInitialSlide] = useState(0);

  // const xs = useMediaQuery(theme.breakpoints.up('xs'));
  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  const xl = useMediaQuery(theme.breakpoints.up('xl'));

  let config;
  if (xl) {
    config = xlConfig(images);
  } else if (lg) {
    config = lgConfig(images);
  } else if (md) {
    config = mdConfig(images);
  } else if (sm) {
    config = smConfig(images);
  } else {
    config = xsConfig(images);
  }

  return (
    <Paper sx={{ px: 1, py: 1, mb: 2, mt: 2 }}>
      <LoadImagesAhead images={images} />
      <FullScreenCarousel images={images} />
      <ImageList sx={{ width: '100%', height: config.height }} variant="quilted" cols={config.cols} rowHeight={config.rowHeight}>
        {config.images.map(({ src, cols, rows }, index) => {
          const isLastImage = index === config.images.length - 1;

          return (
            <ImageListItem
              key={src}
              cols={cols}
              rows={rows}
              sx={
                (isLastImage && images.length > 1) ? {
                  '::before': {
                    content: `"+${images.length} Photos"`,
                    display: 'block',
                    width: '100%',
                    height: '100%',
                    background: 'black',
                    opacity: 0.6,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    color: 'white',
                    padding: '40px',
                    cursor: 'pointer',
                    textAlign: 'center',
                    textDecoration: 'underline',
                    fontSize: '20px',
                  },
                } : {}
              }
              onClick={() => setIsDialogOpen(true)}
            >
              <img src={src} loading="eager" alt="hotel" />
            </ImageListItem>
          );
        })}
      </ImageList>

      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} fullWidth={true} maxWidth="none">
        <DialogTitle sx={{ textAlign: 'center' }}>Images</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setIsDialogOpen(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box
          sx={{
            px: 2,
            py: 0,
            height: 'calc(100vh - 40px)',
            overflowY: 'scroll',
          }}
        >
          <ImageList variant="masonry" cols={config.masonryCols} gap={8}>
            {images.map((src, idx) => (
              <ImageListItem key={src}>
                <img src={src} alt="hotel" loading="eager" onClick={() => {
                  setShowFullScreenCarousel(true);
                  setInitialSlide(idx);
                }}/>
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
      </Dialog>

      <FullScreenCarousel
        images={images}
        onClose={() => setShowFullScreenCarousel(false)}
        initialSlide={initialSlide}
        show={showFullScreenCarousel}
      />
    </Paper>
  );
};

const xsConfig = (images) => {
  return {
    masonryCols: 1,
    cols: 1,
    height: 400,
    rowHeight: 400,
    images: images.slice(0, 1).map((image) => ({
      src: image,
      cols: 1,
      rows: 1,
    })),
  };
};

const smConfig = (images) => {
  if (images.length >= 3) {
    return {
      masonryCols: 2,
      cols: 5,
      height: 450 + 20,
      rowHeight: 450 / 2,
      images: images.slice(0, 3).map((image, index) => ({
        src: image,
        cols: index === 0 ? 3 : 2,
        rows: index === 0 ? 2 : 1,
      })),
    };
  }

  return xsConfig(images);
};

const mdConfig = (images) => {
  if (images.length >= 5) {
    return {
      masonryCols: 2,
      cols: 4,
      height: 300 + 20,
      rowHeight: 300 / 2,
      images: images.slice(0, 5).map((image, index) => ({
        src: image,
        cols: index === 0 ? 2 : 1,
        rows: index === 0 ? 2 : 1,
      })),
    };
  }

  return smConfig(images);
};

const lgConfig = (images) => {
  const count = 8;
  if (images.length >= count) {
    return {
      masonryCols: 3,
      cols: 10,
      height: 300,
      rowHeight: 260 / 10,
      images: images.slice(0, count).map((image, index) => ({
        src: image,
        cols: pick({ 0: 6, 1: 4, 2: 4, default: 2 }, index),
        rows: pick({ 0: 7, 1: 4, 2: 3, default: 3 }, index),
      })),
    };
  }

  return mdConfig(images);
};

const xlConfig = (images) => {
  const count = 10;
  if (images.length >= count) {
    return {
      masonryCols: 4,
      cols: 12,
      height: 400,
      rowHeight: 360 / 10,
      images: images.slice(0, count).map((image, index) => ({
        src: image,
        cols: pick({ 0: 5, 1: 4, 2: 3, 3: 3, default: 2 }, index),
        rows: pick({ 0: 7, 1: 7, 2: 4, 3: 3, default: 3 }, index),
      })),
    };
  }

  return lgConfig(images);
};

export default ImagesContainer;
