import { useState, useEffect, useMemo, useRef } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import debounce from 'lodash/debounce';
import { fetchLocations } from '../../api/locations';
import { makeStyles } from '@mui/styles';
import Popover from '../Popover/Popover';
import PlaceholderForInput from './PlaceholderForInput';
import SearchIcon from '@mui/icons-material/Search';

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    fontSize: '15px',
  },
}));

const locationWithLabel = (location) => {
  if (!location) {
    return null;
  }
  const { type, city, province, country } = location;

  const label = {
    city: {
      primary: city,
      secondary: [province, country].filter(Boolean).join(', '),
    },
    province: { primary: province, secondary: country },
    country: { primary: country },
  }[type];

  label.main = [label.primary, label.secondary].filter(Boolean).join(', ');

  return { ...location, label };
};

const getDisplayValue = (value) => (typeof value === 'string' ? value : value?.label?.main || '');

const LocationInput = (props) => {
  const [initialValue] = useState(locationWithLabel(props.initialValue) || undefined);
  const [value, setValue] = useState(initialValue);
  const [textInputValue, setTextInputValue] = useState(initialValue?.label?.main || '');
  const [searching, setSearching] = useState(false);
  const [options, setOptions] = useState([]);
  const classes = useStyles();
  const inputRef = useRef();
  const [hideError, setHideError] = useState(false)

  const searchLocations = useMemo(
    () =>
      debounce(() => {
        setSearching(true);
        fetchLocations({ searchText: textInputValue, language: 'en' }).then((locations) => {
          setOptions(locations.map(locationWithLabel));
          setSearching(false);
        });
      }, 400),
    [textInputValue]
  );

  useEffect(() => {
    if ((textInputValue?.length || 0) < 2) {
      return;
    }

    searchLocations();

    return () => {
      setSearching(false);
      searchLocations.cancel();
    };
  }, [textInputValue]);

  useEffect(() => {
    props.onChange?.(value);
  }, [value]);

  const error = props.required && !value && props.displayValidationErrors && !hideError ? props.errorLabel : undefined;

  return (
    <Box>
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={0}>
        <SearchIcon sx={{ fontSize: 24, marginRight: '-10px' }} />
        <Autocomplete
          // disablePortal
          fullWidth
          openOnFocus
          sx={{ width: '100%' }}
          size="small"
          classes={classes}
          getOptionLabel={getDisplayValue}
          filterOptions={(x) => x}
          options={options}
          // freeSolo
          // disableClearable
          autoComplete
          value={value || null}
          noOptionsText={textInputValue?.length >= 2 ? (searching ? 'searching...' : props.noLocationsLabel) : props.searchLocationLabel}
          onChange={(event, newValue) => setValue(newValue)}
          inputValue={textInputValue}
          onInputChange={(event, newInputValue) => {
            setTextInputValue(newInputValue);
            if (!newInputValue || newInputValue.length < 2) {
              setOptions([]);
            }
          }}
          isOptionEqualToValue={(option, value) => {
            return value?.code === option.code;
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              value={value}
              fullWidth
              // placeholder='Location'
              inputRef={inputRef}
              label="Location"
              onFocus={() => setHideError(true)}
              onBlur={() => setHideError(false)}
              InputProps={{
                ...params.InputProps,
                shrink: 'true',
                sx: {
                  ...params.InputProps.sx,
                  fontWeight: 'bold',
                  fontSize: '13.7px !important',
                  marginBottom: value ? '-15px !important' : 0,
                  // color: 'gray',
                  // '& ::placeholder': {
                  //   fontWeight: 'bold',
                  //   fontSize: 12,
                  //   opacity: 1, /* Firefox */
                  // },

                  '& .MuiInputLabel-formControl': {
                    fontSize: '16px !important',
                  },
                },
              }}
              sx={{
                width: '100%',
                '& fieldset': {
                  border: 'none',
                },
                '& .MuiInputLabel-formControl': {
                  color: 'gray !important',
                  fontWeight: 'bold !important',
                  fontSize: '13.7px',
                },
                '& .MuiAutocomplete-endAdornment': {
                  marginTop: value ? '-6px' : 0,
                },
                '& . MuiInputLabel-shrink': {
                  color: 'red !important',
                  transform: 'translate(14px, -9px) scale(1)',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  fontSize: '12px',
                },
                '& .MuiInputBase-fullWidth': {
                  paddingRight: '20px !important',
                },
                '& .MuiAutocomplete-popupIndicator': {
                  display: 'none'
                }
              }}
              // fullWidth
            />
          )}
          renderOption={(props, option) => {
            return (
              <li
                {...props}
                key={option.code}
                onClick={(ev) => {
                  // close(ev);
                  props.onClick?.(ev);
                }}
              >
                <Grid container alignItems="center">
                  <Grid item sx={{ display: 'flex', width: 44 }}>
                    <LocationOnIcon sx={{ color: 'text.secondary' }} />
                  </Grid>
                  <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                    <Box component="span" sx={{ fontWeight: 'regular' }}>
                      {option.label.primary}
                    </Box>
                    <Typography variant="body2" color="text.secondary">
                      {option.label.secondary}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            );
          }}
        />
      </Stack>
      {error && (
        <Typography variant="caption" color="error" sx={{ display: 'block', fontSize: 11, mt: '-7px' }}>
          {error}
        </Typography>
      )}
    </Box>
  );
};

LocationInput.defaultProps = {
  label: 'Location',
  errorLabel: 'Destination required',
  noLocationsLabel: 'No locations',
  searchLocationLabel: 'Search a location',
};

export default LocationInput;
