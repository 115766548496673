import { Box, Checkbox, FormControlLabel, FormGroup, Typography, TextField } from '@mui/material';
import { useRef, useState } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TextFields } from '@mui/icons-material';
import debounce from 'lodash/debounce';

const TextFilter = ({ filter }) => {
  const { value, label, onChange } = filter;

  const valueRef = useRef(value)

  const [displayValue, setDisplayValue] = useState(value)
  const debouncedRef = useRef(null);

  if (valueRef.current !== value) {
    valueRef.current = value
    if (value !== displayValue) {
      setDisplayValue(value);
    }
  }

  return (
    <>
      <Typography variant="div" color="text.primary">
        {label}
      </Typography>
      <FormGroup sx={{ ml: 1 }}>
        <FormControlLabel
          key={label}
          control={<TextField size="small" fullWidth onChange={(event) => {
            const ev = { target: { value: event.target.value } }
            setDisplayValue(ev.target.value)

            if (debouncedRef.current) {
              debouncedRef.current.cancel();
            }

            debouncedRef.current = debounce(() => {
              onChange(ev)
            }, 400)
            debouncedRef.current()
          }} value={displayValue} />}
          sx={{ mb: '-11px' }}
        />
      </FormGroup>
    </>
  );
};

export default TextFilter;
