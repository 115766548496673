import React from 'react';
import { Route } from 'react-router-dom';
import HomeLayout from './HomeLayout';
import HomePage from './HomePage';
import ContactPage from './ContactPage';
import HotelAvailabilityPage from './HotelAvailabilityPage';
import NotFoundPage from '../NotFoundPage';
import ErrorPage from '../ErrorPage';
import SearchPage from './SearchPage';
import AboutPage from './AboutPage';
import TermsPage from './TermsPage';
import PrivacyPage from './PrivacyPage';
import Referral from '../Referral';

const homeRouter = (
  <Route path="/" element={<Referral><HomeLayout /></Referral>} errorElement={<ErrorPage />}>
    <Route index element={<HomePage />} />
    <Route path="/search" element={<SearchPage />} />
    <Route path="/contact" element={<ContactPage />} />
    <Route path="/about" element={<AboutPage />} />
    <Route path="/terms" element={<TermsPage />} />
    <Route path="/privacy" element={<PrivacyPage />} />
    <Route path={HotelAvailabilityPage.linkPattern} element={<HotelAvailabilityPage />} />
    <Route path="*" element={<NotFoundPage />} />
  </Route>
);

export default homeRouter;
