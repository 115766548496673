import { createCoupon, getCoupons } from '../../api/admin';
import useDataLoader from '../../hooks/use-data-loader';
import {
  Box,
  Button,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import MyDataGrid from '../../components/MyDataGrid/MyDataGrid';
import { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { v4 as id } from 'uuid';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { randomCouponCode } from '../../helpers/utils';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const filterTypes = [
  { id: 'startAt', name: 'Start at' },
  { id: 'endAt', name: 'End at' },
  { id: 'priceGt', name: 'Price greater than' },
  { id: 'limit', name: 'Limit' },
  { id: 'agentName', name: 'Agent Name' },
  { id: 'clientName', name: 'Client Name' },
];

const errorCode = (code) => {
  if (!code || code.trim().length === 0) {
    return 'required';
  }
  if (code.length < 3) {
    return 'Min length is 3';
  }

  return null;
};

const errorRequired = (value) => (!value || value.trim().length === 0 ? 'required' : '');
const dateRequired = (value) => (!value ? 'required' : '');

const gt = (value, number) => (value <= number ? `Min value is ${number}` : '');

const NewCouponPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [displayValidationErrors, setDisplayValidationErrors] = useState(false);
  const [active, setActive] = useState(true);
  const [code, setCode] = useState('');
  const [discountType, setDiscountType] = useState('');
  const [discountAmount, setDiscountAmount] = useState('');
  const [filters, setFilters] = useState([]);
  const [isPromotionCoupon, setIsPromotionCoupon] = useState(false);
  const [promotionText, setPromotionText] = useState('');

  const addFilter = () => setFilters((currentFilters) => [...currentFilters, { id: id(), type: null, value: null }]);
  const removeFilter = (i) => () => setFilters((currentFilters) => currentFilters.filter((v, index) => index !== i));
  const onChangeFilter = (i, newFilter) =>
    setFilters((currentFilters) => currentFilters.map((filter, index) => (index === i ? newFilter : filter)));
  const validationErrors = (validation) => ({
    error: displayValidationErrors && !!validation,
    helperText: displayValidationErrors ? validation : '',
  });

  const errors = {
    code: errorCode(code),
    discountType: errorRequired(discountType),
    discountAmount: gt(discountAmount, 0),
    filters: filters.map((filter) => ({
      type: errorRequired(filter.type),
      value:
        filter.type === 'startAt' || filter.type === 'endAt'
          ? dateRequired(filter.value)
          : filter.type === 'priceGt' || filter.type === 'limit'
          ? gt(filter.value, 0)
          : errorRequired(filter.value),
    })),
  };

  const hasError = !!(
    errors.code ||
    errors.discountType ||
    errors.discountAmount ||
    errors.filters.find((filter) => filter.type || filter.value)
  );

  const goToCouponsPage = () => navigate('/admin/coupons');

  const submit = async (ev) => {
    ev.preventDefault();
    setDisplayValidationErrors(true);

    if (hasError || loading) {
      return;
    }

    setLoading(true);
    try {
      const newCoupon = await createCoupon({ active, code, discountType, discountAmount, filters, isPromotionCoupon, promotionText });
      toast.success('Coupon created');
      goToCouponsPage();
    } catch (error) {
      toast.error(`Failed creating coupon. ${error.response?.data?.message || ''}`);
    }

    setLoading(false);
  };

  const cancel = () => {
    goToCouponsPage();
  };

  return (
    <Paper sx={{ p: 2, px: 3, height: '100%' }}>
      <Typography variant="h5" mb={2}>
        Create new Coupon
      </Typography>

      <Box component="form" maxWidth={'800px'} onSubmit={submit}>
        <FormControlLabel
          sx={{ mb: 3 }}
          control={<Switch checked={active} onChange={(ev) => setActive(ev.target.checked)} name="active" />}
          label="Active"
        />

        <Stack direction="row" spacing={2} mb={3}>
          <TextField
            label="Code*"
            name="code"
            fullWidth
            sx={{ minWidth: 200, maxWidth: 500 }}
            value={code}
            {...validationErrors(errors.code)}
            onChange={(ev) => setCode(ev.target.value)}
          />
          <Box>
            <Button variant="outlined" onClick={() => setCode(randomCouponCode())}>
              Generate
            </Button>
          </Box>
        </Stack>

        <Stack direction="row" spacing={2} mb={3}>
          <TextField
            select
            label="Type of discount"
            sx={{ minWidth: '200px' }}
            value={discountType}
            {...validationErrors(errors.discountType)}
            onChange={(ev) => setDiscountType(ev.target.value)}
          >
            <MenuItem value={'percentage'}>Percentage</MenuItem>
            <MenuItem value={'fixed'}>Fixed</MenuItem>
          </TextField>

          <TextField
            type="number"
            label="Discount"
            value={discountAmount}
            onChange={(ev) => setDiscountAmount(ev.target.value)}
            {...validationErrors(errors.discountAmount)}
            InputProps={{
              startAdornment: discountType === 'percentage' ? <InputAdornment position="start">%</InputAdornment> : '',
            }}
          />
        </Stack>

        <Stack direction="row" spacing={2} mb={3}>
          <FormControlLabel
            sx={{ mb: 3 }}
            control={
              <Switch checked={isPromotionCoupon} onChange={(ev) => setIsPromotionCoupon(ev.target.checked)} name="isPromotionCoupon" />
            }
            label="Is a Promotion Coupon"
          />
          <TextField
            label="Promotion Coupon Text"
            name="promotionText"
            fullWidth
            sx={{ minWidth: 200, maxWidth: 500 }}
            value={promotionText}
            onChange={(ev) => setPromotionText(ev.target.value)}
          />
        </Stack>

        <Typography variant="h6">Filters</Typography>

        {filters.map((filter, i) => (
          <Stack direction="row" p={1} spacing={2} ml={-1} key={filter.id}>
            <TextField
              select
              label="Select"
              sx={{ minWidth: 200, ml: -2 }}
              value={filter.type}
              onChange={(ev) => onChangeFilter(i, { type: ev.target.value, value: null })}
              {...validationErrors(errors.filters[i].type)}
            >
              {filterTypes.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </TextField>

            {(filter.type === 'startAt' || filter.type === 'endAt') && (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  sx={{ minWidth: 300 }}
                  label="Select Date and Time"
                  value={filter.value}
                  onChange={(newValue) => onChangeFilter(i, { type: filter.type, value: newValue })}
                  slotProps={{
                    textField: validationErrors(errors.filters[i].value),
                  }}
                />
              </LocalizationProvider>
            )}

            {(filter.type === 'priceGt' || filter.type === 'limit') && (
              <>
                <TextField
                  label="Value"
                  name="value"
                  sx={{ minWidth: 300 }}
                  type="number"
                  {...validationErrors(errors.filters[i].value)}
                  onChange={(ev) => onChangeFilter(i, { type: filter.type, value: ev.target.value })}
                />
              </>
            )}

            {(filter.type === 'agentName' || filter.type === 'clientName') && (
              <>
                <TextField
                  label="Value"
                  name="code"
                  sx={{ minWidth: 300 }}
                  {...validationErrors(errors.filters[i].value)}
                  onChange={(ev) => onChangeFilter(i, { type: filter.type, value: ev.target.value })}
                />
              </>
            )}

            <Box>
              <Button onClick={removeFilter(i)}>Remove</Button>
            </Box>
          </Stack>
        ))}

        <Button onClick={addFilter}>Add Filter</Button>

        <Stack direction="row" spacing={3} mt={3}>
          <Button size="large" variant="outlined" onClick={cancel}>
            Cancel
          </Button>
          <Button size="large" variant="contained" type="submit" disabled={(displayValidationErrors && hasError) || loading}>
            Create
          </Button>
        </Stack>
      </Box>
    </Paper>
  );
};

export default NewCouponPage;
