import { useEffect, useRef, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  gridClasses,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import { formatDateTime } from '../../helpers/date-time';
import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { getObjFromLocalStorage, saveObjToLocalStorage } from '../../helpers/utils';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Popover from '../Popover/Popover';
import DraggableList from '../DraggableList/DraggableList';
import { COLOR } from '../../helpers/styles';
import { isArray } from 'lodash';
import Dialog from '../Dialogs/Dialog';
import ResizableSlider from '../ResizableSlider/ResizableSlider';
import MyTabs from '../MyTabs/MyTabs';
import PrettyJson from '../PrettyJson';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ConfirmationDialog from '../Dialogs/ConfirmationDialog';
import { toast } from 'react-toastify';

const GridToolbarSortColumns = (props) => {
  const [currentCols, setCurrentCols] = useState(props.columns);
  useEffect(() => setCurrentCols(props.columns), [props.columns]);

  return (
    <>
      <Popover
        // disableCloseOnClickAway
        openOn={'click'}
        horizontal={0}
        onClosed={() => props.onChange(currentCols)}
        renderTarget={({ open }) => (
          <Button onClick={open} startIcon={<DragIndicatorIcon />}>
            Sort
          </Button>
        )}
        renderChildren={({ close }) => (
          <>
            <IconButton aria-label="delete" size="large" onClick={close}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
            <DraggableList
              items={currentCols}
              renderItem={(item) => (
                <Box
                  sx={{
                    '&:hover': { cursor: 'pointer', backgroundColor: COLOR.LIGHT_BACKGROUND },
                    width: '100%',
                    px: 1,
                    py: 1,
                  }}
                >
                  {item.headerName || item.field}
                </Box>
              )}
              getItemId={(item) => item.field}
              onChange={setCurrentCols}
            />
          </>
        )}
      />
    </>
  );
};

function CustomToolbar(props) {
  const { additionalToolbarOptions, columns, onChange } = props;
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      <GridToolbarSortColumns columns={columns} onChange={onChange} />
      {additionalToolbarOptions}
    </GridToolbarContainer>
  );
}

const sortColumns = (columns, prevColumns) =>
  columns.sort((a, b) => {
    const ai = prevColumns.findIndex((item) => item.field === a.field);
    const bi = prevColumns.findIndex((item) => item.field === b.field);

    if (bi === -1) {
      return -1; // a goes first
    }

    if (ai === -1) {
      return 1; // b goes first
    }

    return ai - bi;
  });

const MyDataGrid = (props) => {
  const { skipView, onEdit, onDelete } = props;

  const [viewable, editable, deletable] = [!skipView, !!onEdit, !!onDelete];

  const [item, setItem] = useState(null);
  const openDialogFnRef = useRef(null);

  const openDeleteDialogRef = useRef(null);
  const closeDeleteDialogRef = useRef(null);

  const onCloseDialog = () => {
    // setItem(null);
  };

  const keyFoVisibility = `dataTable_visibility_${props.name}`;
  const [columnsVisibility, setColumnsVisibility] = useState(() => getObjFromLocalStorage(keyFoVisibility));
  // useEffect(() => , [columnsVisibility]);

  const keyForOrdering = `dataTable_ordering_${props.name}`;
  const [columnsOrder, setColumnsOrder] = useState(() => getObjFromLocalStorage(keyForOrdering));
  useEffect(() => saveObjToLocalStorage(keyForOrdering, columnsOrder), [columnsOrder]);

  const toggleRightRef = useRef();
  const showRef = useRef();
  const hideRef = useRef();

  const onViewRight = (item) => {
    setItem(item);
  };

  useEffect(() => {
    if (!item) {
      return;
    }

    const newItem = props.rows.find(({ _id }) => item._id === _id);
    setItem(newItem ?? null);
  }, [props.rows]);

  useEffect(() => {
    if (item) {
      showRef.current();
    } else {
      hideRef.current();
    }
  }, [item]);

  const [columns, setColumns] = useState(() => {
    const result = [
      {
        field: '_id',
        hideable: false,
        headerName: '',
        width: [viewable, editable, deletable].filter(Boolean).length * 40,
        renderCell: ({ row: item }) => {
          return (
            <Stack direction="row">
              {viewable && (
                <GridActionsCellItem
                  key={`${item._id}-view`}
                  icon={<VisibilityIcon />}
                  label="Save"
                  sx={{
                    color: 'primary.main',
                  }}
                  onClick={(ev) => {
                    ev.stopPropagation();
                    setItem(item);
                    openDialogFnRef.current();
                  }}
                />
              )}
              {editable && (
                <GridActionsCellItem
                  key={`${item._id}-edit`}
                  icon={<EditIcon />}
                  label="Save"
                  sx={{
                    color: 'primary.main',
                  }}
                  onClick={(ev) => {
                    ev.stopPropagation();
                    setItem(item);
                    onEdit(item);
                  }}
                />
              )}
              {deletable && (
                <GridActionsCellItem
                  key={`${item._id}-delete`}
                  icon={<DeleteForeverIcon />}
                  label="Save"
                  sx={{
                    color: 'primary.main',
                  }}
                  onClick={(ev) => {
                    ev.stopPropagation();
                    setItem(item);
                    openDeleteDialogRef.current();
                  }}
                />
              )}
            </Stack>
          );
        },
      },
      {
        field: 'createdAt',
        headerName: 'Created',
        // type: 'date',
        valueGetter: ({ row: item }) => {
          console.log('DATE', item.createdAt);
          return new Date(item.createdAt)
        },
        sortComparator: (v1, v2) => v1 - v2,
        // flex: 1,
        renderCell: ({ row: item }) => {
          return <Box key={item._id}>{item.createdAt ? formatDateTime(item.createdAt) : ''}</Box>;
        },
        width: 180,
      },
      ...props.columns,
    ];

    if (!isArray(columnsOrder)) {
      return result;
    }

    return sortColumns(result, columnsOrder);
  });

  const tab1 = () => (
    <>
      {props.renderBeforeView?.(item)}
      {props.renderView
        ? props.renderView(item)
        : columns.map((column) =>
            column.field === '_id' ? (
              <Box key={column.headerName} mb={2} />
            ) : (
              <Box key={column.headerName} mb={2}>
                <Typography component="span" fontWeight="bold">
                  {column.headerName}:
                </Typography>
                {column.renderCell ? column.renderCell({ row: item }) : <Typography gutterBottom>{`${item[column.field]}`}</Typography>}
              </Box>
            )
          )}
      {props.renderAfterView?.(item)}
    </>
  );
  const tab2 = () => <PrettyJson obj={item.obj || item} collapsed={3} collapseStringsAfterLength={200} />;

  const selectedItemDetails = item && <MyTabs labels={['View', 'Details']}>{[tab1, tab2]}</MyTabs>;

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        overflow: 'hidden',
        backgroundColor: 'white',
        '& .Mui-even': {
          backgroundColor: '#f3f3f563',
        },
        '& .Mui-odd': {
          backgroundColor: 'white',
        },
        '& .MuiDataGrid-toolbarContainer': {
          borderBottom: '1px solid #dfe0e4',
          p: 1,
        },
        '& .MuiDataGrid-toolbarContainer button': {
          marginLeft: 2,
        },
        '& .MuiDataGrid-columnHeaderTitle': {
          fontSize: '13px',
        },
      }}
    >
      <Dialog
        titleLabel={props.name}
        onSetOpenDialog={(openDialogFn) => (openDialogFnRef.current = openDialogFn)}
        onClose={onCloseDialog}
        renderTarget={() => null}
      >
        {selectedItemDetails}
      </Dialog>

      {onDelete && (
        <ConfirmationDialog
          titleLabel={'Do you want to deleted this element?'}
          hideOpenDialogBtn
          hideCloseDialogBtn
          skipDialogContent
          onSetOpenDialog={(openDialog) => {
            openDeleteDialogRef.current = openDialog;
          }}
          onSetCloseDialog={(closeDialog) => {
            closeDeleteDialogRef.current = closeDialog;
          }}
          onSave={async () => {
            try {
              await onDelete(item);
            } catch(error) {
              toast.error(`An error has occurred. ${error.response?.data?.message || ''}`);
              return false;
            }

            return true;
          }}
          onCloseDialog={() => {
            setItem(null);
            return true;
          }}
        />
      )}

      <ResizableSlider
        name={props.name}
        onSetToggleRight={(toggleRight) => {
          toggleRightRef.current = toggleRight;
        }}
        onSetShow={(show) => {
          showRef.current = show;
        }}
        onSetHide={(hide) => {
          hideRef.current = hide;
        }}
        left={
          <Box
            sx={{
              width: '100%',
              height: '100%',
              minWidth: '50px',
              pr: 1,
              border: '1px solid',
              borderColor: COLOR.GRAY_LINE_SEPARATOR,
              '& div.MuiDataGrid-row.Mui-selected': {
                backgroundColor: '#0288d154',
                // color: 'white',
              },
              '& div.MuiDataGrid-row.Mui-selected.Mui-hovered': {
                backgroundColor: '#0288d154',
                // color: 'white',
              },
              '& div.MuiDataGrid-row.Mui-selected button.MuiButtonBase-root.MuiIconButton-root': {
                // color: 'white',
              },
            }}
          >
            <DataGrid
              {...(props.autoHeight ? { getRowHeight: () => 'auto' } : {})}
              autoPageSize
              getRowId={(message) => message._id}
              getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd')}
              columns={columns}
              rows={props.rows}
              onColumnVisibilityModelChange={(newColumnsVisibility, ...args) => {
                setColumnsVisibility(newColumnsVisibility);
                saveObjToLocalStorage(keyFoVisibility, newColumnsVisibility);
              }}
              columnVisibilityModel={columnsVisibility}
              slots={{
                toolbar: () => (
                  <CustomToolbar
                    additionalToolbarOptions={props.additionalToolbarOptions}
                    columns={columns}
                    onChange={(newColumns) => {
                      setColumns(newColumns);
                      setColumnsOrder(newColumns);
                      saveObjToLocalStorage(keyForOrdering, newColumns);
                    }}
                  />
                ),
              }}
              onCellClick={({ row }) => {
                onViewRight(row);
              }}
              sx={{
                width: '100%',
                [`& .${gridClasses.cell}`]: {
                  py: 2,
                },
              }}
            />
          </Box>
        }
        right={
          <Box
            sx={{
              width: '100%',
              height: '100%',
              minWidth: '50px',
              p: 2,
              border: '1px solid',
              borderColor: COLOR.GRAY_LINE_SEPARATOR,
            }}
          >
            <Box sx={{ width: '100%', height: '100%', overflowX: 'hidden', position: 'relative' }}>
              <IconButton
                aria-label="close"
                color="primary.main"
                onClick={() => hideRef.current()}
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  color: (theme) => 'white',
                  backgroundColor: 'info.main',
                  '&:hover': {
                    backgroundColor: 'primary.main',
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
              {selectedItemDetails}
              {item && props.renderExtraView?.(item)}
            </Box>
          </Box>
        }
      />
    </Box>
  );
};

export default MyDataGrid;
