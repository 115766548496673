import { Box, Button, Stack, TextField } from '@mui/material';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { getCoupon } from '../../api/admin';
import Dialog from '../Dialogs/Dialog';

const Coupon = ({ price, clientName, agentName, coupon, onSetCoupon }) => {
  const [couponCode, setCouponCode] = useState('');
  const [loading, setLoading] = useState(false);

  const applyCoupon = (closeDialog) => async () => {
    setLoading(true);

    const coupon = await getCoupon({
      code: couponCode,
      price,
      agentName,
      clientName,
    });

    if (!coupon.applicable) {
      toast.error(coupon.reason);
      setLoading(false);
      return;
    }

    toast.success('Coupon applied');
    onSetCoupon?.(coupon);
    setCouponCode('');
    setLoading(false);
    closeDialog();
  };

  const removeCoupon = () => onSetCoupon?.(null);

  return (
    <Box mb={3}>
      {coupon ? (
        <Button variant="text" onClick={removeCoupon}>
          Remove Coupon
        </Button>
      ) : (
        <Dialog
          titleLabel="Add Coupon"
          renderTarget={({ openDialog }) => (
            <Button variant="text" onClick={openDialog}>
              I have a coupon
            </Button>
          )}
          renderContent={({ closeDialog }) => (
            <Stack direction="row" spacing={2} p={4} alignItems="center">
              <TextField value={couponCode} onChange={(ev) => setCouponCode(ev.target.value)} label="Enter your coupon" />
              <Button variant="contained" disabled={(couponCode || '').trim().length === 0 || loading} onClick={applyCoupon(closeDialog)}>
                Apply
              </Button>
            </Stack>
          )}
        />
      )}
    </Box>
  );
};

export default Coupon;
