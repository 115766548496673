import { Button, Box } from '@mui/material';
import Accordion from './Accordion';

const AccordionDownloadableLink = (props) => {
  const { link, children, ...otherProps } = props;

  return (
    <Accordion
      headerChildren={
        <Box sx={{ marginLeft: 'auto' }} onClick={(ev) => ev.stopPropagation()}>
          <Button variant="outlined" href={link} target="_blank">
            Download
          </Button>
        </Box>
      }
      {...otherProps}
    >
      {children}
    </Accordion>
  );
};

export default AccordionDownloadableLink;
