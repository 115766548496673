import { buildQueryString } from '../helpers/utils';
import apiRequest from './api-request';

export const getDashboards = async () => {
  const { data } = await apiRequest.get('/admin/dashboards');

  return data;
};

export const getOrders = async () => {
  const { data } = await apiRequest.get('/admin/orders');

  return data;
};

export const getMessages = async () => {
  const { data } = await apiRequest.get('/admin/messages');

  return data;
};

export const getCoupons = async () => {
  const { data } = await apiRequest.get('/admin/coupons');

  return data;
};

export const createCoupon = async (coupon) => {
  const { data } = await apiRequest.post('/admin/coupons', coupon);

  return data;
};

export const activateCoupon = async (couponId) => {
  const { data } = await apiRequest.post(`/admin/coupons/${couponId}/activate`);

  return data;
};

export const deactivateCoupon = async (couponId) => {
  const { data } = await apiRequest.post(`/admin/coupons/${couponId}/deactivate`);

  return data;
};

export const deleteCoupon = async (couponId) => {
  const { data } = await apiRequest.delete(`/admin/coupons/${couponId}`);

  return data;
};

export const getCoupon = async ({ code, ...queryStringProps }) => {
  const { data } = await apiRequest.get(`/coupons/${code}?${buildQueryString(queryStringProps)}`);

  return data;
}

export const getOrderCancellationPolicies = async ({ reservationNumber }) => {
  const { data } = await apiRequest.get(`/admin/orders/cancellationPolicies?${buildQueryString({ reservationNumber })}`);

  return data;
}

export const sendRefundOrder = async ({ orderId }) => {
  const { data } = await apiRequest.post(`/admin/orders/${orderId}/refund`);

  return data;
}

export const getFees = async () => {
  const { data } = await apiRequest.get('/admin/fees');

  return data;
}

export const deleteFees = async (feeId) => {
  const { data } = await apiRequest.delete(`/admin/fees/${feeId}`);

  return data;
}

export const createFees = async (fee) => {
  const { data } = await apiRequest.post('/admin/fees', fee);

  return data;
}

export const updateFees = async (feeId, fee) => {
  const { data } = await apiRequest.post(`/admin/fees/${feeId}`, fee);

  return data;
}

export const getSettings = async () => {
  const { data } = await apiRequest.get('/admin/settings');

  return data;
}

export const updateSettings = async (settings) => {
  const { data } = await apiRequest.post('/admin/settings', settings);

  return data;
}

export const getBanners = async () => {
  const { data } = await apiRequest.get('/admin/banners');

  return data;
}

export const createBanner = async (banner) => {
  const { data } = await apiRequest.post('/admin/banners', banner);

  return data;
}

export const updateBanner = async (id, banner) => {
  const { data } = await apiRequest.post(`/admin/banners/${id}`, banner, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

  return data;
}

export const deleteBanner = async (id) => {
  const { data } = await apiRequest.delete(`/admin/banners/${id}`);

  return data;
}

export const getReferrals = async () => {
  const { data } = await apiRequest.get('/admin/referrals');

  return data;
};

export const getReferral = async (referralId) => {
  const { data } = await apiRequest.get(`/admin/referrals/${referralId}`);

  return data;
};

export const createReferral = async (referral) => {
  const { data } = await apiRequest.post('/admin/referrals', referral);

  return data;
};

export const updateReferral = async (referral) => {
  const { data } = await apiRequest.post(`/admin/referrals/${referral._id}`, referral);

  return data;
};

export const deleteReferral = async (referralId) => {
  const { data } = await apiRequest.delete(`/admin/referrals/${referralId}`);

  return data;
};

export const updateOrderReferralPaid = async (referralId, orderId, paid) => {
  const { data } = await apiRequest.post(`/admin/referrals/${referralId}/${orderId}/${paid ? 'paid' : 'unpaid'}`, {});

  return data;
};

export const getLogs = async () => {
  const { data } = await apiRequest.get('/admin/logs');

  return data;
};

export const deleteLog = async (logId) => {
  const { data } = await apiRequest.delete(`/admin/logs/${logId}`);

  return data;
};
