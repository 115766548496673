import apiRequest from './api-request';

export const fetchHotelInfo = async ({ hotelCode }) => {
  const { data } = await apiRequest.get(`/hotels/${hotelCode}`);

  return data;
};

export const preReserve = async ({ params }) => {
  const { data } = await apiRequest.post('/hotels/pre-reserve', params);

  return data;
};

export const confirmReserve = async ({ params }) => {
  const { data } = await apiRequest.post('/hotels/reserve', params);

  return data;
}

export const cancelPreReserve = async ({ params }) => {
  const { data } = await apiRequest.post('/hotels/cancel-pre-reserve', params);

  return data;
}

export const searchHotelsAvailabilities = async ({ params }) => {
  const { data } = await apiRequest.get('/hotels', { params });

  return data;
};

export const getPromotionCoupons = async () => {
  const { data } = await apiRequest.get('/hotels/promotions/coupons');

  return data;
}

export const getBanners = async (filters = {}) => {
  const { data } = await apiRequest.get('/hotels/promotions/banners', { params: filters });

  return data;
}

export const getReferral = async (referralCode) => {
  const { data } = await apiRequest.get(`/hotels/referrals/${referralCode}`);

  return data;
}

export const getExpenseFees = async() => {
  const { data } = await apiRequest.get('/hotels/fees/expenses');

  return data;
}

export const getCoupon = async() => {
  const { data } = await apiRequest.get('/hotels/fees/expenses');

  return data;
}
