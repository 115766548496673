
import { Box, Link, Typography } from '@mui/material';
import { withDataLoader } from '../../hooks/use-data-loader';
import { getDashboards } from '../../api/admin';

const DashboardPage = ({ data: dashboardsData }) => (
  <Box height="100%" sx={{pt: 5, pl: 5}}>
    <Typography variant="h6" mb={2}>Admin Links</Typography>
    <Box component="ul" pl={4}>
      <Box component="li" mb={1}><Link href="/">Website</Link></Box>
      <Box component="li" mb={1}><Link href="/admin/balance">Balance</Link></Box>
      <Box component="li" mb={1}><Link href="/admin/orders">Orders</Link></Box>
      <Box component="li" mb={1}><Link href="/admin/messages">Messages</Link></Box>
      <Box component="li" mb={1}><Link href="/admin/coupons">Coupons</Link></Box>
      <Box component="li" mb={1}><Link href="/admin/banners">Banners</Link></Box>
      <Box component="li" mb={1}><Link href="/admin/referrals">Referrals</Link></Box>
      <Box component="li" mb={1}><Link href="/admin/order-referrals">Order Referrals</Link></Box>
      <Box component="li" mb={1}><Link href="/admin/logs">Logs</Link></Box>
    </Box>
  </Box>
);

export default withDataLoader(DashboardPage, getDashboards);
