import format from 'date-fns/format';
import parse from 'date-fns/parse';
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';
import differenceInDays from 'date-fns/differenceInDays';
import { capitalizeFirstLetter } from './utils';
import { addDays, isSaturday, isSunday, startOfWeek } from 'date-fns';

const DATE_FORMAT = 'MM-dd-yyyy';

export const formatToDate = (dateTime) => format(dateTime, DATE_FORMAT);

export const parseDate = (dateStr) => parse(dateStr, DATE_FORMAT, new Date());

export const humanize = ({ dateStr, time, locale = 'en' }) => {
  const date = parseDate(dateStr);

  if (locale === 'es') {
    return capitalizeFirstLetter(format(date, `EEEE, d 'de' MMMM 'de' yyyy, 'a las ${time}'`, { locale: es }));
  }

  return capitalizeFirstLetter(format(date, `EEEE, MMMM do, yyyy, 'at ${time}'`, { locale: en }));
};

export const formatDateTime = (dateTime) => format(new Date(dateTime), 'yyyy-MM-dd hh:mm:ss a');

export const amountOfNights = (dateStr1, dateStr2) => Math.abs(differenceInDays(parseDate(dateStr1), parseDate(dateStr2)));

export const getNextWeekend = (startDate) => {
  // Start from the next Monday to ensure we're looking forward
  let date = startOfWeek(addDays(startDate || new Date(), 1));

  // Find the next Saturday
  while (!isSaturday(date)) {
    date = addDays(date, 1);
  }

  // Find the next Sunday
  let nextSunday = addDays(date, 1);
  while (!isSunday(nextSunday)) {
    nextSunday = addDays(nextSunday, 1);
  }

  return { startDate: date, endDate: nextSunday };
}

export const isInTheFuture = date => (new Date().getTime() - new Date(date).getTime()) < 0
export const isInThePast = date => !isInTheFuture(date);
