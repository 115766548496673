import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import isEmail from 'validator/lib/isEmail';
import { capitalizeFirstLetter } from '../../helpers/utils';
import { sendMessage } from '../../api/send-message';
import AnimatedSVG from '../Controllers/AnimatedSVG';
import { toast } from 'react-toastify';

const Form = ({ getInputProps, submit }) => (
  <Box component="form" onSubmit={submit}>
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <TextField {...getInputProps('name')} />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField {...getInputProps('email')} />
      </Grid>
    </Grid>
    <TextField {...getInputProps('subject')} />
    <TextField {...getInputProps('message')} multiline rows={6} />
    <Button variant="contained" type="submit" sx={{ minWidth: '100px' }} size="large">
      Send Message
    </Button>
  </Box>
);

const MessageSent = () => (
  <Box title="">
    <AnimatedSVG />
    <Typography variant="h4" mt={2} color="success.main" textAlign="center">
      Your message have been sent!
    </Typography>
  </Box>
);

const MessageBox = (props) => {
  const [displayValidationErrors, setDisplayValidationErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [data, setData] = useState({ name: '', email: '', subject: '', message: '' });

  const onChange = (event) => setData((currentData) => ({ ...currentData, [event.target.name]: event.target.value }));

  const isInputInvalid = (name) => (name === 'email' ? !isEmail(data[name]) : !data[name].trim());

  const submit = async (event) => {
    event.preventDefault();
    if (loading) {
      return;
    }

    setLoading(true);
    setDisplayValidationErrors(true);

    const containsAtLeastOneInvalidProp = Object.keys(data).filter((name) => isInputInvalid(name)).length > 0;
    if (containsAtLeastOneInvalidProp) {
      setLoading(false);
      return;
    }

    try {
      await sendMessage({ params: data });
      setMessageSent(true);
      toast.success('Message sent!');
    } catch {
      toast.error('There was an error sending the message. Please try again');
    }

    setLoading(false);
  };

  const getInputProps = (name) => ({
    label: `${capitalizeFirstLetter(name)}*`,
    name,
    value: data[name],
    onChange,
    fullWidth: true,
    displayValidationErrors,
    error: !!(displayValidationErrors && isInputInvalid(name)),
    helperText: displayValidationErrors && isInputInvalid(name) ? `Invalid ${name}` : '',
    sx: { mb: 2 },
  });
  return (
    <Box sx={{ p: 7 }} className="bt-message-box">
      {messageSent ? (
        <MessageSent />
      ) : (
        <>
          {props.label && (
            <Typography variant="h4">
              {props.label}
            </Typography>
          )}
          <Box mt={3}>
          <Form getInputProps={getInputProps} submit={submit} />
          </Box>
        </>
      )}
    </Box>
  );
};

export default MessageBox;
