import { Box, Rating, Stack, Typography } from '@mui/material';
import 'react-international-phone/style.css';
import Image from 'mui-image';
import noImage from '../assets/no-image-available.png';
import { isFunction, isString } from 'lodash';
import { amountOfNights, humanize } from '../../helpers/date-time';
import HTMLReactParser from 'html-react-parser';
import { COLOR } from '../../helpers/styles';

const HotelReservationDetails = ({ hotel, checkin, checkout, roomDetails, clientInfo, additionalInfo, bookingReference, sx }) => {
  const { client, hotelRequest, agent, guests } = clientInfo || {};

  const details = [
    ['Address', [hotel.address, hotel.cityName, hotel.provinceName, hotel.countryCode, hotel.postalCode].join(', ')],
    ...(hotel.phone ? [['Contact', `tel: ${hotel.phone}`]] : []),
    ...(bookingReference ? [['Booking Reference', bookingReference]] : []),
    ...(additionalInfo ? [['Hotel info', additionalInfo]] : []),
    ['Check-in', humanize({ dateStr: checkin, time: hotel.checkin })],
    ['Check-out', humanize({ dateStr: checkout, time: hotel.checkout })],
    ['Booking', `${amountOfNights(checkin, checkout)} nights, ${roomDetails.length} rooms`],
    ...(client ? [['On behalf of', `${client.fullName}, email: ${client.email}, tel: ${client.phone}`]] : []),
    ...roomDetails.flatMap((roomDetail) => [
      [roomDetail.name, roomDetail.description],
      ...roomDetail.policiesDetails.map((policyDetail) => [policyDetail.name, policyDetail.description]),
    ]),
    ...(guests
      ? [
          [
            'Hosts',
            <Box component="ul">
              {guests.map(({ title, firstName, lastName, age }, i) => (
                <Box key={i} component="li" ml={2}>
                  {`${title?.trim()?.length > 1 ? title + ', ' : ''}`}
                  {firstName} {lastName}
                  {`${age ? ', ' + age : ''}`}
                </Box>
              ))}
            </Box>,
          ],
        ]
      : []),
    ...(agent ? [['Agent', agent]] : []),
    ...(hotelRequest ? [['Peticion al hotel', hotelRequest]] : []),
  ];

  return (
    <Box sx={{ border: `1px solid ${COLOR.GRAY_LINE_SEPARATOR}`, ...sx }}>
      <Box>
        <Image
          sx={{ borderRadius: '4px' }}
          // width={bp.xs ? '200px' : '300px'}
          width="100%"
          height="250px"
          duration={0}
          src={hotel.images?.[0] || noImage}
          alt={hotel.hotelName}
        />
      </Box>

      <Box component="ul" sx={{ borderTop: `1px solid ${COLOR.GRAY_LINE_SEPARATOR}` }}>
        <Stack mb={2} p={2} sx={{ borderBottom: `1px solid ${COLOR.GRAY_LINE_SEPARATOR}` }}>
          <Typography variant="h5">{hotel.hotelName}</Typography>
          <Box>{hotel.category && <Rating name="read-only" value={hotel.category} readOnly precision={1} size="small" />}</Box>
        </Stack>

        {details.map(([key, value]) => (
          <Box key={key} component="li" sx={{ listStyle: 'none', p: 1.5, borderBottom: `1px solid ${COLOR.GRAY_LINE_SEPARATOR}` }}>
            <Stack direction="row" justifyContent="flex-start" alignItems="center">
              <Typography sx={{ flex: 1 }}>{HTMLReactParser(key)}</Typography>
              <Box sx={{ flex: 3, pl: 2 }}>
                {isString(value) ? <Typography sx={{ fontSize: '12.5px' }}>{value}</Typography> : isFunction(value) ? value() : value}
              </Box>
            </Stack>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default HotelReservationDetails;
