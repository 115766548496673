import { useEffect, useState } from 'react';
import { Box, Button, MenuItem, Paper, Stack, TextField, Typography } from '@mui/material';
import { times } from 'lodash';
import { v4 as id } from 'uuid';
import 'react-international-phone/style.css';

const titles = ['-', 'Sr.', 'Sra.', 'Miss', 'Mrs.'];
const ages = [undefined, ...times(50, (i) => `${i}`), '+50'];

const requiredString = (val) => ((val || '').trim().length === 0 ? 'Required' : null);


const newGuest = () => ({
  id: id(),
  title: titles[0],
  age: null,
  firstName: null,
  lastName: null,
  validations: {
    title: requiredString,
    age: requiredString,
    firstName: requiredString,
    lastName: requiredString,
  },
  errors: {
    // title: requiredString(null),
    age: requiredString(null),
    firstName: requiredString(null),
    lastName: requiredString(null),
  },
});

const Guests = (props) => {
  const minGuests = props.minGuests || 1;
  const [guests, setGuests] = useState(() => times(minGuests, () => newGuest()));

  useEffect(() => {
    props.onChange?.(guests);
    if (props.onValidationChange) {
      const hasError = !!guests.find((guest) => Object.values(guest.errors).find(Boolean));
      props.onValidationChange(hasError);
    }
  }, [guests]);

  const addGuest = () => setGuests((guests) => [...guests, newGuest()]);

  const setGuestAttr = (index) => (event) =>
    setGuests((guests) =>
      guests.map((guest, i) => {
        if (i !== index) {
          return guest;
        }

        const { name, value } = event.target;

        return {
          ...guest,
          errors: {
            ...guest.errors,
            [name]: guest.validations[name]?.(value),
          },
          [name]: value,
        };
      })
    );

  const removeGuest = (index) => setGuests((guests) => guests.filter((guest, i) => i !== index));

  return (
    <>
      {guests.map((guest, index) => {
        return (
          <Box key={guest.id} sx={{ px: 0, pt: 0, pb: 0, mb: 1 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} mb={1.5} pl={1}>
              <Typography variant="h6" fontSize={16}>
                Guest {guests.length > 1 && ` - ${index + 1}`}
              </Typography>
              {/* {guests.length > 1 && (
                <Button size="small" onClick={removeGuest.bind(null, index)}>
                  Remove guest
                </Button>
              )} */}
            </Stack>
            <Stack direction="row" alignItems="start" mb={1} pl={1} flexWrap={{xs: 'wrap', md: 'initial'}}>
              <TextField
                label="Title"
                size="small"
                select
                sx={{ minWidth: '100px', mb: 2, mr: 2 }}
                name="title"
                value={guest.title || ''}
                onChange={setGuestAttr(index)}
                error={!!(props.displayValidationErrors && guest.errors?.['title']) || false}
                helperText={(props.displayValidationErrors && guest.errors?.['title']) || null}
              >
                {!guest.title && <MenuItem key="" value={null}></MenuItem>}
                {titles.map((option) => (
                  <MenuItem key={`${option}`} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label="Age"
                size="small"
                select
                sx={{ minWidth: '100px', mb: 2, mr: 2 }}
                name="age"
                value={guest.age || ''}
                onChange={setGuestAttr(index)}
                error={!!(props.displayValidationErrors && guest.errors?.['age']) || false}
                helperText={(props.displayValidationErrors && guest.errors?.['age']) || null}
              >
                {!guest.age && <MenuItem key="" value={null}></MenuItem>}
                {ages.map((option, i) => (
                  <MenuItem key={`${option}`} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label="First name *"
                size="small"
                fullWidth
                sx={{ mb: 2, mr: { xs: 0 , md: 2 } }}
                name="firstName"
                value={guest.firstName || ''}
                onChange={setGuestAttr(index)}
                error={!!(props.displayValidationErrors && guest.errors?.['firstName']) || false}
                helperText={(props.displayValidationErrors && guest.errors?.['firstName']) || null}
              />
              <TextField
                label="Last name *"
                size="small"
                fullWidth
                sx={{ mb: 2, mr: 0 }}
                name="lastName"
                value={guest.lastName || ''}
                onChange={setGuestAttr(index)}
                error={!!(props.displayValidationErrors && guest.errors?.['lastName']) || false}
                helperText={(props.displayValidationErrors && guest.errors?.['lastName']) || null}
              />
            </Stack>
          </Box>
        );
      })}
      {/* <Button size="small" onClick={addGuest}>
        Add another guest
      </Button> */}
    </>
  );
};

export default Guests;
