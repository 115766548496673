import { Slider, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

const MinMaxSliderFilter = ({ filter }) => {
  const [slidingValue, setSlidingValue] = useState(filter.value);
  useEffect(() => {
    setSlidingValue(filter.value);
  }, [filter.value]);

  return (
    <>
      <Typography variant="div" color="text.primary">
        {filter.title}
      </Typography>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <Typography>{filter.minLabel}</Typography>
        <Typography>{filter.maxLabel}</Typography>
      </Stack>
      <Slider
        getAriaLabel={() => filter.title}
        valueLabelDisplay="auto"
        min={filter.min}
        max={filter.max}
        step={filter.step}
        marks={filter.marks}
        value={slidingValue}
        onChange={(_, newSLidingValue) => setSlidingValue(newSLidingValue)}
        onChangeCommitted={(_, newValue) => filter.onChange(newValue)}
      />
    </>
  );
};

export default MinMaxSliderFilter;
