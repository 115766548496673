import { activateCoupon, deactivateCoupon, deleteCoupon, getCoupons } from '../../api/admin';
import useDataLoader from '../../hooks/use-data-loader';
import { Box, Button, FormControlLabel, Link, Stack, Switch, Typography } from '@mui/material';
import MyDataGrid from '../../components/MyDataGrid/MyDataGrid';
import { Link as LinkRouter } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import ConfirmationDialog from '../../components/Dialogs/ConfirmationDialog';
import { toast } from 'react-toastify';

const columns = (setActiveCoupon) => [
  {
    field: 'code',
    headerName: 'Code',
    width: 200,
    renderCell: ({ row: item }) => {
      return (
        <Typography fontWeight="bold" fontSize="18">
          {item.code}
        </Typography>
      );
    },
  },
  {
    field: 'active',
    headerName: 'Active',
    width: 140,
    renderCell: (...args) => {
      const { row: item } = args[0];
      return (
        <Box mt={2} pl={2}>
          <FormControlLabel
            sx={{ mb: 3 }}
            control={
              <Box sx={{ background: 'white', borderRadius: '10px', p: 0, mt: '10px', mr: 1 }}>
                <Switch checked={item.active} onChange={(ev) => setActiveCoupon(item, ev.target.checked)} name="active" />
              </Box>
            }
            label={item.active ? 'Active' : 'Inactive'}
          />
        </Box>
      );
    },
  },
  {
    field: 'discountType',
    headerName: 'Discount Type',
    width: 120,
  },
  {
    field: 'discountAmount',
    headerName: 'Discount Amount',
    width: 120,
  },
  {
    field: 'orderIds',
    headerName: 'Used',
    width: 70,
    renderCell: ({ row: item }) => {
      return (
        <Typography fontWeight="bold" fontSize="18">
          {item.orderIds.length}
        </Typography>
      );
    },
  },
  {
    field: 'isPromotionCoupon',
    headerName: 'Promotion',
    width: 120,
  },
  {
    field: 'promotionText',
    headerName: 'Promotion Text',
    width: 120,
  },
  {
    field: 'filters',
    headerName: 'Filters',
    width: 300,
    renderCell: ({ row: item }) => {
      return (
        <Box>
          {item.filters.map((filter, i) => (
            <Box key={`${i}_${filter.type}_${filter.value}`}sx={{ mb: 1 }}>
              <Typography fontWeight="bold" component="span">
                - {filter.type}
              </Typography>
              : {['startAt', 'endAt'].includes(filter.type) ? 'date' + new Date(filter.value).toLocaleString() : filter.value}
            </Box>
          ))}
        </Box>
      );
    },
  },
];

const CouponsPage = () => {
  const { loaded, data, error } = useDataLoader(getCoupons);

  const openDialogRef = useRef(null);
  const closeDialogRef = useRef(null);

  const openDeleteDialogRef = useRef(null);
  const closeDeleteDialogRef = useRef(null);

  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [coupons, setCoupons] = useState([]);

  useEffect(() => {
    setCoupons(data?.coupons || []);
  }, [data?.coupons]);

  if (error) {
    return <Typography variant="h1">Failed to load the coupons</Typography>;
  }

  if (!loaded) {
    return;
  }

  const setActiveCoupon = (coupon, active) => {
    setSelectedCoupon(coupon);
    openDialogRef.current();
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>

        <Box sx={{ flex: 1 }}>
          <MyDataGrid
            autoHeight
            name="Coupons"
            columns={columns(setActiveCoupon)}
            rows={coupons}
            additionalToolbarOptions={<Link component={LinkRouter} to="/admin/coupons/new" underline="none">
            <Button>Create</Button>
          </Link>}
            renderExtraView={(coupon) => (
              <Button
                variant="outlined"
                onClick={() => {
                  setSelectedCoupon(coupon);
                  openDeleteDialogRef.current();
                }}
              >
                Delete
              </Button>
            )}
          />
        </Box>
      </Box>
      <ConfirmationDialog
        titleLabel={selectedCoupon ? `Do you want to ${selectedCoupon.active ? 'deactivate' : 'activate'} this coupon?` : ''}
        hideOpenDialogBtn
        hideCloseDialogBtn
        skipDialogContent
        onSetOpenDialog={(openDialog) => {
          openDialogRef.current = openDialog;
        }}
        onSetCloseDialog={(closeDialog) => {
          closeDialogRef.current = closeDialog;
        }}
        onSave={async () => {
          const couponId = selectedCoupon._id;
          try {
            await (selectedCoupon.active ? deactivateCoupon(couponId) : activateCoupon(couponId));
          } catch(error) {
            toast.error(`An unexpected error has occurred. Please reload the page. ${error.response?.data?.message || ''}`);
            return false;
          }

          setCoupons((currentCoupons) =>
            currentCoupons.map((coupon) => (coupon === selectedCoupon ? { ...coupon, active: !coupon.active } : coupon))
          );

          return true;
        }}
        onCloseDialog={() => {
          setSelectedCoupon(null);
          return true;
        }}
      />
      <ConfirmationDialog
        titleLabel={'Do you want to deleted this coupon?'}
        hideOpenDialogBtn
        hideCloseDialogBtn
        skipDialogContent
        onSetOpenDialog={(openDialog) => {
          openDeleteDialogRef.current = openDialog;
        }}
        onSetCloseDialog={(closeDialog) => {
          closeDeleteDialogRef.current = closeDialog;
        }}
        onSave={async () => {
          const couponId = selectedCoupon._id;
          try {
            await deleteCoupon(couponId);
          } catch(error) {
            toast.error(`An unexpected error has occurred. Please reload the page. ${error.response?.data?.message || ''}`);
            return false;
          }

          setCoupons((currentCoupons) => currentCoupons.filter((coupon) => coupon !== selectedCoupon));

          return true;
        }}
        onCloseDialog={() => {
          setSelectedCoupon(null);
          return true;
        }}
      />
    </>
  );
};

export default CouponsPage;
