import React, { useEffect, useRef, useState } from 'react';
import './App.css';

import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider } from '@mui/material';
import { createRoutesFromElements, createBrowserRouter, Route, RouterProvider, Outlet } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { defaultTheme } from './themes';
import ErrorPage from './pages/ErrorPage';
import authRouter from './pages/auth/AuthRouter';
import adminRouter from './pages/admin/AdminRouter';
import { AuthProvider } from './pages/auth/AuthProvider';
import AxiosInterceptor from './pages/auth/AxiosInterceptor';
import homeRouter from './pages/home/HomeRouter';
import ErrorBoundary from './pages/ErrorBoundary';
import NotFoundPage from './pages/NotFoundPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CookieConsent from './components/CookieConsent/CookieConsent';
import { AppContextProvider } from './AppContext';
import { LoadingProvider } from './contexts/LoadingContext/LoadingContext';
import Loading from './contexts/LoadingContext/Loading';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={
        <AuthProvider>
          <AxiosInterceptor>
            <Outlet />
          </AxiosInterceptor>
        </AuthProvider>
      }
      errorElement={<ErrorPage />}
    >
      {authRouter}
      {adminRouter}
      {homeRouter}
      <Route path="*" element={<NotFoundPage />} />
    </Route>
  )
);

function App() {
  const [renderConsent, setRenderConsent] = useState(false);

  useEffect(() => {
    setTimeout(() => setRenderConsent(true), 2000);
  }, []);

  return (
    <AppContextProvider>
      <StyledEngineProvider injectFirst>
        {renderConsent && <CookieConsent />}
        <ThemeProvider theme={defaultTheme}>
          <CssBaseline enableColorScheme />
          <ErrorBoundary>
            <LoadingProvider>
              <Loading />
              <RouterProvider router={router} />
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
              />
            </LoadingProvider>
          </ErrorBoundary>
        </ThemeProvider>
      </StyledEngineProvider>
    </AppContextProvider>
  );
}

export default App;
