import { Box, Typography } from '@mui/material';

const ErrorMsg = (props) => (
  <Box>
    <Typography component="h1" variant="h1">
      Oops!
    </Typography>
    <Typography>{props.msg || 'Sorry, an unexpected error has occurred.'}</Typography>
  </Box>
);

export default ErrorMsg;
