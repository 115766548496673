import { useState } from 'react';

import { withDataLoader } from '../../hooks/use-data-loader';
import { Box, Button, FormGroup, FormControlLabel, Switch } from '@mui/material';
import { clone, isEqual } from 'lodash';
import { getSettings, updateSettings } from '../../api/admin';

const SettingsPage = (props) => {

  const [settings, setSettings] = useState(clone(props.data.settings));
  const [originalSettings, setOriginalSettings] = useState(clone(props.data.settings));
  const [isSaving, setIsSaving] = useState(false);

  console.log(settings);

  const handleChange = (ev) => {
    console.log(ev.target.checked);
    setSettings((currentSettings) => ({ ...currentSettings, [ev.target.name]: ev.target.checked }));
  };

  const showActions = !isEqual(settings, originalSettings);
  const cancel = () => {
    setSettings(originalSettings);
  };
  const save = async () => {
    setIsSaving(true)
    await updateSettings(settings);
    const data = await getSettings();

    setSettings(clone(data.settings));
    setOriginalSettings(clone(data.settings));
    setIsSaving(false)
  };

  const switches = {
    allowNewBookings: 'Allow new bookings',
    allowNewBookingsOnlyInMoldova: 'Allow new bookings only in Moldova'
  };

  return (
    <Box mt={10} ml={10}>
      <FormGroup>
        {Object.entries(switches).map(([name, label]) => (
          <FormControlLabel key={name} control={<Switch name={name} checked={settings[name]} onChange={handleChange} />} label={label} />
        ))}
      </FormGroup>

      {showActions && (
        <Box mt={2} ml={2}>
          <Button size="small" variant="outlined" disabled={isSaving} sx={{ mr: 1 }} onClick={cancel}>
            Cancel
          </Button>
          <Button size="small" variant="contained" disabled={isSaving} onClick={save}>
            {isSaving ? 'Saving...' : 'Save'}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default withDataLoader(SettingsPage, getSettings);
