import { useState } from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import LocationInput from './LocationInput';
import DateRangePickerInput from './DateRangePickerInput';
import PaxInput from './PaxInput';
import { Box, Typography, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { COLOR } from '../../helpers/styles';
import clsx from 'clsx';
import useBreakpoints from '../../hooks/use-breakpoints';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 1,
    pl: '7px',
    // border: 'none',
    borderRadius: '10px',
    pl: '10px',
  },
  searchButtonContainer: {
    display: 'flex',
    justifyContent: 'end',
    flexDirection: 'row',
    // alignItems: 'end',
    paddingRight: '10px',
  },
  searchButton: {
    textTransform: 'capitalize',
    // height: '56px',
    whiteSpace: 'nowrap',
    // minWidth: '130px',
    // width: '100%',
    px: 0,
    fontWeight: 'bold',
  },
  itemContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    // flexGrow: 0.5,
  },
  columnSeparator3: {
    borderRight: `1px solid ${COLOR.GRAY_LINE_SEPARATOR}`,
    // flexGrow: '3 !important',
  },
  columnSeparator1_5: {
    borderRight: `1px solid ${COLOR.GRAY_LINE_SEPARATOR}`,
    // flexGrow: '1.5 !important',
  },
  itemBox: {
    padding: '10px',
    maxWidth: '500px',
    width: '100%',
    border: `1px solid ${COLOR.GRAY_LINE_SEPARATOR}`,
    borderRadius: '10px',
  },
}));

const SearchHotelsInputs = (props) => {
  const classes = useStyles();
  const { md } = useBreakpoints();
  const [location, setLocation] = useState(props.initialLocation);
  const [dates, setDates] = useState(props.initialDates);
  const [rooms, setRooms] = useState(props.initialRooms);
  const [displayValidationErrors, setDisplayValidationErrors] = useState(false);

  const search = () => {
    setDisplayValidationErrors(true);

    if ((props.omitLocation || location) && dates && rooms) {
      props.onSearch?.({ location, dates, rooms });
    }
  };

  const locationInput = props.omitLocation ? (
    <Typography fontSize="18px">Find another date:</Typography>
  ) : (
    <LocationInput
      initialValue={location}
      required
      displayValidationErrors={displayValidationErrors}
      onChange={(newLocation) => setLocation(newLocation)}
    />
  );
  const datesInput = (
    <DateRangePickerInput
      initialValue={dates}
      displayValidationErrors={displayValidationErrors}
      onChange={(newDates) => setDates(newDates)}
    />
  );
  const paxInput = (
    <PaxInput initialValue={rooms} displayValidationErrors={displayValidationErrors} onChange={(newRooms) => setRooms(newRooms)} />
  );
  const searchButton = (
    <Box className={classes.searchButtonContainer}>
      <Button
        variant="contained"
        endIcon={props.searching ? <CircularProgress size={20} /> : null}
        size="large"
        disabled={props.searching}
        className={classes.searchButton}
        onClick={search}
      >
        {props.searching ? 'Searching' : 'Search'}
      </Button>
    </Box>
  );

  return (
    <Paper className={classes.container} variant="outlined">
      {md ? (
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={1} sx={{ height: '50px' }}>
          {!props.omitLocation && (
            <Box className={clsx(classes.itemContainer, md && classes.columnSeparator3)} sx={{ pl: 1, flex: 1 }}>
              {locationInput}
            </Box>
          )}
          <Box className={clsx(classes.itemContainer, md && classes.columnSeparator1_5)} sx={{ pl: props.omitLocation && 1, flex: 1 }}>
            <Box maxWidth={200}>{datesInput}</Box>
          </Box>
          <Box className={clsx(classes.itemContainer)} sx={{flex:0.8}}>{paxInput}</Box>
          <Box className={clsx(classes.itemContainer)} sx={{flex:0.3}}>{searchButton}</Box>
        </Stack>
      ) : (
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={2} p={1}>
          {!props.omitLocation && <Box className={clsx(classes.itemContainer, classes.itemBox)}>{locationInput}</Box>}
          <Box className={clsx(classes.itemContainer, classes.itemBox)}>{datesInput}</Box>
          <Box className={clsx(classes.itemContainer, classes.itemBox)}>{paxInput}</Box>
          <Box className={clsx(classes.itemContainer)}>{searchButton}</Box>
        </Stack>
      )}
    </Paper>
  );
};

export default SearchHotelsInputs;
