import { useLoaderData } from 'react-router-dom';
import { Container } from '@mui/material';
import { fetchHotelInfo, searchHotelsAvailabilities } from '../../api/hotels';
import HotelAvailability from '../../components/Controllers/HotelAvailability';
import Navbar from './Navbar';
import { v4 as id } from 'uuid';
import useDataLoader from '../../hooks/use-data-loader';
import loadingGif from '../../components/assets/loading.gif';
import { Image } from 'mui-image';
import { Box, Typography } from '@mui/material';

const HotelAvailabilityPage = (props) => {
  const { loaded, error, data } = useDataLoader(HotelAvailabilityPage.dataLoader);
  const loading = !loaded;
  const { hotelAvailability, hotelInfo, checkin, checkout, paxes, urlParams, searchId } = data || {};

  return (
    <>
      <Navbar />
      <Container sx={{ pt: 0, px: '0px !important' }}>
        {error ? (
          <Box sx={{ margin: '0 auto', textAlign: 'center', my: 20 }}>
            <Typography variant="h2">An error has occurred!</Typography>
          </Box>
        ) : loading ? (
          <Box sx={{ margin: '0 auto', my: '150px', width: '400px', maxHeight: '100px' }}>
            <Image src={loadingGif} duration={0} width={'100%'} height={'100%'} />
          </Box>
        ) : (
          <HotelAvailability
            hotel={hotelInfo}
            availability={hotelAvailability}
            checkin={checkin}
            checkout={checkout}
            paxes={paxes}
            urlParams={urlParams}
            searchId={searchId}
          />
        )}
      </Container>
    </>
  );
};

HotelAvailabilityPage.linkPattern = 'availability/:hotelCodes/:destinationType/:destinationCode/:checkin/:checkout/:paxes';

HotelAvailabilityPage.link = (searchTerms, returnUrl = '') => {
  return (
    [
      '/availability',
      searchTerms.hotelCodes,
      searchTerms.destinationType,
      searchTerms.destinationCode,
      searchTerms.checkinDate,
      searchTerms.checkoutDate,
      searchTerms.rooms,
    ].join('/') + (returnUrl ? `?returnUrl=${encodeURIComponent(returnUrl)}` : '')
  );
};

// TODO: react-router v6 loader is executed before the rendering, so the AuthProvider is executed after. change it to use the useDataLoader
HotelAvailabilityPage.dataLoader = async (params) => {
  const searchHotelParams = {
    hotelCodes: params.hotelCodes,
    // destinationType: params.destinationType,
    // destinationCode: params.destinationCode,
    [params.destinationType]: params.destinationCode,
    checkinDate: params.checkin,
    checkoutDate: params.checkout,
    preferredLanguage: 'en', // todo
    rooms: params.paxes,
    shortResponse: false,
    cancellationFees: true,
  };
  const hotelAvailabilitiesPromise = searchHotelsAvailabilities({ params: searchHotelParams });
  const hotelInfoPromise = fetchHotelInfo({ hotelCode: params.hotelCodes });

  const [result, hotelInfo] = await Promise.all([hotelAvailabilitiesPromise, hotelInfoPromise]);

  return {
    searchHotelParams,
    hotelAvailability: addIdsToPaxRoomAndPkgs(result.hotelsResult[0]),
    searchId: id(),
    hotelInfo,
    checkin: params.checkin,
    checkout: params.checkout,
    paxes: params.paxes,
    urlParams: params,
  };
};

const addIdsToPaxRoomAndPkgs = (hotelAvailability) => {
  hotelAvailability.paxes.forEach(pax => {
    pax.id = id();

    pax.rooms.forEach(room => {
      room.id = id();

      room.packages.forEach(pkg => {
        pkg.id = id();
      })
    })
  })

  return hotelAvailability;
}

export default HotelAvailabilityPage;
