import { useState, useEffect, useRef } from 'react';
import sumBy from 'lodash/sumBy';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { v4 as id } from 'uuid';
import isNumber from 'lodash/isNumber';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import times from 'lodash/times';
import SingleBedIcon from '@mui/icons-material/SingleBed';
import Popover from '../Popover/Popover';
import PlaceholderForInput from './PlaceholderForInput';

const MAX_ROOMS = 4;
const minimum = {
  adults: 1,
  children: 0,
};

const maximum = {
  adults: 6,
  children: 4,
};

const paxInputStyles = makeStyles((theme) => ({
  actionItem: {
    textAlign: 'center',
  },
}));

const roomStyles = makeStyles((theme) => ({
  icons: {
    border: '1px solid',
    color: theme.palette.primary.dark,
    padding: '4px',
    margin: '0 10px',
  },
}));

const getNewRoom = ({ adults } = {}) => ({
  id: id(),
  adults: adults || 1,
  children: 0,
  ages: [],
});

const roomsSummary = (rooms) => {
  if (!rooms) {
    return '';
  }

  const adults = sumBy(rooms, 'adults');
  const children = sumBy(rooms, 'children');
  const guests = adults + children;
  const bedRoom = rooms.length;

  if (!guests) {
    return '';
  }

  return [`${guests} ${guests === 1 ? 'Guest' : 'Guests'}`, `${bedRoom} ${bedRoom === 1 ? 'Room' : 'Rooms'}`].join(', ');
};

const roomsErrors = (rooms) => {
  if (sumBy(rooms, 'adults') === 0) {
    return 'Select guests';
  }
  if (rooms.find((room) => room.ages.find((age) => !isNumber(age)))) {
    return 'Enter the ages for the children';
  }

  return '';
};

const addMissingPropsToRooms = (rooms) => {
  return rooms.map((room) => ({
    ...room,
    id: room.id || id(),
    ages: room.ages || [],
  }));
};

const Room = (props) => {
  const { number, room, onChange, onRemoveRoom, isRemovable, errorsVisible } = props;

  const classes = roomStyles();
  const anchorEl = useRef(null);

  const changeCount = (property, count) =>
    onChange({
      ...room,
      [property]: room[property] + count,
      ages:
        property === 'children' && count > 0
          ? [...room.ages, ' ']
          : property === 'children' && count < 0
          ? room.ages.slice(0, -1)
          : room.ages,
    });

  const updateAge = (index, event) =>
    onChange({
      ...room,
      ages: room.ages.map((age, i) => (i === index ? event.target.value : age)),
    });

  return (
    <Paper sx={{ p: 1, m: 1 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">Room {number} </Typography>
        {isRemovable ? <Button onClick={onRemoveRoom}>Remove Room</Button> : ''}
      </Stack>
      {['adults', 'children'].map((property) => (
        <Stack key={property} direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Box textTransform="capitalize">{property}</Box>
          <Box py={0.5}>
            <IconButton
              size="small"
              disabled={room[property] <= minimum[property]}
              onClick={changeCount.bind(null, property, -1)}
              className={classes.icons}
            >
              <RemoveIcon />
            </IconButton>
            {room[property]}
            <IconButton
              disabled={room[property] >= maximum[property]}
              onClick={changeCount.bind(null, property, +1)}
              className={classes.icons}
            >
              <AddIcon />
            </IconButton>
          </Box>
        </Stack>
      ))}
      <Stack direction="row" useFlexGap flexWrap="wrap">
        {room.ages.map((age, index) => (
          <FormControl
            key={`${room.id}-${index}`}
            required
            sx={{ m: 1, minWidth: 60 }}
            size="small"
            variant="standard"
            error={errorsVisible && !isNumber(age)}
          >
            <InputLabel id={`${index}`}>Age - {index + 1}</InputLabel>
            <Select labelId={`${index}`} value={age} label="Age" onChange={updateAge.bind(null, index)}>
              <MenuItem value={' '}>
                <Box sx={{ color: 'text.disabled', textAlign: 'center' }}>
                  <em>None</em>
                </Box>
              </MenuItem>
              {times(13, (n) => (
                <MenuItem value={n}>
                  <Typography align="center">{n}</Typography>
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>Required</FormHelperText>
          </FormControl>
        ))}
      </Stack>
    </Paper>
  );
};

const PaxInput = (props) => {
  const classes = paxInputStyles();

  const [rooms, setRooms] = useState(props.initialValue ? addMissingPropsToRooms(props.initialValue) : [getNewRoom({ adults: 2 })]);
  const [acceptButtonDisabled, setAcceptButtonDisabled] = useState(true);
  const [errorsVisible, setErrorsVisible] = useState(false);

  const errorText = roomsErrors(rooms);
  const hasErrors = !!errorText;
  const error = props.displayValidationErrors && hasErrors && errorText;

  useEffect(() => {
    setAcceptButtonDisabled(!rooms);
    props.onChange?.(hasErrors ? null : rooms);
  }, [rooms]);

  const updateRoom = (roomId, newRoom) => {
    setRooms((rooms) => rooms.map((room) => (room.id === roomId ? newRoom : room)));
  };

  const addRoom = () => setRooms((rooms) => [...rooms, getNewRoom()]);

  const removeRoom = (roomId) => setRooms((rooms) => rooms.filter((room) => room.id !== roomId));

  const renderTarget = ({ open }) => (
    <PlaceholderForInput label="Guests and rooms" icon={SingleBedIcon} value={roomsSummary(rooms)} error={error} onChange={open} />
  );

  const renderChildren = ({ close }) => (
    <Box sx={{ width: { xs: 320, sm: 400 }, p: 1 }}>
      {rooms.map((room, index) => (
        <Room
          key={room.id}
          number={index + 1}
          room={room}
          errorsVisible={errorsVisible}
          onChange={updateRoom.bind(null, room.id)}
          onRemoveRoom={removeRoom.bind(null, room.id)}
          isRemovable={rooms.length > 1}
        ></Room>
      ))}
      {rooms.length < MAX_ROOMS && <Button onClick={addRoom}>Add another Room</Button>}
      <Stack direction="row" spacing={2} justifyContent="center" alignItems="center" sx={{ py: 1.2 }}>
        <Button
          disabled={acceptButtonDisabled}
          className={classes.actionItem}
          variant={'contained'}
          onClick={() => {
            if (hasErrors) {
              setErrorsVisible(true);
              return;
            }
            close();
          }}
        >
          Done
        </Button>
      </Stack>
    </Box>
  );

  return <Popover fullWidth openOn="click" renderTarget={renderTarget} renderChildren={renderChildren} />;
};

export default PaxInput;
