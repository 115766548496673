import { Box, Container, Typography, Link } from '@mui/material';
import { Link as LinkRouter } from 'react-router-dom';

const NotFoundPage = () => {
  return (
    <Container sx={{mt: 5}}>
      <Box>
      <Typography component="h2" variant="h2">Nothing to see here!</Typography>
      <Link component={LinkRouter} to="/">Go to the home page</Link>
    </Box>
    </Container>
  );
};

export default NotFoundPage;
