import apiRequest from './api-request';

const LOCATION_TYPES = {
  1: 'country',
  2: 'province',
  3: 'city',
};

export const fetchLocations = async ({ searchText, language }) => {
  const { data } = await apiRequest.get('/locations', {
    params: {
      searchText,
      language,
    },
  });

  return data.map(parseLocation);
};

const parseLocation = (location) => {
  const [code, ...locs] = location;
  const len = locs.length;

  return {
    code,
    country: locs[len - 1],
    province: locs[len - 2],
    city: locs[len - 3],
    type: LOCATION_TYPES[len],
  };
};
