import { createFees, deleteFees, getFees, updateFees } from '../../api/admin';
import useDataLoader from '../../hooks/use-data-loader';
import { Box, Button, Typography, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import MyDataGrid from '../../components/MyDataGrid/MyDataGrid';
import { useEffect, useRef, useState } from 'react';
import ConfirmationDialog from '../../components/Dialogs/ConfirmationDialog';
import { toast } from 'react-toastify';

const columns = [
  {
    field: 'feeType',
    headerName: 'Type',
    width: 120,
  },
  {
    field: 'chargeType',
    headerName: 'Unit',
    width: 120,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    width: 120,
  },
  {
    field: 'text',
    headerName: 'Description',
    width: 230,
  },
];

const FeesPage = () => {
  const { loaded, data, error } = useDataLoader(getFees);

  const openDeleteDialogRef = useRef(null);
  const closeDeleteDialogRef = useRef(null);

  const openCreateEditDialogRef = useRef(null);
  const closeCreateEditDialogRef = useRef(null);

  const [fee, setFee] = useState({});
  const [fees, setFees] = useState([]);
  const [isCreatingFee, setIsCreatingFee] = useState(true);

  useEffect(() => {
    setFees(data?.fees || []);
  }, [data?.fees]);

  const getNewFee = () => ({
    text: '',
    feeType: 'Markup',
    chargeType: 'percentage',
    amount: 0,
  });

  const createNewFee = async () => {};

  const updateFeeProp = (ev) => setFee((currentFee) => ({ ...currentFee, [ev.target.name]: ev.target.value }));

  if (error) {
    return <Typography variant="h1">Failed to load the fees</Typography>;
  }

  if (!loaded) {
    return;
  }

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box sx={{ flex: 1 }}>
          <MyDataGrid
            autoHeight
            name="Fees"
            columns={columns}
            rows={fees}
            additionalToolbarOptions={
              <Button
                onClick={() => {
                  setFee(getNewFee());
                  setIsCreatingFee(true);
                  openCreateEditDialogRef.current();
                }}
              >
                Create
              </Button>
            }
            renderExtraView={(fee) => (
              <>
                <Button
                  variant="outlined"
                  sx={{ mr: 2 }}
                  onClick={() => {
                    setFee(fee);
                    openDeleteDialogRef.current();
                  }}
                >
                  Delete
                </Button>

                <Button
                  variant="outlined"
                  onClick={() => {
                    setFee(fee);
                    setIsCreatingFee(false);
                    openCreateEditDialogRef.current();
                  }}
                >
                  Edit
                </Button>
              </>
              // <CreateDialog />
            )}
          />
        </Box>
      </Box>

      <ConfirmationDialog
        titleLabel={'Do you want to deleted the fee?'}
        hideOpenDialogBtn
        hideCloseDialogBtn
        // skipDialogContent
        onSetOpenDialog={(openDialog) => {
          openDeleteDialogRef.current = openDialog;
        }}
        onSetCloseDialog={(closeDialog) => {
          closeDeleteDialogRef.current = closeDialog;
        }}
        onSave={async () => {
          const couponId = fee._id;
          try {
            await deleteFees(couponId);
          } catch (error) {
            toast.error(`Error. ${error?.response?.data?.message}`);
            return false;
          }

          const data = await getFees();
          setFees(data.fees);

          return true;
        }}
        onCloseDialog={() => {
          setFee(null);
          return true;
        }}
      >
        {fee && (
          <>
            <Typography>
              Text: <b>{fee.text}</b>
            </Typography>
            <Typography>
              Fee type: <b>{fee.feeType}</b>
            </Typography>
            <Typography>
              Charge type: <b>{fee.chargeType}</b>
            </Typography>
            <Typography>
              Amount: <b>{fee.amount}</b>
            </Typography>
          </>
        )}
      </ConfirmationDialog>

      <ConfirmationDialog
        titleLabel={isCreatingFee ? 'Create a new Fee' : 'Edit fee'}
        hideOpenDialogBtn
        hideCloseDialogBtn
        preventToClickWithClickOutside
        okLabel={isCreatingFee ? 'Create' : 'Save'}
        onSetOpenDialog={(openDialog) => {
          openCreateEditDialogRef.current = openDialog;
        }}
        onSetCloseDialog={(closeDialog) => {
          closeCreateEditDialogRef.current = closeDialog;
        }}
        onCloseDialog={() => {
          setFee(null);
          return true;
        }}
        onSave={async () => {
          try {
            if (isCreatingFee) {
              await createFees(fee);
            } else {
              await updateFees(fee._id, fee);
            }
          } catch (error) {
            toast.error(`Error. ${error?.response?.data?.message}`);
            return false;
          }

          const data = await getFees();
          setFees(data.fees);

          return true;
        }}
      >
        {fee && (
          <>
            <Box component="form" maxWidth={'800px'} onSubmit={createNewFee}>
              <Box mb={3}>
                <TextField label="Text*" name="text" fullWidth value={fee.text} onChange={updateFeeProp} />
              </Box>

              <Box mb={3}>
                <FormControl fullWidth>
                  <InputLabel>Fee type*</InputLabel>
                  <Select value={fee.feeType} label="Fee type*" name="feeType" onChange={updateFeeProp}>
                    <MenuItem value="Markup">Markup</MenuItem>
                    <MenuItem value="Expense">Expense</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Box mb={3}>
                <FormControl fullWidth>
                  <InputLabel>Charge type*</InputLabel>
                  <Select value={fee.chargeType} label="Charge type*" name="chargeType" onChange={updateFeeProp}>
                    <MenuItem value="percentage">Percentage</MenuItem>
                    <MenuItem value="fixed">Fixed</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Box mb={3}>
                <TextField label="Amount*" name="amount" type="number" fullWidth value={fee.amount} onChange={updateFeeProp} />
              </Box>
            </Box>
          </>
        )}
      </ConfirmationDialog>
    </>
  );
};

export default FeesPage;
