import React from 'react';

import { Navigate, Outlet, Route } from 'react-router-dom';

import NotFoundPage from '../NotFoundPage';
import ErrorPage from '../ErrorPage';

import AdminLayout from './AdminLayout';
import DashboardPage from './DashboardPage';
import ProtectedRoute from '../auth/ProtectedRoute';
import OrdersPage from './OrdersPage';
import MessagesPage from './MessagesPage';
import CouponsPage from './CouponsPage';
import NewCouponPage from './NewCouponPage';
import FeesPage from './FeesPage';
import SettingsPage from './SettingsPage';
import BannersPage from './BannersPage';
import ReferralsPage from './ReferralsPage';
import NewReferralPage from './NewReferralPage';
import OrderReferralsPage from './OrderReferralsPage';
import LogsPage from './LogsPage';

const adminRouter = (
  <Route
    path="/admin"
    element={
      <ProtectedRoute>
        <AdminLayout>
          <Outlet />
        </AdminLayout>
      </ProtectedRoute>
    }
    errorElement={<ErrorPage />}
  >
    <Route index element={<Navigate to="dashboard" />} errorElement={<ErrorPage />} />
    <Route path="dashboard" element={<DashboardPage />} errorElement={<ErrorPage />} />
    <Route path="orders" element={<OrdersPage />} errorElement={<ErrorPage />} />
    <Route path="messages" element={<MessagesPage />} errorElement={<ErrorPage />} />
    <Route path="coupons" element={<CouponsPage />} errorElement={<ErrorPage />} />
    <Route path="coupons/new" element={<NewCouponPage />} errorElement={<ErrorPage />} />
    <Route path="fees" element={<FeesPage />} errorElement={<ErrorPage />} />
    <Route path="banners" element={<BannersPage />} errorElement={<ErrorPage />} />
    <Route path="referrals" element={<ReferralsPage />} errorElement={<ErrorPage />} />
    <Route path="referrals/new" element={<NewReferralPage />} errorElement={<ErrorPage />} />
    <Route path="referrals/edit/:referralId" element={<NewReferralPage />} errorElement={<ErrorPage />} />
    <Route path="order-referrals" element={<OrderReferralsPage />} errorElement={<ErrorPage />} />
    <Route path="settings" element={<SettingsPage />} errorElement={<ErrorPage />} />
    <Route path="logs" element={<LogsPage />} errorElement={<ErrorPage />} />
    <Route path="*" element={<NotFoundPage />} />
  </Route>
);

export default adminRouter;
