import { Box, Container, Grid, Typography } from '@mui/material';
import mission1 from '../../components/assets/mission1.jpeg';
import mission2 from '../../components/assets/mission2.jpeg';
import Image from 'mui-image';
import Flipper from '../../components/Flipper/Flipper';
import member1 from '../../components/assets/team/member1.png';
import member2 from '../../components/assets/team/member2.jpeg';
import member3 from '../../components/assets/team/member3.jpeg';
import member4 from '../../components/assets/team/member4.jpeg';
import Navbar from './Navbar';

const flipperCards = [
  [
    '+50',
    'Vacation Packages',
    "If you're looking at a family resort or a vacation package our consultants will guide you with their in-depth knowledge of places.",
  ],
  [
    '+20K',
    'Hotels',
    "Partnered with leading Hotel Chains and Rental Properties we offer one of the best Hotel's" +
      ' rates online. Just check our prices. You will be surprised',
  ],
  [
    '+300',
    'Tours Destinations',
    'We have special tours destinations and packages ready for you. Tailored just for our clients, we create routes and special trips',
  ],
  [
    '+40K',
    'Reservations',
    'We are continuously developing our system, our skills and travel knowledge to provide the best customer service.',
  ],
];

const team = [
  {
    name: 'Kevin Palacios',
    title: 'Manager /CEO',
    image: member1,
  },
  {
    name: 'Josue Garcia',
    title: 'Marketing Director/ Travel Agent',
    image: member2,
  },
  {
    name: 'Amanda Hernandez',
    title: 'Account Manager/ Travel Advisor',
    image: member3,
  },
  {
    name: 'Dania Marin',
    title: 'Sales Manager',
    image: member4,
  },
];

const AboutPage = () => {
  return (
    <>
    <Navbar withNavbar />
      <Container sx={{ pt: 10, maxWidth: '1200px' }}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Box heigth="400px">
              <Image src={mission1} width="100%" height="100%" duration={0} />
            </Box>
            <Typography variant="h5" color="primary.main" mt={4} mb={1}>
              Our Story
            </Typography>
            <Typography>
              Bellitours, established in 2018, takes pride in being a locally owned and operated company that caters to individual travel
              agents. We provide them with an opportunity to join our network, sell travel, and become valuable members of our dedicated
              team. While our primary focus is serving clients in the USA, we also extend our services to customers across Europe. We
              recognize that the travel industry is undergoing rapid transformations, and we attribute our success to the unwavering support
              we receive from our community, including you. In order to remain engaged with the US Southwest community, we actively
              participate in activities and events dedicated to enhancing the quality of life in our region. In response to the challenges
              posed by the pandemic in 2021, we made our system accessible and bookable online, allowing us to establish an online travel
              agency (OTA) that offers comprehensive travel booking services throughout the USA. This step was crucial in adapting to the
              changing landscape and meeting the needs of our customers in a convenient and accessible manner.
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box heigth="400px">
              <Image src={mission2} width="100%" height="100%" duration={0} />
            </Box>
            <Typography variant="h5" color="primary.main" mt={4} mb={1}>
              Our Mission
            </Typography>
            <Typography>
              Our utmost goal is to meticulously plan and craft the finest travel experience for you and your family, ensuring that we
              uncover the most exceptional travel options available. Additionally, we are committed to ensuring that every individual
              relishes their vacation with seamless ease. Hence, if you have any preferences or specific travel destinations in mind, or if
              you are commemorating a special occasion, kindly inform us! Whether you are an intrepid solo traveler, an adventurous couple,
              or a convivial group of family, friends, or coworkers, Bellitours is passionately dedicated to empowering you to explore the
              world and embrace boundless possibilities!
            </Typography>
          </Grid>
        </Grid>

        <Grid container mt={5} mb={4}>
          {flipperCards.map(([title, text, desc]) => (
            <Grid item xs={12} sm={6} md={3} mb={2}>
              <Box width="100%">
                <Flipper
                  sx={{ width: '200px', height: '130px', textAlign: 'center', cursor: 'pointer', margin: '0 auto' }}
                  front={
                    <Box sx={{ border: '6px solid', borderColor: 'primary.main', p: 3, height: '100%' }}>
                      <Typography variant="h4">{title}</Typography>
                      <Typography>{text}</Typography>
                    </Box>
                  }
                  back={
                    <Box sx={{ backgroundColor: 'primary.main', color: 'white', p: 1, height: '100%' }}>
                      <Typography fontSize={14}>{desc}</Typography>
                    </Box>
                  }
                />
              </Box>
            </Grid>
          ))}
        </Grid>

        <Box mt={9}>
          <Typography maxWidth={'700px'} sx={{ margin: '0 auto', textAlign: 'center' }}>
            "Kevin embarked on his journey in the travel industry nearly twenty-five years ago. His professional trajectory commenced with a
            fortuitous encounter with the esteemed Italian worldwide vacation club,{' '}
            <Typography component="span" fontWeight="bold">
              I Viaggi del Ventaglio
            </Typography>
            . From humble beginnings as a travel guide and tourism entertainer, Kevin swiftly ascended the ranks, assuming pivotal roles
            such as team leader, trip management specialist, and group tourist developer within a remarkably short span of time. His
            exceptional ability to tackle challenges head-on, coupled with his innate talent for fostering strong connections with his
            clients, propelled him to stand out in his field. Above all, it was his unwavering passion for travel that ignited a spark
            within him, fuelling his dedication to this captivating industry."
          </Typography>
        </Box>

        {/* <Grid container mt={10} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" textAlign="center" mb={1}>
              Meet Our Team
            </Typography>
          </Grid>
          {team.map((member) => (
            <Grid item xs={12} sm={6} md={3} mt={3} textAlign="center">
              <Box
                sx={{
                  margin: '0 auto',
                  width: '200px',
                  height: '200px',
                  overflow: 'hidden',
                  borderRadius: '50%',
                  border: '2px solid',
                  borderColor: 'primary.main',
                  cursor: 'pointer',
                  '&:hover': { opacity: 0.78 },
                }}
              >
                <Image src={member.image} />
              </Box>
              <Typography fontWeight="bold" mt={2} mb={1}>
                {member.name}
              </Typography>
              <Typography color="text.secondary">{member.title}</Typography>
            </Grid>
          ))}
        </Grid> */}

        <Box mb={7} />
      </Container>
    </>
  );
};

export default AboutPage;
