import { Box, Typography } from '@mui/material';
import Navbar from './Navbar';

const PrivacyPage = () => (
  <>
    <Navbar withNavbar />
    <Box maxWidth="1200px" margin="0 auto" p={4} pt={6}>
      <h1 id="privacy-cookie-policy">Privacy &amp; Cookie Policy</h1>
      <p>
        Your privacy is important to us. We value your trust and are committed to protecting and safeguarding any personal information you
        give us. This document, which we update from time to time, describes how we use and process your personal data and how we use
        cookies. It also tells you how you can contact us if you have questions about your personal information.
      </p>
      <p>
        Bellitours provides online travel services through its own websites and mobile apps and through other online platforms such as
        partners' websites and social media. The information that follows applies to all of these platforms. This privacy policy applies to
        data collected on <strong>bellitours.com</strong>.
      </p>
      <Box component="ul" sx={{ ml: 3 }}>
        <li>What information we collect from you</li>
        <li>How we use your information</li>
        <li>With whom we share your information</li>
        <li>How you can access your information</li>
        <li>Your choices with respect to the collection and use of your information</li>
        <li>Cookies and other Web technologies</li>
        <li>Display of tailored advertising/Your choices</li>
        <li>How we protect your information</li>
        <li>External links</li>
        <li>Changes to this Privacy Policy</li>
        <li>How you can contact us</li>
      </Box>
      <p>
        <strong>What information we collect from you</strong>
        <br />
        In General we receive and store any information you enter on our website or give us in any other way. This includes information that
        can identify you (&quot;personal information&quot;), including your first and last name, telephone number, postal and email
        addresses, fax number, and, if you make a reservation through our site, credit card information (such as credit card number,
        cardholder name, and expiration date). We also may request information about your hotel room preferences, loyal- ty, frequent flyer
        and/or car rental program information. You can choose not to provide information to us, but in general some information about you is
        required in order for you to register as a member; purchase products or services; complete a traveler pro- file; participate in a
        survey, competition or promotion; ask us a question; or initiate other transactions on our site.
      </p>
      <p>
        <strong>Travel Companion Information</strong>
        <br />
        When you make a reservation for someone else through bellitours.com, we will request personal information and travel prefer- ences
        about that individual. You should obtain the consent of other individuals prior to providing us with their personal infor- mation
        and travel preferences, as any access to view or change their information will be available only through your account.
      </p>
      <p>
        <strong>Information from Other Sources</strong>
        <br />
        We also may periodically obtain both personal and non-personal information about you from affiliated entities, business partners and
        other independent third-party sources and add it to our account information. Examples of information we may receive include: updated
        delivery and address information, purchase history, and demographic information.
      </p>
      <p>
        <strong>Call Monitoring</strong>
        <br />
        Calls to and from our customer service help desk may be recorded or monitored for the purpose of quality control and for staff
        training. Call recordings will be maintained as long as reasonably necessary and will then be deleted. Any personal information ob-
        tained from you during the call will be treated in accordance with the provisions of this Privacy Policy.
      </p>

      <strong>What personal data we process</strong>
      <br />
      <p>2.1. Common personal data</p>
      <p>
        For the purposes indicated in this Information, the Data Controller processes common personal data such as personal data (name,
        surname, tax code, address, telephone number, e-mail and other contact details).
      </p>
      <p>2.2. Personal data of family members or third parties</p>
      <p>
        Finally, to follow up on the requests you have made from time to time through the Site, the Data Controller may need to acquire per-
        sonal data of his family members and / or third parties, including minor subjects.
      </p>
      <p>
        In this sense, you undertake to inform your family members of these treatments, including by providing them with this information.
      </p>
      <p>2.3. Source of personal data.</p>
      <p>
        Your personal data processed by the Data Controller are those provided by you directly by filling in the various forms on the Site.
      </p>
      <strong>
        Purpose and legal basis of the processing, nature of the provision and consequences of a refusal to provide personal data
      </strong>
      <br />
      <p>3.1 Request for quotation and fulfillment of legal obligations</p>
      <p>
        Your personal data will be processed by BELLITOURS for activities strictly connected and instrumental to the generation of the esti-
        mate required for the trip you have selected and any booking and purchase of the same, as well as for the fulfillment of obligations
        under laws, regulations and / or Community regulations, or by supervisory and control bodies or by other legitimate authorities.
      </p>
      <p>
        The provision of your personal data is mandatory for this purpose. Your refusal will, in fact, make it impossible for the Data
        Controller to follow up on your request.
      </p>
      <p>
        For the treatments necessary for the pursuit of these purposes, it will not be necessary to obtain your consent because the legal
        basis of the treatment consists, pursuant to current legislation, in the satisfaction of a request made by you and in the
        fulfillment of a legal obligation.
      </p>
      <p>
        In any case, your personal data will be processed until the end of the trip and, after that date, will be kept exclusively for the
        period of time necessary to comply with current legislation (including the provisions of statute of limitations).
      </p>
      <p>3.2. Registration on the site</p>
      <p>
        Your personal data may also be processed by BELLITOURS to satisfy your request to register on the Site and to be able to provide you
        with the related services.
      </p>
      <p>
        Also in this case, the treatment does not require your prior consent as the legal basis of the treatment consists, in accordance
        with current legislation, in the satisfaction of the request made by you. The provision of your personal data for the purpose
        referred to in this Chapter is mandatory and your refusal will make it impossible for BELLITOURS to follow up on your request to
        register on the Site and to use the related services.
      </p>
      <p>
        The data collected for this purpose by the Data Controller will be kept for the period in which you decide to remain registered on
        the Site.
      </p>
      <p>
        3.3. <strong>Request for services through the Site</strong>
      </p>
      <p>
        Furthermore, your personal data may be processed by BELLITOURS to satisfy further requests made by you from time to time by filling
        in the appropriate forms available on the Site (for example, requests for information).
      </p>
      <p>
        The provision of data is mandatory and your refusal will make it impossible for the Data Controller to follow up on the requests you
        have made from time to time.
      </p>
      <p>
        The treatment does not require your consent as the legal basis consists in the satisfaction of the owner of a request made by you.
      </p>
      <p>
        The data collected for this purpose by the Data Controller may be processed for the time strictly necessary to satisfy the request
        made by you and, in any case, will be deleted 6 months after the request has been processed.
      </p>
      <p>
        <strong>How we use your information</strong>
        <br />
        We use credit card information (such as cardholder name, credit card number, and expiration date) for the purpose of completing the
        travel bookings you conduct on our site. We use other information about you for the following general purposes: to provide you with
        the products and services you request; to provide you with travel confirmation and updates; to manage your account, including
        processing bills and providing travel notifications; to communicate with you in general; to respond to your questions and comments;
        to allow us to contact you for customer service, if necessary; to measure interest in and improve our products, ser- vices, and
        website; to notify you by email or post about special offers and travel-related products and services that may be of in- terest to
        you; to otherwise customize your experience with bellitours.com; to reward you as part of any reward and recognition program you
        choose to join; to solicit information from you, including through surveys; to resolve disputes, collect fees, or trouble- shoot
        problems; to prevent potentially prohibited or illegal activities; to enforce our Terms of Use; and as otherwise described to you at
        the point of collection.
      </p>
      <p>
        <strong>Email Communications</strong>
        <br />
        We want to make it easy for you to take advantage of travel-related opportunities on our website. One way we do this is by send- ing
        you email messages that contain information about your travel-related interests. For example, if you search for a flight to Flori-
        da on our website and have saved your itinerary or otherwise not yet booked your travel, we may send you an email message re-
        minding you about your saved travel itinerary or about a special airfare to Florida. Similarly, if you receive an email from us
        about Florida travel and demonstrate an interest in Florida hotels by affirmatively clicking on a link about Florida hotels in the
        email, you may receive an email about Florida hotel offers or other destination information. We believe these email messages will
        provide you with useful information about travel-related special offers available through our sites. Please note that you will have
        the op- portunity to choose not to receive these email messages in any such email we send.
      </p>
      <p>
        <strong>With whom we share your information</strong>
        <br />
        Bellitours.com may share your information with the following entities:
      </p>
      <Box component="ul" sx={{ ml: 3 }}>
        <li>
          Suppliers, such as hotel, airline, car rental, and activity providers, who fulfill your travel reservations. Throughout
          bellitours.com, all services provided by a third-party supplier are described as such. We encourage you to review the privacy
          policies of any third- party travel supplier whose products you purchase through bellitours.com. Please note that these suppliers
          also may contact you as necessary to obtain additional information about you, facilitate your travel reservation, or respond to a
          review you may submit.
        </li>
        <li>
          Third-party vendors who provide services or functions on our behalf, including credit card processing, business analytics, custom-
          er service, marketing, distribution of surveys or sweepstakes programs, and fraud prevention. We may also authorize third-party
          vendors to collect information on our behalf, including as necessary to operate features of our website or to facilitate the
          delivery of online advertising tailored to your interests. Third-party vendors have access to and may collect information only as
          needed to perform their functions and are not permitted to share or use the information for any other purpose.
        </li>
        <li>
          Business partners with whom we may jointly offer products or services. You can tell when a third party is involved in a product or
          service you have requested because their name will appear with ours. If you choose to access these optional services, we may share
          information about you, including your personal information, with those partners so that they can contact you directly by email or
          post about their products and services. Please note that we do not control the privacy practices of these third-party busi- ness
          partners.
        </li>
        <li>
          Referring websites. If you were referred to bellitours.com from another website, we may share some information about you with that
          referring website so that they can contact you directly by email or post about their products and services. We have not placed
          limitations on the referring websites’ use of your personal information and we encourage you to review the privacy policies of any
          website that referred you to bellitours.com
        </li>
        <li>
          Companies within our corporate family. We may share your personal information with our corporate affiliates in the leisure and
          travel sector, including bedsonline.com, TripAdvisor LLC; , Amadeus, Hertz, among others. This sharing enables us to provide you
          with information about products and services, both leisure- and travel-related, which might interest you. To the extent that our
          corporate affiliates have access to your information, they will follow practices that are at least as restrictive as the practices
          de- scribed in this Privacy Policy.
        </li>
      </Box>
      <p>
        <strong>We also may share your information:</strong>
      </p>
      <Box component="ul" sx={{ ml: 3 }}>
        <li>
          When we believe it is appropriate to investigate, prevent, or take action regarding illegal or suspected illegal activities; to
          protect and defend the rights, property, or safety of Bellitours, the website or users of the website; and in connection with our
          Terms of Use and other agreements.
        </li>
        <li>
          In connection with a corporate transaction, such as a sale of a subsidiary or a division, merger, consolidation, or asset sale, or
          in the unlikely event of bankruptcy. We also may share aggregate or anonymous information with third parties, including
          advertisers and investors. For example, we may tell our advertisers the number of visitors our websites receive or the most
          popular hotels and holiday destinations. This infor- mation does not contain any personal information and is used to develop
          content and services we hope you will find of interest and to audit the efficacy of our advertising.
        </li>
      </Box>
      <p>Your choices with respect to collection and use of your information</p>
      <Box component="ul" sx={{ ml: 3 }}>
        <li>
          As discussed above, you can choose not to provide us with any information, although it may be needed to make travel bookings or to
          take advantage of certain features offered on bellitours.com.
        </li>
        <li>
          When you accept to join the bellitours.com newsletter, you will be given a choice as to whether you want to receive email mes-
          sages from us about Travel special offers as well as email messages about products and services of selected third parties.
        </li>
        <li>
          When you subscribe the bellitours.com newsletter, you also will be given the opportunity to unsubscribe from messages in any such
          email we send. Please note that we reserve the right to send you other communications, including service announcements,
          administrative messages, and surveys relating either to your transactions on Bellitours.com, without offering you the opportunity
          to opt out of receiving them.
        </li>
      </Box>
      <p>
        We automatically collect some information about you and your computer when you visit bellitours.com. For example, we will col- lect
        your IP address, Web browser software (such as Firefox, Internet Explorer or Safari), and referring website. We also may collect
        information about your online activity, such as trips viewed and purchases made. One of our goals in collecting this automatic in-
        formation is to help customize your user experience. In addition to the above cookies, we use Local Shared Objects, also referred to
        as ”flash cookies,” on our website. These are used to enhance your user experience, for example, by storing your user preferences
        and settings, such as your volume/mute settings, and in connection with animated content on our website. Local Shared Objects are
        similar to browser cookies, but can store data more complex than simple text. By themselves, they cannot do anything to or with the
        data on your computer. Like other cookies, they can only access personally identifiable information that you have provided on this
        site, and cannot be accessed by other web- sites. To find out more about flash cookies or how to disable them, please click here.
      </p>
      <p>
        If you have any questions about our use of cookies or other technologies, please email us at our customer care :
        admin@bellitours.com
      </p>
      <p>
        <strong>Display of tailored advertising/Your choices</strong>
        <br />
        Data collected by business partners and ad networks to serve you with relevant advertising. The advertisements you see on this
        website are served by us or by our service providers. But we also allow third parties to collect information about your online.
        These third parties include: (1) other Bellitours companies; (2) Business partners, who collect information when you view or
        interact with one of their advertisements on our sites; and (3) Advertising networks, which collect information about your interests
        when you view or interact with one of the advertisements they place on many different websites on the Internet. The information
        gathered by these third parties is used to make predictions about your characteristics, interests or preferences and to display
        advertise- ments on our sites and across the Internet tailored to your apparent interests. We do not permit these third parties to
        collect per- sonal information about you (such as email address) on our site, nor do we share with them any personal information
        about you.
      </p>
      <p>
        Please note that we do not have access to or control over cookies or other technologies these third parties may use to collect in-
        formation about your interests, and the information practices of these third parties are not covered by this Privacy Policy. Some of
        these companies are members of the Network Advertising Initiative, which offers a single location to opt out of ad targeting from
        member companies. To learn more, please click here and here.
      </p>
      <p>
        Data collected by companies that operate cookie-based exchanges to serve you with relevant advertising. Like other companies
        operating online, this site participates in cookie-based exchanges where anonymous information is collected about your browsing
        behavior through cookies and other technologies and segmented into different topics of interest (such as travel). These topics of
        interest are then shared with third parties, including advertisers and ad networks, so they can tailor advertisements to your appar-
        ent interests. We do not share personal information (such as your email address) with these companies and we do not permit these
        companies to collect any such information about you on our site. Please click here to learn more about cookie-based ex- changes,
        including how to access information about the topics of interest associated with cookies on your computer and how to decline
        participation in these programs.
      </p>
      <p>
        <strong>How we protect your information</strong>
        <br />
        We want you to feel confident about using bellitours.com to make hotel and travel bookings, and we are committed to protecting the
        information we collect. While no website can guarantee security, we have implemented appropriate administrative, technical, and
        physical security procedures to help protect the personal information you provide to us. For example, only authorized employ- ees
        are permitted to access personal information, and they only may do so for permitted business functions. In addition, we use
        encryption when transmitting personal information that is sensitive to you (e.g., credit card details) between your system and ours,
        and we employ firewalls and intrusion detection systems to help prevent unauthorized persons from gaining access to your infor-
        mation.
      </p>
      <p>
        <strong>External links</strong>
        <br />
        If any part of the bellitours.com website links you to other websites, those websites do not operate under this Privacy Policy. We
        recommend you examine the privacy statements posted on those other websites to understand their procedures for collecting, using,
        and disclosing personal information.
      </p>
      <p>
        <strong>Changes to this Privacy Policy</strong>
        <br />
        We may update this Privacy Policy in the future. We will notify you about material changes to this Privacy Policy by sending a no-
        tice to the email address you provided to us or by placing a prominent notice on our website.
      </p>
      <p>
        <strong>How you can contact us</strong>
        <br />
        If you have questions about this Privacy Policy (or your travel planning or purchases), please contact us at: admin@bellitours.com
        Call: (305) 244-3651 from 8am - 7pm Monday to Friday. In addition, you may directly contact the travel supplier at the phone number
        or address provided on your travel itinerary.
      </p>
      <p>Write: You may also write to Bellitours, office: 15420 SW 136th ST UNIT 3 , Miami, FL,US, 33196</p>
    </Box>
  </>
);

export default PrivacyPage;
