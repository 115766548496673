import React, {  } from 'react';
import { Box, Button, Typography, Link, Slide } from '@mui/material';
import Dialog from '../Dialogs/Dialog';

const consentKey = 'cookieConsent';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CookieConsent = () => {
  const accepted = localStorage.getItem(consentKey) === 'true';

  const acceptCookieConsent = () => {
    localStorage.setItem(consentKey, 'true');
  };

  if (accepted) {
    return null;
  }

  return (
    <Dialog
      startOpen
      withTitle={false}
      preventToClickWithClickOutside
      dialogProps={{
        fullWidth: true,
        maxWidth: 'lg',
        TransitionComponent: Transition,
      }}
      renderContent={({ closeDialog }) => (
        <Box sx={{ backgroundColor: 'rgb(238, 239, 243)', margin: -2 }}>
          <Box width={'90%'} sx={{ display: { md: 'flex' }, py: 4, px: 2 }}>
            <Typography fontSize={13}>
              This website uses cookies to enhance user experience and to analyze performance and traffic on our website. We also share
              information about your use if our site with our social media, adversising and analytics partners.{' '}
              <Link href="/privacy">Cookie Policy</Link>
            </Typography>

            <Button
              sx={{ minWidth: '180px', ml: 4, maxHeight: '80px' }}
              variant="contained"
              onClick={() => {
                acceptCookieConsent();
                closeDialog();
              }}
              color="error"
            >
              Accept Cookies
            </Button>
          </Box>
        </Box>
      )}
    />
  );
};

export default CookieConsent;
