import { activateCoupon, deactivateCoupon, deleteCoupon, deleteReferral, getCoupons, getReferrals } from '../../api/admin';
import useDataLoader from '../../hooks/use-data-loader';
import { Box, Button, FormControlLabel, Link, Stack, Switch, Typography, List, ListItem } from '@mui/material';
import MyDataGrid from '../../components/MyDataGrid/MyDataGrid';
import { Link as LinkRouter, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import ConfirmationDialog from '../../components/Dialogs/ConfirmationDialog';
import { toast } from 'react-toastify';
import PrettyJson from '../../components/PrettyJson';

const website = process.env.REACT_APP_BELLITOURS_WEBSITE

const renderValuesByCurrencies = (valuesByCurrencies) => {
  const entries = Object.entries(valuesByCurrencies);
  return entries.length ? (
    <List>
      {entries.map(([currency, value]) => (
        <ListItem key={currency}>{currency}: {value}</ListItem>
      ))}
    </List>
  ) : (
    '0'
  );
}

const renderCellNumber =
  (prop) =>
  ({ row: item }) => {
    return (
      <Box display="inline-block" pl={1.5}>
        {item[prop]}
      </Box>
    );
  };

const columns = () => [
  {
    field: 'name',
    headerName: 'Name',
    width: 120,
  },
  {
    field: 'referralCode',
    headerName: 'Referral',
    width: 270,
    renderCell: ({ row: referral }) => {
      return (
        <Box>
          <Box>
            {website}?referral=<b>{referral.referralCode}</b>
          </Box>
          <Box position="absolute" sx={{mt: '-5px'}}>
            <Button size="small" sx={{mr: 4}}
              onClick={(ev) => {
                ev.stopPropagation();
                navigator.clipboard.writeText(`${website}?referral=${referral.referralCode}`);
              }}
            >
              Copy Link
            </Button>

            <Button  size="small"
              onClick={(ev) => {
                ev.stopPropagation();
                navigator.clipboard.writeText(referral.referralCode);
              }}
            >
              Copy Referral Code
            </Button>
          </Box>
        </Box>
      );
    },
  },
  {
    field: 'durationInDays',
    headerName: 'Duration',
    width: 90,
    renderCell: ({ row: referral }) => {
      return (
        <Typography fontWeight="bold" fontSize="18">
          {referral.durationInDays} days
        </Typography>
      );
    },
  },
  {
    field: 'profitType',
    headerName: 'Profit',
    width: 80,
    renderCell: ({ row: referral }) => {
      return (
        <Typography fontWeight="bold" fontSize="18">
          {referral.profitType === 'Percentage' ? '%' : 'USD '} {referral.profitAmount}
        </Typography>
      );
    },
  },
  {
    field: 'totalOfOrders',
    headerName: 'Orders',
    width: 80,
    renderCell: renderCellNumber('totalOfOrders'),
  },
  {
    field: 'paidOrders',
    headerName: 'Payed',
    width: 80,
    renderCell: renderCellNumber('paidOrders'),
  },
  {
    field: 'pendingOrders',
    headerName: 'Pending',
    width: 80,
    renderCell: renderCellNumber('pendingOrders'),
  },
  {
    field: 'cancelledOrders',
    headerName: 'Cancelled',
    width: 80,
    renderCell: renderCellNumber('cancelledOrders'),
  },
  {
    field: 'totalPayedByCurrency',
    headerName: 'Total Payed',
    width: 120,
    renderCell: ({ row: referral }) => renderValuesByCurrencies(referral.totalPayedByCurrency),
  },
  {
    field: 'pendingToPaidByCurrency',
    headerName: 'Pending to Paid',
    width: 120,
    renderCell: ({ row: referral }) => renderValuesByCurrencies(referral.pendingToPaidByCurrency),
  },
];

const mapReferralsToColumns = (referrals) =>
  referrals.map((referral) => {
    const totalOfOrders = referral.orders.length;

    let paidOrders = 0;
    let pendingOrders = 0;
    let cancelledOrders = 0;

    let totalPayedByCurrency = {};
    let pendingToPaidByCurrency = {};

    referral.orders.forEach((order) => {
      if (order.paid) {
        paidOrders += 1;

        totalPayedByCurrency[order.currency] ||= 0;
        totalPayedByCurrency[order.currency] += order.amountToPay;
      }

      if (order.cancelled) {
        cancelledOrders += 1;
      }

      if (!order.paid && !order.cancelled) {
        pendingOrders += 1;

        pendingToPaidByCurrency[order.currency] ||= 0;
        pendingToPaidByCurrency[order.currency] += order.amountToPay;
      }
    });

    console.log('here');

    return {
      ...referral,
      totalOfOrders,
      paidOrders,
      pendingOrders,
      cancelledOrders,
      totalPayedByCurrency,
      pendingToPaidByCurrency,
    };
  });

const ReferralsPage = () => {
  const navigate = useNavigate();
  const { loaded, data, error } = useDataLoader(getReferrals);
  const [referrals, setReferrals] = useState([]);

  useEffect(() => {
    setReferrals(mapReferralsToColumns(data?.referrals || []));
  }, [data?.referrals]);

  if (error) {
    return <Typography variant="h1">Failed to load the referrals</Typography>;
  }

  if (!loaded) {
    return;
  }

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box sx={{ flex: 1 }}>
          <MyDataGrid
            autoHeight
            name="Referrals"
            columns={columns()}
            rows={referrals}
            additionalToolbarOptions={
              <Link component={LinkRouter} to="/admin/referrals/new" underline="none">
                <Button>Create</Button>
              </Link>
            }
            onEdit={(referral) => navigate(`/admin/referrals/edit/${referral._id}`)}
            onDelete={async (referral) => {
              await deleteReferral(referral._id);
              setReferrals(mapReferralsToColumns(data?.referrals || []).filter(item => item._id !== referral._id));
            }}
          />
        </Box>
      </Box>

    </>
  );
};

export default ReferralsPage;
