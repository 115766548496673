import { Box, Grid, Paper, Stack, Typography } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import PublicIcon from '@mui/icons-material/Public';
import MessageBox from '../MessageBox/MessageBox';

const address = process.env.REACT_APP_BELLITOURS_ADDRESS
const phoneNumber = process.env.REACT_APP_BELLITOURS_PHONE
const adminEmail = process.env.REACT_APP_BELLITOURS_ADMIN_EMAIL
const website = process.env.REACT_APP_BELLITOURS_WEBSITE

// TODO: update this info
const ContactDetails = () => {
  const stackProps = {
    direction: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    spacing: 2,
    mb: 4,
  };

  const iconProps = {
    sx: {
      backgroundColor: 'info.main',
      width: '50px',
      padding: '10px',
      height: '50px',
      borderRadius: '50%',
      opacity: '.80',
    },
  };
  return (
    <>
      <Stack {...stackProps}>
        <LocationOnIcon {...iconProps} />
        <Typography variant="">
          <Typography fontWeight="bold">Address:</Typography>
          <Typography>{address}</Typography>
        </Typography>
      </Stack>

      <Stack {...stackProps}>
        <PhoneIcon {...iconProps} />
        <Typography variant="">
          <Typography fontWeight="bold">Phone:</Typography>
          <Typography>{phoneNumber}</Typography>
        </Typography>
      </Stack>

      <Stack {...stackProps}>
        <EmailIcon {...iconProps} />
        <Typography variant="">
          <Typography fontWeight="bold">Email:</Typography>
          <Typography>{adminEmail}</Typography>
        </Typography>
      </Stack>

      <Stack {...stackProps}>
        <PublicIcon {...iconProps} />
        <Typography variant="">
          <Typography fontWeight="bold">Website:</Typography>
          <Typography>{website}</Typography>
        </Typography>
      </Stack>
    </>
  );
};

const Contact = () => {
  return (
    <Paper elevation={3}>
      <Grid container mb={10}>
        <Grid item xs={12} md={8}>
          <MessageBox label="Get in touch"/>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box height="100%" sx={{ backgroundColor: 'primary.main', color: 'white', px: 3, py: 7 }}>
            <Typography variant="h4" mb={4}>
              Contact
            </Typography>
            <ContactDetails />
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Contact;
