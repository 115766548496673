export const COLOR = {
  GRAY_LINE_SEPARATOR: '#dfe0e4',
  GRAY_LINE_SEPARATOR_2: '#bfbfc1',
  GRAY_ITEM_OVER: '#dfe0e482',
  PRIMARY_ITEM_SELECTED: '#1976d21f',
  PRIMARY_ITEM_OVER: '#1f67af0a',
  BACKGROUND: '#f3f3f5',
  LIGHT_BACKGROUND: '#f3f3f5c9',
}

export const CENTERED = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
}
