import {
  getReferrals,
  updateOrderReferralPaid,
} from '../../api/admin';
import useDataLoader from '../../hooks/use-data-loader';
import { Box, Button, Typography } from '@mui/material';
import MyDataGrid from '../../components/MyDataGrid/MyDataGrid';
import { useEffect, useRef, useState } from 'react';
import ConfirmationDialog from '../../components/Dialogs/ConfirmationDialog';
import { toast } from 'react-toastify';
import { formatToDate } from '../../helpers/date-time';

const columns = (setPaidOrder) => [
  {
    field: 'referralName',
    headerName: 'Referred by',
    width: 120,
  },

  {
    field: 'orderId',
    headerName: 'Order Id',
    width: 200,
  },
  {
    field: 'checkin',
    headerName: 'Checkin',
    width: 120,
    renderCell: ({ row: orderReferral }) => {
      return <Box>{formatToDate(new Date(orderReferral.checkin))}</Box>;
    },
  },
  {
    field: 'price',
    headerName: 'Price',
    width: 80,
    renderCell: ({ row: orderReferral }) => {
      return (
        <Box>
          {orderReferral.currency} {orderReferral.price}
        </Box>
      );
    },
  },
  {
    field: 'profitType',
    headerName: 'Profit',
    width: 80,
    renderCell: ({ row: orderReferral }) => {
      return (
        <Typography fontWeight="bold" fontSize="18">
          {orderReferral.profitType === 'Percentage' ? '%' : 'USD '} {orderReferral.profitAmount}
        </Typography>
      );
    },
  },
  {
    field: 'amountToPay',
    headerName: 'Amount to Pay',
    width: 100,
    renderCell: ({ row: orderReferral }) => {
      return (
        <Box>
          {orderReferral.profitType === 'Percentage' ? orderReferral.currency : 'USD '} {orderReferral.amountToPay}
        </Box>
      );
    },
  },
  {
    field: 'cancelled',
    headerName: 'Status',
    width: 140,
    renderCell: ({ row: orderReferral }) => {
      return <Box>{orderReferral.cancelled ? 'Cancelled' : orderReferral.paid ? 'Payed' : 'Pending to pay'}</Box>;
    },
  },
  {
    field: 'paid',
    headerName: 'Actions',
    width: 140,
    renderCell: ({ row: orderReferral }) => {
      return (
        <Box>
          <Button disabled={orderReferral.cancelled && !orderReferral.paid} onClick={(ev) => {
            ev.stopPropagation();
            setPaidOrder(orderReferral);
          }}>
            {orderReferral.paid ? 'Unpaid' : 'Paid'}
          </Button>
        </Box>
      );
    },
  },
];

const mapReferralsToColumns = (referrals) =>
  referrals
    .map((referral) =>
      referral.orders.map((orderReferral) => ({
        _id: orderReferral.orderId,
        referralId: referral._id,
        referralName: referral.name,
        ...orderReferral,
      }))
    )
    .flat()
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

const OrderReferralsPage = () => {
  const { loaded, data, error } = useDataLoader(getReferrals);
  const [orderReferrals, setOrderReferrals] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const openDialogRef = useRef(null);
  const closeDialogRef = useRef(null);

  useEffect(() => {
    setOrderReferrals(mapReferralsToColumns(data?.referrals || []));
  }, [data?.referrals]);

  const setActiveOrder = (orderReferral) => {
    setSelectedOrder(orderReferral);
    openDialogRef.current();
  };

  if (error) {
    return <Typography variant="h1">Failed to load the order referrals</Typography>;
  }

  if (!loaded) {
    return;
  }

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box sx={{ flex: 1 }}>
          <MyDataGrid
            autoHeight
            name="Order Referrals"
            columns={columns(setActiveOrder)}
            rows={orderReferrals}
            // skipView
          />
        </Box>
      </Box>

      <ConfirmationDialog
        titleLabel={selectedOrder ? `Do you want to update this order referral as ${selectedOrder.paid ? 'unpaid' : 'paid'}` : ''}
        hideOpenDialogBtn
        hideCloseDialogBtn
        skipDialogContent
        onSetOpenDialog={(openDialog) => {
          openDialogRef.current = openDialog;
        }}
        onSetCloseDialog={(closeDialog) => {
          closeDialogRef.current = closeDialog;
        }}
        onSave={async () => {
          try {
            await updateOrderReferralPaid(selectedOrder.referralId, selectedOrder.orderId, !selectedOrder.paid);
          } catch (error) {
            toast.error(`An unexpected error has occurred. Please reload the page. ${error.response?.data?.message || ''}`);
            return false;
          }

          setOrderReferrals((current) =>
            current.map((orderReferral) =>
              orderReferral.orderId === selectedOrder.orderId
                ? {
                    ...orderReferral,
                    paid: !selectedOrder.paid
                  }
                : orderReferral
            )
          );

          return true;
        }}
        onCloseDialog={() => {
          setSelectedOrder(null);
          return true;
        }}
      />
    </>
  );
};

export default OrderReferralsPage;
