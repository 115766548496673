import { Box, Typography } from '@mui/material';
import Navbar from './Navbar';

const TermsPage = () => (
  <>
    <Navbar withNavbar />
    <Box maxWidth="1200px" margin="0 auto" p={4} pt={6}>
      <h2 id="important-information-regarding-your-booking">IMPORTANT INFORMATION REGARDING YOUR BOOKING</h2>
      <p>
        <strong>You must not accept any booking unless you understand and agree with the following terms and conditions.</strong>
      </p>
      <Box component="ul" sx={{ ml: 3 }}>
        <li>All Terms and Conditions to be performed in the name of Bellitours LLC (hereinafter referred to as &quot;Bellitours&quot;)</li>
      </Box>
      <p>
        <strong>Baggage and Fees</strong>
        <br />
        Additional fees for baggage may apply. Please contact your airline or refer to its website for detailed information regarding their
        checked baggage policies, or alternatively please click here for more information.
      </p>
      <p>
        <strong>Travel Agent</strong>
        <br />
        Bellitours or any of its affiliated entities acts as a wholesale agent or direct Travel Service Provider. We sell various travel
        related products on behalf of numerous transport service providers, including, but not limited to hotel, tours, airlines and rail.
        Bellitours does not own, operate, manage or control these independent suppliers of services and is not liable for their acts or
        omissions. Bel- litours obligations to you are to make travel bookings on your behalf for your client’s and to arrange relevant
        contracts between you, your clients and the travel service providers. We have no responsibility for these services, nor do we have
        the authority to make any warranty or representation regarding their standard. A request cannot be guaranteed. All bookings are
        subject to the terms and conditions and limitations of liability imposed by these travel service providers. You understand that you
        and your cli- ent’s legal recourse are against the specific provider not Bellitours
      </p>
      <p>
        <strong>Deposits and Final Payment</strong>
        <br />
        Bellitours will accept the following credit cards: VISA, MASTERCARD, AMERICAN EXPRESS and DISCOVER as valid forms of payment for
        bookings made through this website. Bellitours reserves the right to modify this policy based on booking agency status and prior
        credit approval.
      </p>
      <p>
        All reservations require a non-refundable $100 per person deposit. There will be a $35.00 charge for returned checks. If the book-
        ing is for a vacation more than 45 days before departure, payment can be made in full at time of booking, or (if applicable) an
        initial deposit can be made and the remaining balance paid no less than 45 days before departure. The initial deposit will be
        charged to a credit card at time of booking or may be payable by check. AIR: If the booking includes air travel, such airfare is
        subject to the poli- cies of the issuing Airline and may be non-refundable once ticketed. Subject to the Airline policies and to the
        extent permitted by law, you will have 24 hours from the time of your original booking to cancel your reservation without being
        charged a cancellation if the travel was booked 7 days or more prior to the departure date. Thereafter, any amendment or any changes
        to a reservation once booked and paid or deposited constitutes a cancellation and any ticket exchanges, changes, reissues and
        cancellations may require additional funds. In addition to retaining the non-refundable $150 per person deposit, cancelled bookings
        may incur charg- es of up to 100% of the cost of the booking depending on the Airline rules. In the event the airfare is cancelled,
        the airfare may be exchanged for another trip, under the same passenger names, so long as the trip is completed within 365 days of
        the original ticket issue date.
      </p>
      <p>
        <strong>Credit Card Transactions</strong>
        <br />
        If for any reason, any travel service provider is unable to provide the services for which you or your client have contracted, you
        or your client’s remedy lies against the provider, and not against Bellitours and in the event that payment has been made to
        Bellitours by credit card, you agree that you will not seek to charge back your payment to Bellitours or that of your client’s. If
        Bellitours in- curs any costs, including but not limited to attorneys’ fees, to recover any payments charged back by you or your
        client’s credit card company, you agree that you will be liable for these costs. If the credit card is declined, you guarantee that
        you will settle any amounts owing Bellitours via money order or cash immediately.
      </p>
      <p>
        <strong>Other Conditions</strong>
        <br />
        Prices are subject to increase prior to the time you or your client makes full payment. Prices are not subject to increase after you
        or your client makes full payment, except for charges resulting from increases in government-imposed taxes or fees. By accepting
        these terms and conditions, you expressly acknowledge your acceptance of these conditions (i.e. increases before full payment and
        increases attributable to government-imposed taxes or fees after full payment will be your responsibility) applicable to your cli-
        ent’s purchase. You agree that you will be responsible for obtaining your client’s written consent to any increases to prices in ac-
        cordance with this section and you agree to indemnify and hold harmless Bellitours against any claims arising out of such a failure
        to obtain written consent.
      </p>
      <p>
        <strong>Cancellation Policy</strong>
        <br />
        It is a condition of this booking that you specifically communicate in writing to your client, Bellitours refund and/or cancellation
        policy of this booking, including without limitation the refund and/or cancellation policy of the third party suppliers. To the
        extent permitted by law, any bookings cancelled will be subject to a:
      </p>
      <p>1) $100 per person fee paid to Bellitours</p>
      <p>2) forfeiture of any deposit paid to Bellitours;</p>
      <p>
        3) Any additional fees or penalties required by third party-suppliers; and any additional fees or penalties required by third party-
        suppliers;
      </p>
      <p>
        4) Any non-refundable portion payable to third party suppliers such as airlines whether paid in advance by your client or if pay-
        ment in full is due prior to departure (collectively “Cancellation Fees”). Your failure to pay or obtain payment from your client
        for any or all portion of the Cancellation Fees may result in Bellitours withholding, setting off or deducting such portion of the
        Cancellation Fees which are outstanding from future commissions due to you for this booking and any other bookings you have with
        Bellitours until the Cancellation Fees owing is received. If your client has trip protection insurance, any Cancellation Fees must
        be paid to Bellitours. Your client must submit an insurance claim for the Cancellation Fees. Agency indemnifies Belli- tours against
        all losses, damages, claims and costs (including reasonable attorney fees) which Bellitours may sustain or incur directly from or
        concerning:
      </p>
      <p>
        5) Any failure by Agency to obtain the Agency’s Client written acknowledgement of the refund and/or cancellation policy of this
        booking as set out above in this paragraph
      </p>
      <p>
        6) Any failure by the Agency’s Client to pay the full amount owed for this booking, including without limitation Cancellation Fees;
        and/or (c) any chargebacks or bounced checks by the Agency or the Agency’s Client relating to this booking or Cancellation Fees.
      </p>
      <p>
        Any failure by the Agency’s Client to pay the full amount owed for this booking, including without limitation Cancellation Fees;
        and/ or (c) any chargebacks or bounced checks by the Agency or the Agency’s Client relating to this booking or Cancellation Fees.
      </p>
      <p>
        <strong>Hazardous Material</strong>
        <br />
        Federal law forbids the carriage of certain hazardous materials, such as aerosols, fireworks, and flammable liquids, aboard the air-
        craft. If your do not understand these restrictions, contact your airline or click here
      </p>
      <p>
        <strong>Modifications or Revisions</strong>
        <br />A $30 non-refundable commissionable fee will be charged by Bellitours for any changes or revisions made after initial deposit
        in addition to any fees imposed by third party suppliers for the change or revisions.
      </p>
      <p>
        <strong>Not Included Items</strong>
        <br />
        Your client may incur addition expenses whilst travelling that are not included in the package price, including but not limited to
        the following:
      </p>
      <Box component="ul" sx={{ ml: 3 }}>
        <li>
          Tipping and porterage: our package price does not include porterage at airport and hotels; tips to local representatives,
          sightsee- ing guides, restaurant employees, hotel bellmen and chambermaids; wines, liquors, laundry, or any other items of a
          personal na- ture as well as any items not specifically mentioned within the itinerary. Tipping is left to your client’s
          discretion.
        </li>
        <li>
          Car, Moped or Scooter Rental: Car rental rates for packages that include a car do not include gasoline, taxes, optional insurance,
          CDW, underage and additional driver charges or airport charges. These charges are payable to the car rental company directly.
        </li>
        <li>
          Local taxes and service charges: Local room tax, energy surcharges and service charges on room and meals are not included un- less
          specified. Taxes and service charges are based on the hotel’s daily room tariff and not on the land package price. Local govern-
          ments may impose additional taxes including but not limited to departure taxes which may not be included in the package price.
        </li>
      </Box>
      <p>
        <strong>Travel Protection</strong>
        <br />
        Travel Protection plans are recommended to help cover your investment, offset expenses from travel mishaps and provide you with
        emergency travel assistance. For your protection, Bellitours will offer and quote an insurance plan with your booking.
      </p>
      <p>
        Bellitours is not an insurance company and has no responsibility for the representation, description of coverage, submission, bind-
        ing coverage, payment or adjustment of any insurance claims. Any insurance claims that may fall under the relevant travel insur-
        ance policy must be submitted to the insurance company identified in the policy.
      </p>
      <p>
        Refunds for Unused Services Unused hotel nights are refundable only to the extent that we receive a refund from the hotels, after
        cancellation charges are lev- ied by the hotel, if any. Unused transfers and/or sightseeing tours included in the basic tours will
        only be refunded to the extent that we receive a refund from the supplier. To get a prompt refund for unused services, clients must
        submit to their travel agent written evidence from the hotel or other operator that services were unused and are refundable. A copy
        of the checkout bill will help to expedite the processing of any refund due, if applicable. If no proof is furnished, a refund may
        be made pending an investi- gation, which may take up to three months. TO BE ACCEPTED, ALL REFUND CLAIMS MUST BE PRESENTED NO LATER
        THAN 30 DAYS AFTER THE TRIP HAS ENDED. All refund claims must be submitted in writing to: Bellitours 14135 sw 147th ct, Miami, FL,
        33196 Attn: Refunds. Bellitours cannot accept faxed refund requests. No refund or adjustment will be made for any changes,
        cancellations or modifications of services provided in this itinerary, if such changes are made by the tour participant during the
        tour. Travel agent’s commission is not protected on any cancelled or refunded bookings.
      </p>
      <p>
        <strong>Responsibilities</strong>
        <br />
        We always do our best to make sure your travel arrangements are satisfactory. However, Bellitours and its employees, sharehold- ers,
        subsidiaries, affiliates, officers, directors, successors, agents, and assigns do not own or operate any entity which provides goods
        and services for your travel including without limitation, lodging facilities, airline, vessel, motor coach, or other transporta-
        tion companies, guides or guide services, local ground operators, providers or organizers of optional excursions, food service pro-
        viders, etc. All such persons and entities are independent contractors and are in no way affiliated any of Bellitours affiliated
        enti- ties.
      </p>
      <p>
        <strong>Liability</strong>
        <br />
        Bellitours and its employees, affiliates, officers, directors, successors, agents, and assigns do not own or operate any entity
        which provides goods and services for your travel including without limitation, lodging facilities, airline, vessel, motor coach, or
        other transportation companies, guides or guide services, local ground operators, providers or organizers of optional excursions,
        food service providers, etc. All such persons and entities are independent contractors and are in no way affiliated to Bellitours.
        Belli- tours” is not liable for any negligent or willful act or failure to act of any travel service provider or of any third party.
        2Fix Corp, DBA ”Bellitours is not responsible for any injury, loss, death, inconvenience, delay, or damage to person or property in
        connection with the provision of any goods or services whether resulting from, but not limited to, acts of God or force majeure,
        illness, disease, acts of war or civil unrest, insurrection or revolt, animals, strikes or other labor activities, criminal or
        terrorist activities of any kind, overbooking or downgrading of accommodations, mechanical or other failure of airplanes or other
        means of transportation, or for any failure of any transportation mechanism to arrive or depart on time. If due to weather, flight
        schedules or other uncontrollable factors you are required to spend additional night(s), you will be responsible for your own hotel,
        transfers and meal costs. Baggage is entirely at owner’s risk. You may see the name ”Bellitours” affixed to motor vehicles, on signs
        around the hotel or elsewhere. This use of our name is purely for reasons of identification and does not denote ownership,
        supervision, or control by Bellitours in any way. You specifically agree that Bellitours is not liable for any consequential loss or
        damage.
      </p>
      <p>
        <strong>Passports and Documents</strong>
        <br />
        All individuals departing from the United States of America must be in possession of a valid passport. It is your responsibility to
        ensure that you have valid travel documentation, including but not limited to passports and visas, which meet the requirements of
        immigration and other government authorities at every destination. You are responsible for confirming with the United States De-
        partment of State or representative government agency of the country to which you are traveling to confirm the requirements for
        visas and/or other requirements for admission to your destination. Any fines, penalties, payments or expenditures incurred as a
        result of such documents not meeting the requirements of those authorities will be your sole responsibility. A government issued ID
        is required for travel within the USA.
      </p>
      <p>
        Travel documents including, without limitation, airline tickets, hotel vouchers, tour vouchers or any other document (whether in
        electronic form or otherwise) used to confirm an arrangement with a service provider. Travel documents may be subject to certain
        conditions and/or restrictions such as, but not limited to, being non refundable, non-date-changeable, and subject to cancellation
        and/or amendment fees. Travel documents cannot be transferred to another person to use. All airline tickets must be issued in the
        name of the passport/photo identity holder. An incorrect name on a booking may result in an inability to use that booking and the
        booking being cancelled. Any errors in names, dates and timings may result in an inability to use that booking and the booking be-
        ing cancelled. Any errors in names, dates and timings on your documentation will be your responsibility if not advised at the time
        of booking.
      </p>
      <p>
        <strong>Flight Confirmation</strong>
        <br />
        We strongly suggest that clients reconfirm exact flight times with the carrier prior to departure. Air travelers are required to
        check in at least 2 hours prior to departure time for domestic flights and 3 hours for international flights and must report to the
        gat at least 30 minutes prior to departure time. Failure to comply may result in the loss of the seat to another passenger.
      </p>
      <p>
        <strong>Health and Special Requirements</strong>
        <br />
        It is your responsibility to ensure you are aware of any health requirements for your travel destinations and to ensure that you
        carry all necessary vaccination documentation. Please advise your consultant of any special requirements you may have, for exam-
        ple, special meals, medical requirements and so on.
      </p>
      <p>
        International Flights Some countries require insecticide spraying of aircraft prior to a flight or while you are on the aircraft.
        Federal law requires that we refer you to the Department of Transport disinfection website. Click Here
      </p>
      <p>
        <strong>Photographs &amp; Maps</strong>
        <br />
        Room photographs are shown for informational purposes only and may not represent the actual room category purchased. Maps
        represented on the site are shown for informational purposes only and display only approximate locations and attractions and are not
        intended to be exact in every detail.
      </p>
      <p>
        <strong>Refusal of Service</strong>
        <br />
        Service providers reserve the right to refuse service to travelers at their sole discretion and including without limitation if the
        trav- eler:
      </p>
      <Box component="ul" sx={{ ml: 3 }}>
        <li>Lacks proper documentation for the country of destination;</li>
        <li>Has a contagious disease;</li>
        <li>Is under the influence of alcohol, drugs or narcotics; and/or</li>
        <li>
          Manifests disruptive and/or unruly behavior. Bellitours assumes no liability for the acts of the service provider in refusing
          service.
        </li>
      </Box>
      <p>
        <strong>Privacy Policy</strong>
        <br />
        Bellitours is committed to protecting the privacy and confidentiality of your personal information. Our Privacy Policy is available
        for viewing here
      </p>
      <p>
        <strong>Jurisdiction</strong>
        <br />
        All disputes and matters arising under or in connection with, these terms and conditions shall be submitted for trial without jury
        in the Federal Court of Florida. These terms and conditions shall be construed in accordance with the laws of the State of Florida.
      </p>
      <p>Bellitours. 15420 SW 136th ST Unit 3, Miami, FL,</p>
    </Box>
  </>
);

export default TermsPage;
