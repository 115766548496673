import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ErrorMsg from '../components/ErrorMsg';

const useDataLoader = (getData) => {
  const params = useParams();
  const [state, setState] = useState({ loaded: false });

  useEffect(() => {
    const loadData = async () => {
      let data, error;

      try {
        data = await getData(params);
      } catch (err) {
        error = err;
      }

      setState({ loaded: true, data, error });
    };

    loadData();
  }, [getData, params]);

  return state;
};

export const withDataLoader = (WrappedCmp, dataLoader) => (props) => {
  const { loaded, data, error } = useDataLoader(dataLoader);

  if (!loaded) {
    return null;
  }

  if (error) {
    return <ErrorMsg />;
  }

  return <WrappedCmp {...props} data={data} />;
};

export default useDataLoader;
