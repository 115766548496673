import { createReferral, getReferral, updateReferral } from '../../api/admin';
import {
  Box,
  Button,
  InputAdornment,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { randomReferralCode } from '../../helpers/utils';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

const errorRequired = (value) => (!value || value.trim().length === 0 ? 'required' : '');
const dateRequired = (value) => (!value ? 'required' : '');
const minLen = (value, min) => ((value || '').trim().length < min ? `Min length is ${min}` : '');
const gt = (value, number) => (Number.parseInt(value || 0) <= number ? `Value needs to be greater than ${number}` : '');

const NewReferralPage = () => {
  const navigate = useNavigate();
  let { referralId } = useParams();

  const [loading, setLoading] = useState(false);
  const [displayValidationErrors, setDisplayValidationErrors] = useState(false);
  const [referral, setReferral] = useState({  });

  const isNew = !referralId;
  useEffect(() => {
    if (!referralId) {
      return;
    }

    getReferral(referralId)
      .then(data => {
        setReferral(data.referral)
      })
  }, [referralId])

  const validationErrors = (validation) => ({
    error: displayValidationErrors && !!validation,
    helperText: displayValidationErrors ? validation : '',
  });

  const errors = {
    name: minLen(referral.name, 3),
    referralCode: minLen(referral.referralCode, 5),
    durationInDays: gt(referral.durationInDays, 0),
    profitType: errorRequired(referral.profitType),
    profitAmount: gt(referral.profitAmount, 0),
  };

  const hasError = !!(errors.name || errors.referralCode || errors.durationInDays || errors.profitType || errors.profitAmount);

  const onChange = (prop) => (ev) => {
    setReferral((current) => ({
      ...current,
      [prop]: ev.target.value,
    }));
  };

  const goToReferralsPage = () => navigate('/admin/referrals');

  const submit = async (ev) => {
    ev.preventDefault();
    setDisplayValidationErrors(true);

    if (hasError || loading) {
      return;
    }

    setLoading(true);
    try {
      const save = isNew ? createReferral : updateReferral;
      await save(referral);
      toast.success(`Referral ${isNew ? 'created' : 'updated'}`);
      goToReferralsPage();
    } catch (error) {
      toast.error(`Failed ${isNew ? 'creating' : 'updating'} referral. ${error.response?.data?.message || ''}`);
    }

    setLoading(false);
  };

  const cancel = () => {
    goToReferralsPage();
  };

  return (
    <Paper sx={{ p: 2, px: 3, height: '100%' }}>
      <Typography variant="h5" mb={2}>
        {isNew ? 'Create new' : 'Update'} Referral
      </Typography>

      <Box component="form" maxWidth={'800px'} onSubmit={submit}>
        <Box mb={3}>
          <TextField
            label="Name*"
            name="name"
            fullWidth
            sx={{ minWidth: 200, maxWidth: 500 }}
            value={referral.name || ''}
            {...validationErrors(errors.name)}
            onChange={onChange('name')}
          />
        </Box>
        <Stack direction="row" spacing={2} mb={3}>
          <TextField
            label="Referral Code*"
            name="referralCode"
            fullWidth
            sx={{ minWidth: 200, maxWidth: 500 }}
            value={referral.referralCode || ''}
            {...validationErrors(errors.referralCode)}
            onChange={onChange('referralCode')}
          />
          <Box>
            <Button
              sx={{mt: 1}}
              variant="outlined"
              onClick={() => {
                onChange('referralCode')({
                  target: {
                    value: randomReferralCode(),
                  },
                });
              }}
            >
              Generate
            </Button>
          </Box>
        </Stack>

        <Box mb={3}>
          <TextField
            type="number"
            label="Duration in days"
            value={referral.durationInDays || ''}
            {...validationErrors(errors.durationInDays)}
            onChange={onChange('durationInDays')}
          />
        </Box>

        <Stack direction="row" spacing={2} mb={3}>
          <TextField
            select
            label="Type of profit"
            sx={{ minWidth: '200px' }}
            value={referral.profitType || ''}
            {...validationErrors(errors.profitType)}
            onChange={onChange('profitType')}
          >
            <MenuItem value="Percentage">Percentage</MenuItem>
            <MenuItem value="Fixed">Fixed</MenuItem>
          </TextField>

          <TextField
            type="number"
            label="Discount"
            value={referral.profitAmount || ''}
            {...validationErrors(errors.profitAmount)}
            onChange={onChange('profitAmount')}
            InputProps={{
              startAdornment: <InputAdornment position="start">{referral.profitType === 'Percentage' ? '%' : 'USD'}</InputAdornment>,
            }}
          />
        </Stack>

        <Stack direction="row" spacing={3} mt={3}>
          <Button size="large" variant="outlined" onClick={cancel}>
            Cancel
          </Button>
          <Button size="large" variant="contained" type="submit" disabled={(displayValidationErrors && hasError) || loading}>
            {isNew ? 'Create' : 'Update'}
          </Button>
        </Stack>
      </Box>
    </Paper>
  );
};

export default NewReferralPage;
