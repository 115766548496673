import { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import TextFieldWithIcon from '../TextFieldWithIcon';
import isEmail from 'validator/lib/isEmail';
import PhoneInput from '../PhoneInput';
import 'react-international-phone/style.css';

const validateEmail = (val) => {
  if (!val || val.trim() === '') {
    return 'Required';
  }

  if (!isEmail(val)) {
    return 'Invalid email';
  }

  return null;
};

const validateFullName = (val) => {
  if (!val || val.trim() === '') {
    return 'Required';
  }

  if (val.trim().length < 5) {
    return 'Full name is too short';
  }

  if (val.trim().length > 80) {
    return 'Full name is too long';
  }

  return null;
};

const ClientInfoInput = (props) => {
  const [fullName, setFullName] = useState('');
  const [fullNameError, setFullNameError] = useState(validateFullName(fullName));

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(validateEmail(email));

  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState(null);

  // useEffect(() => {
  //   console.log('mounted');
  // }, [])

  useEffect(() => {
    props.onValidationChange?.(fullNameError || emailError || phoneError);
  }, [fullNameError, emailError, phoneError]);

  useEffect(() => {
    // console.log('onchange', [fullName, email, phone]);
    props.onChange?.({ fullName, email, phone });
  }, [fullName, email, phone]);

  // const {}
  return (
    <>
      <TextField
        label="Full name*"
        name="full-name"
        fullWidth
        sx={{ mb: 3 }}
        value={fullName}
        error={!!(props.displayValidationErrors && fullNameError)}
        helperText={props.displayValidationErrors && fullNameError}
        onChange={(ev) => {
          const { value } = ev.target;
          setFullName(value);
          setFullNameError(validateFullName(value));
        }}
      />

      <PhoneInput
        size="large"
        sx={{ mb: 3 }}
        name="phone"
        label="Phone number*"
        displayValidationErrors={props.displayValidationErrors}
        value={phone}
        onChange={(value) => setPhone(value)}
        onValidationChange={(isValid) => {
          setPhoneError(!isValid);
        }}
      />

      <TextFieldWithIcon
        label="Email*"
        fullWidth
        sx={{ mb: 3 }}
        icon={AlternateEmailIcon}
        value={email}
        name="email"
        error={!!(props.displayValidationErrors && emailError)}
        helperText={props.displayValidationErrors && emailError}
        onChange={(ev) => {
          const { value } = ev.target;
          setEmail(value);
          setEmailError(validateEmail(value));
        }}
      />
    </>
  );
};

export default ClientInfoInput;
