import { Fragment } from 'react';
import { Box, Container, Grid, Link, Paper, Stack, Typography } from '@mui/material';
import {isMobile} from 'react-device-detect';
import { Link as LinkRouter } from 'react-router-dom';
import Image from 'mui-image';
import SearchPage from './SearchPage';
import { formatToDate, getNextWeekend } from '../../helpers/date-time';
import { COLOR } from '../../helpers/styles';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import MapIcon from '@mui/icons-material/Map';
import Navbar from './Navbar';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import dest1 from '../../components/assets/trending-destinations/1.png';
import dest2 from '../../components/assets/trending-destinations/2.png';
import dest3 from '../../components/assets/trending-destinations/3.png';
import dest4 from '../../components/assets/trending-destinations/4.png';
import dest5 from '../../components/assets/trending-destinations/5.png';

import hotel11 from '../../components/assets/hotels/1.1.jpeg';
import hotel12 from '../../components/assets/hotels/1.2.jpeg';
import hotel13 from '../../components/assets/hotels/1.3.jpeg';
import hotel21 from '../../components/assets/hotels/2.1.jpeg';
import hotel22 from '../../components/assets/hotels/2.2.jpeg';
import hotel23 from '../../components/assets/hotels/2.3.jpeg';
import hotel31 from '../../components/assets/hotels/3.1.jpeg';
import hotel32 from '../../components/assets/hotels/3.2.jpeg';
import hotel33 from '../../components/assets/hotels/3.3.jpeg';
import MyTabs from '../../components/MyTabs/MyTabs';
import ShowPromotionCoupon from '../../components/ShowPromotionCoupon/ShowPromotionCoupon';
import { HOTEL_SERVICES_ICONS } from '../../components/icons';
import HomePageBanner from '../../components/Banners/HomePageBanner';

const TOP_HOTELS = [
  {
    name: 'Aruba',
    hotels: [
      {
        name: 'DIVI ARUBA PHOENIX',
        image: hotel11,
        destination: 'Aruba',
        link: '/availability/701758/destinationProvince/AWAUA',
        amenities: [
          {
            name: 'Jacuzzi',
            code: 20,
          },
          {
            name: 'Pool',
            code: 47,
          },
        ],
      },
      {
        name: 'TAMARIJN ARUBA ALL INCLUSIVE',
        image: hotel12,
        destination: 'Aruba',
        link: 'availability/629436/destinationProvince/AWAUA',
        amenities: [
          {
            name: 'Rent A Car',
            code: 11,
          },
          {
            name: 'Shops',
            code: 50,
          },
        ],
      },
      {
        name: 'HOLIDAY INN ARUBA',
        image: hotel13,
        destination: 'Aruba',
        link: '/availability/701766/destinationProvince/AWAUA',
        amenities: [
          {
            name: 'Gym',
            code: 13,
          },
          {
            name: 'Spa',
            code: 63,
          },
        ],
      },
    ],
  },
  {
    name: 'Cancun',
    hotels: [
      {
        name: 'TEMPTATION CANCUN RESORT',
        image: hotel21,
        destination: 'Cancun',
        link: '/availability/734027/destinationCity/4712',
        amenities: [
          {
            name: 'Discotheque',
            code: 9,
          },
          {
            name: 'Entertainment',
            code: 12,
          },
        ],
      },
      {
        name: 'Paradisus Cancún All Inclusive',
        image: hotel22,
        destination: 'Cancun',
        link: '/availability/739648/destinationCity/4712',
        amenities: [
          {
            name: 'Golf',
            code: 21,
          },
          {
            name: 'Spa',
            code: 63,
          },
        ],
      },
      {
        name: 'FIESTA AMERICANA CONDESA',
        image: hotel23,
        destination: 'Cancun',
        link: '/availability/952231/destinationCity/4712',
        amenities: [
          {
            name: 'Restaurant',
            code: 35,
          },
          {
            name: 'Room Service',
            code: 37,
          },
        ],
      },
    ],
  },
  {
    name: 'Punta Cana',
    hotels: [
      {
        name: 'EDEN ROC CAP CANA',
        image: hotel31,
        destination: 'PUNTA CANA',
        link: '/availability/147147/destinationCity/12782',
        amenities: [
          {
            name: 'Restaurant',
            code: 35,
          },
          {
            name: 'Sauna',
            code: 16,
          },
        ],
      },
      {
        name: 'ADULTS ONLY CLUB AT LOPESAN COSTA BAVARO',
        image: hotel32,
        destination: 'Punta Cana',
        link: '/availability/596477/destinationCity/12782',
        amenities: [
          {
            name: 'Pool',
            code: 66,
          },
          {
            name: 'Jacuzzi',
            code: 20,
          },
        ],
      },
      {
        name: 'THE WESTIN PUNTACANA RESORT & CLUB',
        image: hotel33,
        destination: 'Punta Cana',
        link: '/availability/892968/destinationCity/12782',
        amenities: [
          {
            name: 'Tennis',
            code: 18,
          },
          {
            name: 'Jacuzzi',
            code: 20,
          },
        ],
      },
    ],
  },
];

const TRENDING_DESTINATIONS = [
  {
    name: 'Venice, Italy',
    image: dest1,
    responsive: {
      xs: 12,
      sm: 6,
    },
    location: {
      city: 'VENICE',
      code: '357',
      country: 'ITALY',
      province: 'VENICE',
      type: 'city',
    },
  },
  {
    name: 'Miami Beach, US',
    image: dest2,
    responsive: {
      xs: 12,
      sm: 6,
    },
    location: {
      city: 'MIAMI BEACH',
      code: '937',
      country: 'UNITED STATES',
      province: 'MIAMI, FL',
      type: 'city',
    },
  },
  {
    name: 'Las Vegas, US',
    image: dest3,
    responsive: {
      xs: 12,
      sm: 4,
    },
    location: {
      city: 'LAS VEGAS',
      code: '6605',
      country: 'UNITED STATES',
      province: 'LAS VEGAS, NV',
      type: 'city',
    },
  },
  {
    name: 'London, UK',
    image: dest4,
    responsive: {
      xs: 12,
      sm: 4,
    },
    location: {
      city: 'LONDON',
      code: '833',
      country: 'UNITED KINGDOM',
      province: 'LONDON',
      type: 'city',
    },
  },
  {
    name: 'Cappadocia, Turkey',
    image: dest5,
    responsive: {
      xs: 12,
      sm: 4,
    },
    location: {
      city: undefined,
      code: 'TUNAV',
      country: 'TURKEY',
      province: 'NEVSEHIR / CAPPADOCIA',
      type: 'province',
    },
  },
];

const underline = `1px solid ${COLOR.GRAY_LINE_SEPARATOR}`;

const sx = {
  container: {
    pt: 2,
    position: 'relative',
    maxWidth: '1300px',
    px: {
      xs: '20px !important',
      md: '50px !important',
      lg: '0px !important',
    },
  },
};

const TopHotels = () => {
  const { startDate, endDate } = getNextWeekend();
  const urlSuffix = `/${formatToDate(startDate)}/${formatToDate(endDate)}/2-0`;

  return (
    <>
      <Typography fontSize={18} fontWeight="bold" pt={2} pb={1}>
        Recommended All Inclusive Hotels for you to consider
      </Typography>
      <MyTabs
        labels={TOP_HOTELS.map((dest, i) => dest.name)}
        children={TOP_HOTELS.map((dest, i) => () => (
          <Grid container spacing={4} key={dest.name}>
            {dest.hotels.map((hotel) => {
              const linkTo = `${hotel.link}${urlSuffix}`;
              return (
                <Grid item key={hotel.name} xs={12} md={4}>
                  <Paper elevation={8} sx={{ borderRadius: '20px', overflow: 'hidden' }}>
                    <Link component={LinkRouter} to={linkTo} underline="none"
                      target={isMobile ? '_self' : '_blank'} sx={{ color: 'black' }}>
                      <Image src={hotel.image} height={200} duration={0} />
                      <Box px={1.3} py={1.7}>
                        <Typography
                          fontWeight="bold"
                          fontSize={18}
                          sx={{
                            textOverflow: 'ellipsis',
                            textWrap: 'nowrap',
                            overflow: 'hidden',
                            mb: 1,
                          }}
                        >
                          {hotel.name}
                        </Typography>
                        <Box sx={{ display: 'flex', mb: 1 }}>
                          <LocationOnIcon sx={{ opacity: 0.8, ml: 0.3, mr: 0.8, fontSize: 18 }} />
                          <Typography color="gray" sx={{ fontSize: 13 }}>
                            {hotel.destination}
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                          {hotel.amenities.map(({ name, code }) => {
                            const Icon = HOTEL_SERVICES_ICONS[code] || HOTEL_SERVICES_ICONS.default;
                            return (
                              <Fragment key={code}>
                                <Icon sx={{ ml: 0.3, mr: 1, opacity: 0.8, color: 'primary.main' }} />
                                <Typography sx={{ mr: 2, fontSize: 13, fontWeight: 'bold' }}>{name}</Typography>
                              </Fragment>
                            );
                          })}
                        </Box>
                      </Box>
                    </Link>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        ))}
      />
    </>
  );
};

const TopDestinations = () => {
  const { startDate, endDate } = getNextWeekend();
  const defaultSearchTerms = {
    dates: { startDate, endDate },
    roomsStr: '2-0',
  };

  return (
    <Box mt={6} pb={3} sx={{ borderBottom: underline }}>
      <Typography fontSize={18} fontWeight="bold" pt={2} pb={1}>
        Trending Destinations
      </Typography>
      <Grid container spacing={2}>
        {TRENDING_DESTINATIONS.map((destination) => {
          const linkTo = SearchPage.link({ ...defaultSearchTerms, location: destination.location });
          return (
            <Grid item key={destination.name} {...destination.responsive}>
              <Paper sx={{ position: 'relative', borderRadius: '5px', overflow: 'hidden' }} elevation={4}>
                <Link component={LinkRouter} to={linkTo} underline="none">
                  <Image
                    width="100%"
                    height="250px"
                    duration={0}
                    src={destination.image}
                    alt={destination.name}
                    // errorIcon={<NoImage />}
                  />
                  <Box
                    sx={{
                      position: 'absolute',
                      // background: 'linear-gradient(112.1deg, rgb(32, 38, 57) 11.4%, rgb(63, 76, 119) 70.2%)',
                      boxShadow: '0 0 50px 24px rgba(0, 0, 0, 0.5);',

                      borderRadius: '15px',
                      bottom: '1px',
                      left: '1px',
                      // filter: 'blur(1px)',
                      background: '#00000066',
                      // top: '70%',
                      width: '100%',
                      textAlign: 'center',
                      p: 1,
                    }}
                  >
                    <Typography color="white" fontWeight="bold" fontSize={18}>
                      {destination.name}
                    </Typography>
                  </Box>
                </Link>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

const Tags = () => {
  const tags = [
    {
      title: 'Free Cancelation',
      text: 'Explore our Properties',
      icon: EventAvailableIcon,
    },
    {
      title: 'Guaranteed prices',
      text: 'Low Hotel Rates',
      icon: LocalOfferIcon,
    },
    {
      title: 'Locally owned',
      text: 'Serving you since 2018',
      icon: MapIcon,
    },
  ];
  return (
    <Box mt={7}>
      <Grid container spacing={10}>
        {tags.map(({ title, text, icon: Icon }) => (
          <Grid item xs={12} sm={4} key={title}>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
              <Box
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: '40px',
                    color: 'primary.main',
                  },
                }}
              >
                <Icon />
              </Box>
              <Box ml={3}>
                <Typography variant="h5">{title}</Typography>
                <Typography>{text}</Typography>
              </Box>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const HomePage = () => {
  return (
    <Box>
      <Navbar withNavbar />
      <Container sx={sx.container}>
        <ShowPromotionCoupon />
        <HomePageBanner />
        <TopHotels />
        <TopDestinations />
        <Tags />
      </Container>
    </Box>
  );
};

export default HomePage;
