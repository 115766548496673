import { useEffect, useState } from 'react';
import { withDataLoader } from '../../hooks/use-data-loader';
import { Box, Button, FormControlLabel, Switch, Stack, IconButton, MenuItem } from '@mui/material';
import { cloneDeep } from 'lodash';
import { createBanner, deleteBanner, getBanners, updateBanner } from '../../api/admin';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const bannerLocations = ['HomePage'];
const baseUrl = process.env.REACT_APP_HOTELS_API_URL;

const Banner = ({ banner, onChange, onDelete }) => {
  const [draftBanner, setDraftBanner] = useState(cloneDeep(banner));

  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [inputKey, setInputKey] = useState(1);

  useEffect(() => {
    setDraftBanner(banner);
  }, [banner])

  const edit = () => {
    setDraftBanner(cloneDeep(banner));
    setIsEditing(true);
  };

  const updateProp =
    (prop, eventProp = 'value') =>
    (ev) => {
      setDraftBanner((current) => ({
        ...current,
        [prop]: ev.target[eventProp],
      }));
    };

  const handleFileInputChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);

    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setPreviewUrl(null);
    }
  };

  const handleFileCancel = () => {
    setFile(null);
    setPreviewUrl(null);
    setInputKey(new Date().toISOString());
  };

  const cancel = () => {
    setDraftBanner(banner);
    setIsEditing(false);
  };

  const save = async () => {
    setLoading(true);

    const formData = new FormData();
    ['visible', 'location', 'link'].forEach((prop) => {
      if (draftBanner[prop] === undefined) {
        return;
      }
      formData.append(prop, draftBanner[prop]);
    });

    if (file) {
      formData.append('image', file);
    }

    const { banner: newBanner } = await updateBanner(banner._id, formData);
    onChange(newBanner);

    setLoading(false);
    setIsEditing(false);
    setFile(null);
    setPreviewUrl(null);
  };

  const removeBanner = async () => {
    await deleteBanner(banner._id);
    onDelete();
  };

  const image = previewUrl || (draftBanner.image && `${baseUrl}${draftBanner.image}`);

  return (
    <Card sx={{ minWidth: 275, maxWidth: 750, position: 'relative' }}>
      <CardContent>
        <Stack direction="row" sx={{ position: 'absolute', right: '20px' }}>
          <IconButton disabled={isEditing} onClick={edit}>
            <EditIcon color={isEditing ? 'disabled' : 'action'} />
          </IconButton>
          <IconButton onClick={removeBanner}>
            <DeleteIcon color="action" />
          </IconButton>
        </Stack>

        <Typography variant="h5" component="div">
          {banner.location} banner
        </Typography>

        <Box sx={{ mt: 4 }}>
          <Box sx={{ mt: 2, pl: 1 }}>
            <FormControlLabel
              control={<Switch checked={Boolean(draftBanner.visible)} onChange={updateProp('visible', 'checked')} disabled={!isEditing} />}
            />
            {draftBanner.visible ? 'Visible' : 'Not visible'}
          </Box>
          <Box sx={{ mt: 2 }}>
            <TextField
              select
              fullWidth
              label="Location"
              value={draftBanner.location || ''}
              onChange={updateProp('location', 'value')}
              InputProps={{
                readOnly: !isEditing,
              }}
            >
              {bannerLocations.map((location) => (
                <MenuItem key={location} value={location}>
                  {location}
                </MenuItem>
              ))}
            </TextField>
          </Box>

          <Box sx={{ mt: 2 }}>
            <TextField
              fullWidth
              label="Link"
              value={draftBanner.link || ''}
              onChange={updateProp('link')}
              InputProps={{
                readOnly: !isEditing,
              }}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            {isEditing && <TextField type="file" fullWidth onChange={handleFileInputChange} key={inputKey} />}

            {image && (
              <Box sx={{ mt: 2 }}>
                {isEditing && file && <Button onClick={handleFileCancel}>Remove image</Button>}
                <img src={image} alt="Preview" style={{ maxWidth: '100%' }} />
              </Box>
            )}
          </Box>
        </Box>

        {isEditing && (
          <Box mt={2}>
            <Button size="small" variant="outlined" disabled={loading} sx={{ mr: 1 }} onClick={cancel}>
              Cancel
            </Button>
            <Button size="small" variant="contained" disabled={loading} onClick={save}>
              {loading ? 'Saving...' : 'Save'}
            </Button>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

const BannersPage = (props) => {
  console.log(props.data.banners);
  const [banners, setBanners] = useState(props.data.banners);

  const addNewBanner = async () => {
    const { banner } = await createBanner({});

    setBanners((current) => [...current, banner]);
  };

  return (
    <Box>
      {banners.map((banner, i) => {
        return (
          <Box key={banner._id} mt={3} ml={2}>
            <Banner
              banner={banner}
              onChange={(newBanner) => {
                setBanners((current) => current.map((itemBanner, j) => (i === j ? newBanner : itemBanner)));
              }}
              onDelete={() => {
                setBanners((current) => current.filter((_, j) => i !== j));
              }}
            />
          </Box>
        );
      })}
      <Box sx={{ mt: 2, ml: 2, mb: 4 }}>
        <Button variant="contained" onClick={addNewBanner}>
          Add new banner
        </Button>
      </Box>
    </Box>
  );
};

export default withDataLoader(BannersPage, getBanners);
