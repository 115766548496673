import Image from 'mui-image';
import googlePlay from '../assets/apps/google-play.png';
import appStore from '../assets/apps/app-store.png';
import { useEffect, useRef, useState } from 'react';
import FullScreenDialog from '../Dialogs/FullScreenDialog';
import { Box, Button, Typography } from '@mui/material';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import { isAndroid, isIOS } from 'react-device-detect';
import { getObjFromLocalStorage, saveObjToLocalStorage } from '../../helpers/utils';
import { isInTheFuture } from '../../helpers/date-time';
import { addDays } from 'date-fns';

const LINK_TO_INSTALL_APP_PROMPTED = 'install_App_Prompted';

export const useLinkToInstallApp = () => {
  const installPromptRef = useRef(null);
  const [canBeInstalled, setCanBeInstalled] = useState(undefined);
  const [installing, setInstalling] = useState(false);

  const onBeforeInstallPrompt = async (event) => {
    event.preventDefault();

    installPromptRef.current = event;

    if (navigator && navigator.getInstalledRelatedApps) {
      const relatedApps = await navigator.getInstalledRelatedApps();
      const isInstalled = relatedApps.find((app) => app.id === 'bellitours');

      setCanBeInstalled(!isInstalled && isAndroid);
    }
  };

  const onAppInstalled = () => {
    installPromptRef.current = null;
    setCanBeInstalled(false);
  };

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', onBeforeInstallPrompt);
    window.addEventListener('appinstalled', onAppInstalled);
    return () => {
      window.removeEventListener('beforeinstallprompt', onBeforeInstallPrompt);
      window.removeEventListener('appinstalled', onAppInstalled);
    };
  }, []);

  const install = async () => {
    if (!installPromptRef.current || !canBeInstalled || installing) {
      return;
    }

    setInstalling(true);
    try {
      const result = await installPromptRef.current.prompt();
      console.log(`Install prompt was: ${result.outcome}`); // "accepted", "dismissed"

      onAppInstalled();
    } finally {
      setInstalling(false);
    }
  };

  return {
    canBeInstalled,
    installing,
    install,
    isAndroid,
    isIOS,
  };
};

export const LinkToInstallAppButton = (props) => {
  const openDialogRef = useRef(null);

  if (!props.canBeInstalled) {
    return null;
  }

  return (
    <>
      {isAndroid || isIOS ? (
        <Image src={isAndroid ? googlePlay : appStore} duration={0} width="200px" onClick={() => openDialogRef.current()} />
      ) : (
        <Button variant="outlined" onClick={() => openDialogRef.current()}>
          Install the App
        </Button>
      )}
      <LinkToInstallAppDialog openDialogRef={openDialogRef} {...props} />
    </>
  );
};

export const InstallAppPrompt = (props) => {
  const openedRef = useRef(undefined);
  const openDialogRef = useRef(null);

  const [prompted, setPrompted] = useState(() => getObjFromLocalStorage(LINK_TO_INSTALL_APP_PROMPTED));
  const updatePrompted = () =>
    setPrompted((current) => {
      const newPrompted = {
        ...current,
        expires: addDays(new Date(), 1).toISOString(),
      };
      saveObjToLocalStorage(LINK_TO_INSTALL_APP_PROMPTED, newPrompted);

      return newPrompted;
    });

  if (!props.canBeInstalled || (prompted?.expires && isInTheFuture(new Date(prompted.expires)))) {
    return null;
  }

  if (openedRef.current === undefined) {
    setTimeout(() => {
      if (!openDialogRef.current) {
        return;
      }

      openDialogRef.current();
      openedRef.current = true;
    }, 100);
  }

  return <LinkToInstallAppDialog openDialogRef={openDialogRef} onCloseDialog={updatePrompted} {...props} />;
};

export const LinkToInstallAppDialog = (props) => {
  const closeDialogRef = useRef(null);
  if (!props.canBeInstalled) {
    return null;
  }

  return (
    <FullScreenDialog
      titleLabel="Install the App"
      onSave={undefined}
      hideOpenDialogBtn
      onSetOpenDialog={(openDialog) => {
        if (props.openDialogRef) {
          props.openDialogRef.current = openDialog;
        }
      }}
      onSetCloseDialog={(closeDialog) => {
        closeDialogRef.current = closeDialog;
      }}
      onCloseDialog={() => !props.onCloseDialog || props.onCloseDialog()}
    >
      <Box textAlign="center" px={2}>
        <Typography variant="h1" mb={2} fontSize={'35px'} mt="30%">
          Install the App
        </Typography>

        <Typography variant="h3" fontSize={20} mb={8}>
          You can use Bellitours directly in your mobile for a better experience. <br />
        </Typography>

        <Button
          variant="contained"
          disabled={props.isInstalling}
          startIcon={props.isAndroid ? <AndroidIcon /> : props.isIOS ? <AppleIcon /> : undefined}
          onClick={props.install}
          sx={{ mr: 2 }}
        >
          {props.isInstalling ? 'Installing' : 'Install'}
        </Button>

        <Button sx={{ ml: 2 }} variant="outlined" disabled={props.isInstalling} onClick={() => closeDialogRef?.current?.()}>
          Cancel
        </Button>
      </Box>
    </FullScreenDialog>
  );
};
