import { useState } from 'react';
import { getOrderCancellationPolicies, getOrders, sendRefundOrder } from '../../api/admin';
import Dialog from '../../components/Dialogs/Dialog';
import MyDataGrid from '../../components/MyDataGrid/MyDataGrid';
import { isInTheFuture } from '../../helpers/date-time';
import useDataLoader from '../../hooks/use-data-loader';
import { Box, Typography, Button } from '@mui/material';
import { Policies } from '../../components/HotelPaxSelect/HotelPaxSelect';
import { toast } from 'react-toastify';
import ConfirmationDialog from '../../components/Dialogs/ConfirmationDialog';
import { useEffect } from 'react';

const columns = [
  { field: 'id', headerName: 'Id', width: '200' },
  { field: 'updatedAt', headerName: 'Updated at', width: '170' },
  { field: 'status', headerName: 'Status', width: 180 },
  { field: 'language', headerName: 'Lang', width: 80 },
  { field: 'type', headerName: 'Type', width: 70 },
  { field: 'nights', headerName: 'Nights', width: 30 },
  { field: 'checkin', headerName: 'Check-in', width: 170 },
  { field: 'checkout', headerName: 'Check-out', width: 170 },
  { field: 'name', headerName: 'Name', width: 180 },
  { field: 'email', headerName: 'Email', width: 200 /*flex: 1*/ },
  { field: 'phone', headerName: 'Phone', width: 140 /*flex: 1*/ },
  { field: 'hotelCode', headerName: 'Hotel code' /*flex: 1*/ },
  { field: 'clientObservations', headerName: 'Client observations', width: 250 },
  { field: 'agent', headerName: 'Agent', width: 170 },
  { field: 'guests', headerName: 'Guests', width: 250 },
  { field: 'hotelObservations', headerName: 'Hotel observations', width: 250 },
  { field: 'cancellationPolicies', headerName: 'Policies', width: 300 },
  { field: 'preReservationConfirmed', headerName: 'PreReserved', width: 60 },
  { field: 'reservationNumber', headerName: 'Reservation No.', width: 120 },
  { field: 'confirmed', headerName: 'Confirmed', width: 80 },
  { field: 'confirmationNo', headerName: 'Confirmation No.', width: 120 },
  { field: 'reservationInfo', headerName: 'Reservation Info', width: 150 },
  { field: 'currency', headerName: 'Currency', width: 100 },
  { field: 'reservationPrice', headerName: 'Reserve Price', width: 120 },
  { field: 'sellPrice', headerName: 'Sell Price', width: 120 },
  { field: 'belliToursReservationId', headerName: 'Internal Reservation Id', width: 170 /*flex: 1*/ },
  { field: 'fees', headerName: 'Fees', width: 100 },
  { field: 'stripeStatus', headerName: 'Stripe Status', width: 120 },
  { field: 'stripeCustomerId', headerName: 'Stripe Customer Id', width: 200 },
  { field: 'stripeCurrency', headerName: 'Stripe currency', width: 100 },
  { field: 'stripeCharge', headerName: 'Stripe charge', width: 130 },
  { field: 'stripeRefund', headerName: 'Stripe refund', width: 130 },
  {
    field: 'stripeReceiptUrl',
    headerName: 'Stripe receipt url',
    /*flex: 1*/
    renderCell: ({ row: item }) => {
      return item.stripeReceiptUrl ? (
        <Box>
          <a href={item.stripeReceiptUrl} key={item._id} target="_blank" rel="noreferrer">
            receipt
          </a>
        </Box>
      ) : (
        ''
      );
    },
  },
  { field: 'billingClient', headerName: 'Billing client', width: 200 /*flex: 1*/ },
  { field: 'billingAddress', headerName: 'Billing address', width: 200 /*flex: 1*/ },
  { field: 'billingCard', headerName: 'Billing card', width: 180 /*flex: 1*/ },
];

const OrdersPage = () => {
  const { loaded, data, error } = useDataLoader(getOrders);
  const [ordersFromData, setOrdersFromData] = useState([]);

  useEffect(() => {
    setOrdersFromData(data?.orders || []);
  }, [data?.orders]);

  if (error) {
    return <Typography variant="h1">Failed to get orders</Typography>;
  }

  if (!loaded) {
    return undefined;
  }

  const orders = ordersFromData.map((order) => {
    return {
      _id: order._id,
      id: order._id,
      obj: order,
      createdAt: order.createdAt,
      updatedAt: order.updatedAt,
      status: order.status,
      confirmed: order.confirmation?.reservationConfirmed,
      confirmationNo: order.confirmation?.confirmationNumber,
      language: order.clientRequest.language,
      type: order.clientRequest.isAnApartment ? 'apartment' : 'hotel',
      nights: order.clientRequest.lines.length,
      name: order.clientRequest.client.fullName,
      email: order.clientRequest.client.email,
      phone: order.clientRequest.client.phone,
      checkin: order.clientRequest.checkin,
      checkout: order.clientRequest.checkout,
      hotelCode: order.clientRequest.hotelCode,
      clientObservations: order.clientRequest.observations,
      agent: order.clientRequest.agent,
      guests: order.clientRequest.guests.map((guest) => `${guest.title} ${guest.firstName} ${guest.lastName} (${guest.age})`).join(' / '),
      hotelObservations: order.additionalInfo.observations.map(({ text }, i) => `DAY ${i + 1}: ${text}`),
      cancellationPolicies: order.cancellationFees
        .map((policy, i) => `POLICY ${i + 1}: ${!policy.reimbursable ? 'No' : ''} Reimbursable. ${policy.summary || ''}`)
        .join(' / '),
      preReservationConfirmed: order.preReserve.preReservationConfirmed,
      reservationNumber: order.preReserve.reservationNumber,
      reservationInfo: order.preReserve.additionalInfo,
      currency: order.preReserve.currency,
      reservationPrice: order.preReserve.totalAmount,
      belliToursReservationId: order.reservationId,
      finalPrice: order.finalPrice,
      priceToPay: order.priceToPay,
      // fees: `${order.markup.type} ${order.markup.amount}`,
      stripeStatus: order.charge?.charge?.status,
      stripeCustomerId: order.charge?.customer?.id,
      stripeCurrency: order.charge?.charge?.currency,
      stripeCharge: order.charge?.charge?.amount_captured,
      stripeRefund: order.charge?.charge?.amount_refunded,
      stripeReceiptUrl: order.charge?.charge?.receipt_url,
      billingClient: order.charge
        ? [order.charge.customer.name, order.charge.customer.email, order.charge.customer.phone].join(' / ')
        : undefined,
      billingAddress: order.charge
        ? ['country', 'state', 'city', 'line1', 'postal_code'].map((prop) => order.charge?.charge.billing_details.address[prop]).join(' / ')
        : undefined,
      billingCard: order.charge
        ? [
            order.charge?.charge.source.brand,
            `***${order.charge?.charge.source.last4}`,
            `${order.charge?.charge.source.exp_month}/${order.charge?.charge.source.exp_year}`,
          ].join()
        : undefined,
    };
  });

  return (
    loaded && (
      <MyDataGrid
        name="Order"
        columns={columns}
        rows={orders}
        renderBeforeView={(order) => (
          <Refund
            order={order}
            onUpdate={(newOrder) => {
              setOrdersFromData((orders) => orders.map((ord) => (ord._id === newOrder._id ? newOrder : ord)));
            }}
          />
        )}
      />
    )
  );
};

const Refund = ({ order, onUpdate }) => {
  const [loadingPolicies, setLoadingPolicies] = useState(false);
  const [refunding, setRefunding] = useState(false);
  const [policies, setPolicies] = useState(false);
  const [refundedCompleted, setRefundedCompleted] = useState(false);
  const [refundedResult, setRefundedResult] = useState('');

  const isRefundable = order.obj.status.toLowerCase() === 'captured' && isInTheFuture(order.obj.clientRequest.checkin);

  const loadPolicies = async () => {
    setLoadingPolicies(true);
    try {
      const newPolicies = await getOrderCancellationPolicies({ reservationNumber: order.obj.confirmation.reservationNumber });
      setPolicies(newPolicies.policies);
    } catch (error) {
      toast.error(error);
      console.error(error);
    }
    setLoadingPolicies(false);
  };

  const refund = async () => {
    setRefunding(true);
    try {
      const newOrder = await sendRefundOrder({ orderId: order.obj._id });
      toast.success('Order have been refunded');
      setRefundedResult('Order have been refunded');
      onUpdate(newOrder);
    } catch (error) {
      toast.error(error);
      console.error(error);
      setRefundedResult('Failed to refund');
    }
    setRefunding(false);
    setRefundedCompleted(true);
  };

  return (
    <Dialog
      titleLabel="Refund Order"
      renderTarget={({ openDialog }) => (
        <Button
          variant="contained"
          disabled={!isRefundable}
          onClick={() => {
            openDialog();
            setRefunding(false);
            setRefundedCompleted(false);
            setRefundedResult(undefined);
            loadPolicies();
          }}
        >
          Refund
        </Button>
      )}
      renderContent={({ closeDialog }) => (
        <Box>
          {loadingPolicies && <Typography>Loading Policies...</Typography>}
          {refunding && <Typography>Refunding...</Typography>}
          {refundedCompleted && (
            <Box textAlign="center">
              <Typography variant="h6">{refundedResult}</Typography>
              <Box mt={2}>
                <Button onClick={closeDialog} variant="contained">
                  Close
                </Button>
              </Box>
            </Box>
          )}
          {!loadingPolicies && !refunding && !refundedCompleted && (
            <Box textAlign="center">
              <Policies policies={policies} />
              <ConfirmationDialog
                titleLabel="Confirm Refund"
                openDialogLabel="Proceed to Refund"
                cancelLabel="No"
                okLabel="Yes"
                onSave={refund}
              >
                <Box>Are you sure you want to refund this order?</Box>
              </ConfirmationDialog>
            </Box>
          )}
        </Box>
      )}
    />
  );
};

export default OrdersPage;
