import { getMessages } from '../../api/admin';
import useDataLoader from '../../hooks/use-data-loader';
import { Typography } from '@mui/material';
import MyDataGrid from '../../components/MyDataGrid/MyDataGrid';


const columns =  [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
  },
  {
    field: 'subject',
    headerName: 'Subject',
    flex: 1,
  },
  {
    field: 'message',
    headerName: 'Message',
    flex: 2,
  },
];

const MessagesPage = () => {
  const { loaded, data, error } = useDataLoader(getMessages);

  if (error) {
    return <Typography variant="h1">Failed to load the messages</Typography>;
  }

  return (
    loaded && (
      <MyDataGrid name="Message" columns={columns} rows={data.messages} />
    )
  );
};

export default MessagesPage;
