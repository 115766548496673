import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Box } from '@mui/material';
import BaseDialog from './BaseDialog';
import { COLOR } from '../../helpers/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogCmp = (props) => (
  <Dialog
    fullScreen
    open={props.open}
    onClose={props.closeDialog}
    TransitionComponent={Transition}
    className={props.className}
    disableEscapeKeyDown
    sx={{}}
  >
    <AppBar sx={{ position: 'relative' }}>
      {props.hideToolbar || <Toolbar>
        {!props.hideCloseDialogBtn && (
          <IconButton edge="start" color="inherit" onClick={props.closeDialog} aria-label="close">
            <CloseIcon />
          </IconButton>
        )}
        {props.customTitle && props.customTitle()}
        {props.title}
        {props.titleLabel && (
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {props.titleLabel}
          </Typography>
        )}
        {props.onSave && (
          <Button autoFocus color="inherit" onClick={() => props.onSave() && props.closeDialog()}>
            {props.saveLabel || 'Save'}
          </Button>
        )}
      </Toolbar>}
    </AppBar>
    <Box ref={props.containerRef || {}} sx={{ height: 'calc(100vh - 50px)', overflow: 'scroll', background: COLOR.BACKGROUND }}>{props.children}</Box>
  </Dialog>
);

const FullScreenDialog = (props) => {
  return <BaseDialog {...props} Dialog={DialogCmp} />;
};

export default FullScreenDialog;
