import { Container } from '@mui/material';
import HotelsSearch, { buildNewSearchTerms, buildQueryStringFromSearchTerms } from '../../components/HotelsSearch/HotelsSearch';
import Navbar from './Navbar';

const SearchPage = () => {
  return (
    <>
    <Navbar/>
      <Container maxWidth="1300px" sx={{ pt: 2, maxWidth: '1300px' }}>
        <HotelsSearch />
      </Container>
    </>
  );
};

SearchPage.linkPattern = '/search';

SearchPage.link = (searchProps) => {
  const searchTerms = buildNewSearchTerms(searchProps);
  const queryString = buildQueryStringFromSearchTerms(searchTerms);
  return `/search?${queryString}`;
};

export default SearchPage;
