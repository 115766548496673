import HotelDetails from '../HotelDetails';
import HotelPaxSelect from '../HotelPaxSelect/HotelPaxSelect';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Paper, Typography, Link } from '@mui/material';
import { formatToDate, parseDate } from '../../helpers/date-time';
import { paxesObjToString, paxesStringToObj } from '../../helpers/paxes';
import { useNavigate } from 'react-router-dom';
import HotelAvailabilityPage from '../../pages/home/HotelAvailabilityPage';
import { toast } from 'react-toastify';
import SearchHotelsInputs from '../Inputs/SearchHotelsInput';
import { getQueryString } from '../../helpers/utils';
import Checkout from '../Checkout/Checkout';
import { useLoading } from '../../contexts/LoadingContext/LoadingContext';

const getReturnUrl = () => {
  const { returnUrl } = getQueryString();
  if (!returnUrl) {
    return undefined;
  }

  const isFullUrl = returnUrl.startsWith('http://') || returnUrl.startsWith('https://');
  const isSameOrigin = returnUrl.startsWith(window.location.origin);

  return isSameOrigin || !isFullUrl ? returnUrl : undefined;
};

const HotelAvailability = ({ hotel, availability, checkin, checkout, paxes: paxesStr, urlParams, searchId }) => {
  const { setLoading } = useLoading();
  const openCheckoutRef = useRef(null);
  const closeCheckoutRef = useRef(null);
  const cleanupSelectedPackageRef = useRef(null);

  const paxesRef = useRef(null);
  const [searching, setSearching] = useState(false);

  const [paxes, setPaxes] = useState([]);
  const updateSelectedPaxes = ({ packages, completed }) => {
    setPaxes(packages);

    if (completed) {
      setLoading(true);
      setTimeout(() => {
        openCheckoutRef.current();
        setTimeout(() => {
          setLoading(false);
        }, 300);
      }, 100);
    }
  };

  useEffect(() => {
    setSearching(false);
    if (!availability) {
      toast.info('There is no availability in the selected dates');
    }
  }, [availability, searchId]);

  const navigate = useNavigate();

  const returnUrl = getReturnUrl();

  const search = ({ dates, rooms }) => {
    setSearching(true);

    const newUrlParams = {
      ...urlParams,
      checkinDate: formatToDate(dates.startDate),
      checkoutDate: formatToDate(dates.endDate),
      preferredLanguage: 'en', // todo
      rooms: paxesObjToString(rooms),
    };

    const newUrl = HotelAvailabilityPage.link(newUrlParams, returnUrl);
    navigate(newUrl);
  };

  const [paxesAsObj, roomsPerPaxes, totalOfRooms] = useMemo(() => {
    const paxesAsObj = paxesStringToObj(paxesStr);
    const roomsPerPaxes = paxesAsObj.reduce((acc, { adults, children }) => {
      const paxStr = `${adults}-${children}`;
      acc[paxStr] = (acc[paxStr] || 0) + 1;
      return acc;
    }, {});

    return [paxesAsObj, roomsPerPaxes, paxesAsObj.length];
  }, [paxesStr]);

  return (
    <Box px={1}>
      <Box sx={{ mb: 1, mt: 1 }}>
        {returnUrl && (
          <Link sx={{ float: 'right' }} href={returnUrl}>
            Back to Hotel Results
          </Link>
        )}
        <Box>
          <Typography sx={{ ml: 1 }} variant="h6">
            Find another date in this property
          </Typography>
          <Box>
            <SearchHotelsInputs
              omitLocation
              initialDates={{ startDate: parseDate(checkin), endDate: parseDate(checkout) }}
              initialRooms={paxesAsObj}
              searching={searching}
              onSearch={search}
            />
          </Box>
        </Box>
      </Box>
      <HotelDetails hotel={hotel}></HotelDetails>

      {(searching || !availability) && (
        <Paper variant="outlined" sx={{ p: 2, mb: 5 }}>
          <Typography textAlign="center" variant="h4">
            {searching ? 'Looking availabilities in the selected dates...' : 'No availability'}
          </Typography>
        </Paper>
      )}

      {!searching && availability && (
        <Box
          ref={(ref) => {
            paxesRef.current = ref;
          }}
        >
          <HotelPaxSelect
            availability={availability}
            onChange={updateSelectedPaxes}
            roomsPerPaxes={roomsPerPaxes}
            totalOfRooms={totalOfRooms}
            onSetCleanupSelectedPackage={(value) => {
              cleanupSelectedPackageRef.current = value;
            }}
          />

          <Checkout
            hotel={hotel}
            paxes={paxes}
            checkin={checkin}
            checkout={checkout}
            openRef={openCheckoutRef}
            closeRef={closeCheckoutRef}
            onClosed={() => cleanupSelectedPackageRef.current()}
          />
        </Box>
      )}
    </Box>
  );
};

export default HotelAvailability;
