import { deleteLog, getLogs } from '../../api/admin';
import useDataLoader from '../../hooks/use-data-loader';
import { Typography } from '@mui/material';
import MyDataGrid from '../../components/MyDataGrid/MyDataGrid';
import { useEffect, useState } from 'react';

const columns = [
  {
    field: 'category',
    headerName: 'Category',
    // flex: 1,
    width: 250,
  },
  {
    field: 'level',
    headerName: 'Level',
    // flex: 1,
    width: 200,
  },
  {
    field: 'message',
    headerName: 'Message',
    width: 200,
  },
  {
    field: 'payload',
    headerName: 'Payload',
    flex: 1,
    renderCell: ({ row: item }) => {
      return JSON.stringify(item);
    },
  },
];

const LogsPage = () => {
  const { loaded, data, error } = useDataLoader(getLogs);
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    setLogs(data?.logs || []);
  }, [data?.logs]);

  if (error) {
    return <Typography variant="h1">Failed to load the logs</Typography>;
  }

  if (!loaded) {
    return;
  }

  return (
    loaded && (
      <MyDataGrid
        name="Logs"
        columns={columns}
        rows={logs}
        onDelete={async (log) => {
          await deleteLog(log._id);
          setLogs((data?.logs || []).filter((item) => item._id !== log._id));
        }}
      />
    )
  );
};

export default LogsPage;
