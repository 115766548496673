import { Box } from '@mui/material';
import { useFirstRender } from '@mui/x-data-grid';
import { Resizable } from 're-resizable';
import { useEffect, useRef, useState } from 'react';

const ResizableSlider = (props) => {
  const containerRef = useRef();

  const key = `resizableSlider_${props.name}`;
  const [width, setWidth] = useState(undefined);

  const setWidthAndStore = (newWidth) => {
    localStorage.setItem(key, Number(newWidth));
    setWidth(Number(newWidth));
  };

  const prevWidthRef = useRef(undefined);
  useEffect(() => {
    const eleWidth = containerRef.current?.offsetWidth;
    if (eleWidth === undefined) {
      return;
    }

    const eps = 10;

    const show = () => {
      if (!Number(width) || Number(width) <= eps) {
        setWidthAndStore(prevWidthRef.current && prevWidthRef.current > eps ? prevWidthRef.current : eleWidth / 3);
        prevWidthRef.current = Number(width);
        return true;
      }
    };

    const hide = () => {
      setWidthAndStore(5);
      prevWidthRef.current = Number(width);
    }

    const toggleRight = () => {
      if (show()) {
        return;
      }
      hide()
    };

    props.onSetToggleRight?.(toggleRight);
    props.onSetShow?.(show);
    props.onSetHide?.(hide);
  }, [width]);

  useEffect(() => {
    if (width === undefined) {
      setWidthAndStore(Number(localStorage.getItem(key)) || 5);
    }
  }, [containerRef.current]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        overflowX: 'hidden',
        maxWidth: '100%',
      }}
      ref={(ref) => {
        containerRef.current = ref;
      }}
    >
      {width !== undefined && (
        <>
        <Box sx={{ maxWidth: '100%', height: '100%', }} style={{ width: '100%', minWidth: '10px', maxWidth: '100%', overflowX: 'hidden' }}>
        {props.left}
          </Box>
          <Resizable
            bounds="parent"
            boundsByDirection
            size={{ width: width, height: '100%' }}
            onResizeStop={(e, direction, ref, d) => setWidthAndStore(Number(width) + Number(d.width))}
            sx={{maxWidth: '100%', minWidth: '10px', height: '100%', overflowX: 'hidden'}}
          >
            {props.right}
          </Resizable>

        </>
      )}
    </Box>
  );
};

export default ResizableSlider;
