import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { useState } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const CheckboxesFilter = ({ filter }) => {
  const [showedAll, setShowedAll] = useState(false);
  const { values, limit, title } = filter;

  return (
    <>
      <Typography variant="div" color="text.primary">
        {title}
      </Typography>
      <FormGroup sx={{ ml: 1 }}>
        {values.slice(0, showedAll ? values.length + 1 : limit).map((value) => (
          <FormControlLabel
            key={value.label}
            control={<Checkbox size="small" onChange={value.onChange} checked={value.checked} />}
            label={value.label}
            sx={{ mb: '-11px' }}
          />
        ))}
      </FormGroup>
      {values.length > limit && (
        <Box sx={{ cursor: 'pointer', ml: 1, mt: 1 }} onClick={() => setShowedAll(!showedAll)}>
          <Typography variant="body" sx={{ textDecoration: 'underline' }}>
            {showedAll ? 'view less' : 'view more'}
          </Typography>
          {showedAll ? <ExpandLessIcon sx={{ mb: '-6px' }} /> : <ExpandMoreIcon sx={{ mb: '-6px' }} />}
        </Box>
      )}
    </>
  );
};

export default CheckboxesFilter;
