import React, { createContext, useContext, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuthToken } from '../../api/auth';

const AUTH_TOKEN_KEY = 'authToken';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const navigateTo = useRef(null);

  // TODO: memo the useRefValue to avoid read from the storage unnecessary every time
  const existingAuthToken = useRef(localStorage.getItem(AUTH_TOKEN_KEY));
  const [auth, setAuth] = useState({
    isLoggedIn: !!existingAuthToken.current,
    authToken: existingAuthToken.current,
  });

  const login = async ({ email, pass }) => {
    const { token } = await getAuthToken({ email, pass });
    localStorage.setItem(AUTH_TOKEN_KEY, token);
    navigateTo.current = '/admin';
    setAuth({ isLoggedIn: true, authToken: token });
  };

  const logout = async () => {
    localStorage.removeItem(AUTH_TOKEN_KEY);
    navigateTo.current = '/auth/login';
    setAuth({ isLoggedIn: false, authToken: null });
  };

  const contextValue = useMemo(
    () => ({ isLoggedIn: auth.isLoggedIn, authToken: auth.authToken, login, logout }),
    [auth.isLoggedIn, auth.authToken]
  );

  if (navigateTo.current) {
    const url = navigateTo.current;
    navigateTo.current = null;
    setTimeout(() => navigate(url), 1);
  }

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
