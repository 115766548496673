import { isNil, isString, omitBy, times } from 'lodash';

export const isPromise = (p) => p && p.then && typeof p.then === 'function';

export const tryCatch = (fn) => {
  let result;

  try {
    result = fn();
  } catch (e) {
    return [undefined, e];
  }

  if (isPromise(result)) {
    return result.then((promiseResult) => [promiseResult, undefined]).catch((promiseError) => [undefined, promiseError]);
  }

  return [result, undefined];
};

export const pick = (config, value) => (value in config ? config[value] : config.default);

export const removeAccents = (str) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const isBlank = (str) => isString(str) && str.trim() === '';

export const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const getObjFromLocalStorage = (name) => {
  const item = localStorage.getItem(name)
  try {
    return JSON.parse(item ?? '{}');
  } catch {
    return {};
  }
}

export const saveObjToLocalStorage = (name, value) => localStorage.setItem(name, JSON.stringify(value));
export const removeObjFromLocalStorage = (name) => localStorage.removeItem(name);

const COUPONS_CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'.split('');

export const randomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

export const sample = (list) => list[randomInt(0, list.length - 1)];

export const randomCodes = (opts = {}) => {
  const amountOfGroups = randomInt(...(opts.amountOfGroups || [1, 1]));
  const groupsLength = randomInt(...(opts.groupsLength || [1,10]));

  return times(amountOfGroups, () => times(groupsLength, () => sample(COUPONS_CHARS)).join('')).join('-');
}

export const randomCouponCode = () => {
  return randomCodes({
    amountOfGroups: [1, 4],
    groupsLength: [3, 5],
  });
};

export const randomReferralCode = () => {
  return randomCodes({
    amountOfGroups: [1, 1],
    groupsLength: [5, 10],
  });
}

export const buildQueryString = (obj) => {
  const paramWithNoNil = omitBy(obj, isNil);
  return new URLSearchParams(paramWithNoNil).toString();
};

export const money = (m) => Number(m).toLocaleString('en-US');

export const getQueryString = () => Object.fromEntries(new URLSearchParams(window.location.search).entries());
