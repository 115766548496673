import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';

const LogoutPage = () => {
  const { logout } = useAuth();
  logout();

  return <Navigate to="/auth/login" />;
};

export default LogoutPage;
